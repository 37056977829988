import { api_url } from '../Config.js';
export function Myshop(userData) {
	let BaseURL = api_url.base_url + '/products_api/addtoMyshop';

	// let BaseURL = 'http://localhost:8080/moizpk/Welcome/addtoFavourites'
	var value = (userData.title) ? userData.title : userData;
	var data = { user_id: localStorage.getItem('userData'), title: value };
	return new Promise((resolve, reject) => {
		fetch(BaseURL,
			{
				method: 'POST',
				headers:
				{
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data)
			})
			.then((response) => response.json()
				.then((res) => {
					resolve(res);
				}))
			.catch((error) => {
				reject(error);
			});
	});
}