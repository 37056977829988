import React,{useState,useEffect} from 'react';
import {api_url}  from "../Config.js";
import {NavLink} from 'react-router-dom';
const UserSidebar = (props) => {
	const current_user=localStorage.getItem('userData');
	const [seller,setSeller] = useState(false);
	const [dropship,setDropship] = useState(false);
	const com_url = api_url.com_url;
	useEffect(()=>{
		const checkUrl = com_url+'moiz_api/getCustomerDetail/'+current_user
			fetch(checkUrl)
			.then(response=>response.json())
			.then(result=>{
				if (result.customer_detail.group_id=="6") {
					setSeller(true);
				}
				if (result.customer_detail.group_id=="3") {
					setDropship(true);
				} 
			})
	},[]);

	let sellerStatusBar = '';
	if (seller) {
		sellerStatusBar =<div><li><NavLink to="/seller-comission" className={props.active==="seller_comission" ? "Active" :''}>Seller Comission</NavLink></li><li><NavLink to="/seller-payments" className={props.active==="seller_payments" ? "Active" :''}>Seller Payments</NavLink></li></div>
		
	}

	let dropshipBar = '';
	if (dropship) {
		dropshipBar =<div><li><NavLink to="/customer-profit" className={props.active==='profit' ? 'Active' :''}>User Profit</NavLink></li><li><NavLink to="/dropship-payments" className={props.active==="dropship_payments" ? "Active" :''}>Dropship Payments</NavLink></li></div>;
	}

  return (
    <div>
    	<ul>
	      	<li><NavLink to="/order"  className={props.active==='all_order' ? 'Active' :''}>All Orders</NavLink></li>
	       
	      	<li><NavLink to="/manage-feedback" className={props.active==='feedback' ? 'Active' :''}>Manage Feedback</NavLink></li>
	       
	      	<li><NavLink to="/my-shop" className={props.active==='myshop' ? 'Active' :''}>My Shop</NavLink></li>		
			
			<li><NavLink to="/my-favourite" className={props.active==='favourite' ? 'Active' :''}>My Favourite</NavLink></li>
	      	 
	      	<li><NavLink to="/my-coupon" className={props.active==='coupon' ? 'Active' :''}>My Coupons</NavLink></li>
	      	 
	      	<li><NavLink to="/shipping-address" className={props.active==='shipping' ? 'Active' :''}>Shipping Address</NavLink></li>
	      	 
	      	<li><NavLink to="/profile" className={props.active==='profile' ? 'Active' :''}>User Profile</NavLink></li>

	      	
	      	{dropshipBar}
	      	{sellerStatusBar}

	      	{
	      		<>
	      		{/*

	      		*/}
	      		</>
	      	}
	      	
	   </ul>
    </div>
  )
}

export default UserSidebar;