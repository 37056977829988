import React, { Component } from 'react';


class Myview extends Component {
	constructor(props){
		super(props);
		this.state = {
		  notes: [],
		  currentNote: ""
		};
		this.addNote=this.addNote.bind(this);
	}
	addNote() {
	  const notes = [...this.state.notes];
	  notes.push(this.state.currentNote);
	  this.setState({ notes, currentNote: "" });
	
	};
	
	render() {
		const{notes} = this.state
		return (
			<div>
			<div style={{'textAlign':'center', 'marginTop':'90px'}}>
			<textarea
			  onChange={event => this.setState({ currentNote: event.target.value })}
			  value={this.state.currentNote}
			  className="input"
			  placeholder="Notes"
			/>
			<br/>
			<button className="button" style={{'marginTop':"30px"}} onClick={this.addNote}>
			  Submit
			</button>
							
				
			</div>
			</div>
			
		);
	}
}

export default Myview;
