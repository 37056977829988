import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import $ from 'jquery';
import '../../assets/css/bootstrap.css';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/font-awesome.min.css';
import {NavLink} from 'react-router-dom';
import '../../assets/css/style.css';
import shape1 from '../../assets/images/Shape-1.png';
import {api_url} from '../../Config.js';
import {Favourite} from '../../services/Favourite.js';
import {Unfavourite} from '../../services/Unfavourite.js';
import {Myshop} from '../../services/Myshop.js';
import {RemoveMyShop} from '../../services/RemoveMyShop.js';
import {countMostViewsItem} from '../../services/countMostViewsItem.js';
import styled from 'styled-components';
import swal from 'sweetalert';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

class DynamicComponent extends Component {



render() {

  return (
    <div>
        <section className="tabs_blog new_add_products">
          <div className="container">
            <div className="row">
                  <div className="col-sm-12 new_arrivals">
                    <section className="best_deal">
                        <div className="main_head">
                          <h3><Skeleton /></h3>
                        </div>

                        <div className="shadow_box">
                          <div className="row">


                            <div className="withoutSlider">
                              <div className="col-sm-3 side_gaps"  >
                                <Skeleton height={260} width={221} />
                              </div>
                              <div className="col-sm-3 side_gaps"  >
                                <Skeleton height={260} width={221} />
                              </div>
                              <div className="col-sm-3 side_gaps"  >
                                <Skeleton height={260} width={221} />
                              </div>
                              <div className="col-sm-3 side_gaps"  >
                                <Skeleton height={260} width={221} />
                              </div>
                              <div className="col-sm-3 side_gaps"  >
                                <Skeleton height={260} width={221} />
                              </div>
                            </div>

                          </div>

                        </div>
                    </section>
                  </div>
                </div>
          </div>
        </section>
      </div>
      );

  }


}
export default DynamicComponent;
