import React, { Component } from 'react';
import Header  from "./Header/Header";
import Footer  from "./Footer/Footer";
import $ from 'jquery';
import deal856985 from '../assets/images/shop1_post1.jpg';
import deal8545785 from '../assets/images/clothes-eyewear-footwear-934069.jpg';
import deal78965412 from '../assets/images/shop1_post1.jpg';
import discount2 from '../assets/images/logo_boot1.jpg';
import logo2 from '../assets/images/logo_boot2.jpg';
import logo4 from '../assets/images/logo_boot4.jpg.png';
import {api_url} from '../Config.js';
import styled from 'styled-components';
const Container = styled.div`
  background-color:#444;
  color:white;
  padding:16px;
  position:fixed;
  top:${props=> props.top}px;
  right:16px;
  z-index:999;
  transition: top 0.9s ease;
`;

class Brands extends Component {
  constructor(props) {
    super(props);
    this.state={
         error: null,
      products: [],
      backupproducts: [],
      cartProducts:[],
      brands:[],
      itemShow:10,
      top:-8000,
      categories:[],
      productsFilters:[],
       filter:[],
      totalItems:"",
      loading:false,
      addtocart:'',
      itemquantity:1,
      items: JSON.parse(localStorage.getItem('session')),
      cartCount:localStorage.getItem('cartCount'),
      filterId:localStorage.getItem('filterId'), 
      base_url:api_url.base_url,
      img_url:api_url.base_url,
      filterData:[],
      fillterCategory:'',
      filterOption:'',
      changeCategory:"",



    }
    this.loadmore = this.loadmore.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.handleChanged = this.handleChanged.bind(this);

  }

 componentDidMount() {

   const caturl = this.state.base_url+'products_api/GetCategories';

    fetch(caturl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            categories: result

          });
        },
        (error) => {
          this.setState({ error });
        }
      )
     
  
const allCat = this.state.base_url+'products_api/AllCategories';

    fetch(allCat)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            brands: result
          });
          console.log(this.state.brands)
        },
        (error) => {
          this.setState({ error });
        }
      )



    
    const apiUrl = this.state.base_url+'products_api/getProducts';

    fetch(apiUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            products: result,
            backupproducts:result,
          });
          console.log(this.state.products)
        },
        (error) => {
          this.setState({ error });
        }
      )




    $('ul.tabs li').click(function(){
    var tab_id = $(this).attr('data-tab');

    $('ul.tabs li').removeClass('current');
    $('.tab-content').removeClass('current');

    $(this).addClass('current');
    $("#"+tab_id).addClass('current');
  })
     $(".tab-section- .tabs a").on("click",function(e){
  $(this.hash).slideDown().siblings().slideUp();
    e.preventDefault();
  }).first().click();
   
   // for active class
    $(".tab-section- .tabs a").on("click",function(){
   $(".tabs a.active").removeClass("active");
   $(this).addClass("active");
});

    $('ul.tabs_sort li').click(function(){
    var tab_id = $(this).attr('data-tab');
    $('ul.tabs_sort li').removeClass('active');
    $('.tab-content-sort').removeClass('active');
    $(this).addClass('active');
    $("#"+tab_id).addClass('active');
  });



$(".collapsed").click(function(){
    $(".collapse_info").slideToggle();
});


$(".cata-sub-slider").on('scroll', function() {
      var val = $(this).scrollLeft();
      if($(this).scrollLeft() + $(this).innerWidth()>=$(this)[0].scrollWidth){
          $(".cata-sub-slider .nav-next-").hide();
        } else {
        $(".cata-sub-slider .nav-next-").show();
      }
      if(val == 0){
        $(".cata-sub-slider .nav-prev-").hide();
      } else {
        $(".cata-sub-slider .nav-prev-").show();
      }
    });
  console.log( 'init-scroll: ' + $(".cata-sub-slider .nav-next-").scrollLeft() );
  $(".cata-sub-slider .nav-next-").on("click", function(){
    $(".cata-sub-slider").animate( { scrollLeft: '+=460' }, 200);
    
  });
  $(".cata-sub-slider .nav-prev-").on("click", function(){
    $(".cata-sub-slider").animate( { scrollLeft: '-=460' }, 200);
  });


  $(".cata-sub-nav").on('scroll', function() {
       var val = $(this).scrollLeft();

      if($(this).scrollLeft() + $(this).innerWidth()>=$(this)[0].scrollWidth){
          $(".nav-next").hide();
        } else {
        $(".nav-next").show();
      }

      if(val == 0){
        $(".nav-prev").hide();
      } else {
        $(".nav-prev").show();
      }
    });
  console.log( 'init-scroll: ' + $(".nav-next").scrollLeft() );
  $(".nav-next").on("click", function(){
    $(".cata-sub-nav").animate( { scrollLeft: '+=460' }, 200);
    
  });
  $(".nav-prev").on("click", function(){
    $(".cata-sub-nav").animate( { scrollLeft: '-=460' }, 200);
  });
  }

  loadmore(){
  this.setState((old)=>{
    return {itemShow:old.itemShow + 10}
  })
}
addToCart(e){
  this.setState((old)=>{
    return {cartCount:old.cartCount + 1}
   } )
// localStorage.clear();
this.setState({
      top:5,
    },function(){
      setTimeout(()=>{
        this.setState({
          top: -800,
        })
      },1500)
    })

this.setState({addtocart:"Product Added to Cart!"})
var count = this.state.cartCount;
console.log(this.state.cartCount)
count = parseInt(this.state.cartCount)+parseInt(1);
this.setState({cartCount:count},function(){})

localStorage.setItem('cartCount',count);
var newproducts = JSON.parse(localStorage.getItem('session'))
var products =  this.state.products[e.target.name] ;
var proid=products.id



 products['itemQty']='1';
        a = JSON.parse(localStorage.getItem('session')) || [];
        a.push(products);
        localStorage.setItem('session', JSON.stringify(a));






for(var i= 0 ; i<newproducts.length ; i++)
{
var newid = newproducts.map((key,index)=>(key.id))
var idd = newproducts[i]
  if(products.id == idd.id)
  {

const updateditems = newproducts.filter(item=>item.id !==idd.id);

localStorage.setItem('session', JSON.stringify(updateditems));

    
    var changeproduct = newproducts[i]
   
    var qty = changeproduct.itemQty
  
   products['itemQty']=parseInt(changeproduct.itemQty)+ parseInt(1);
   var a = [];
    a = JSON.parse(localStorage.getItem('session')) || [];
    var length = a.length;
    a.push(products);
    localStorage.setItem('session', JSON.stringify(a));
    
  }
}
 
}
changeCategory(e){
  this.setState({changeCategory:e.target.value},function(){
    localStorage.setItem('categoryName',this.state.changeCategory)

  })
}
handleChanged(e)
  {
    var filterId = e.target.value
    this.setState({idtoFilter:e.target.value},function(){
        this.setState({idtoFilter:filterId})
    })
    
let isChecked = e.target.checked;
if(isChecked == true){

const filterurl = `https://demo.a.eloerp.net/accounting/products_api/getFilteredProducts?filterId=${filterId}`;
fetch(filterurl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            productsFilters: result
          });
        var filter = this.state.filter
            this.state.productsFilters.map((product, index)=>(
              console.log(product),
               filter.push(product),
               this.setState({products:filter},function(){}),
               console.log(this.state.products)
              ))
        },
        (error) => {
          this.setState({ error });
        }
      )

}else{
  
const filterurl = `https://demo.a.eloerp.net/accounting/products_api/getFilteredProducts?filterId=${filterId}`;
fetch(filterurl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            unfilter: result
          });
          for(var i= 0 ; i<this.state.products.length ; i++)
          {
           
            console.log(i)
            var unfilterresult = this.state.unfilter[0]
           

          var newid = this.state.products.map((key,index)=>(key.id))
          var idd = this.state.products[i]
          
            if(this.state.unfilter[0].id == idd.id)
            {

          const updateditems = this.state.products.filter(item=>item.id !==idd.id);

          this.setState({products:updateditems})
              
            }
          }
            
        },
        (error) => {
          this.setState({ error });
        }
      )
if(this.state.products.length == 1)
{

  this.setState({products:this.state.backupproducts,function(){
    this.setState({productsFilters:[]})
  }})
  this.setState({productsFilters:[]})
  console.log(this.state.productsFilters)
}

}
}
render() {
    const { error, products, categories} = this.state;
		return (
			<div>
      <Header />
		<Container top={this.state.top}>{this.state.addtocart}</Container>		

<div className="contact_us">
   <div className="container">
      <div className="page-title">
        <h1>Blog</h1>
      </div>
   </div>
</div>



<div class="blog_wrap">
  <div class="container">
    <div class="row">
      <div class="col-sm-9 content_left">
        <div class="full_blog_img">
          <img src={deal856985} alt="" />
        </div>
        <div class="row">
          <div class="col-sm-1 date_info">
            <b>26</b>
            <span>FEB</span>
          </div>
          <div class="col-sm-11 blog_info">
            <h3>Post Format Standard</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras non placerat mi. Etiam non tellus sem. Aenean pretium convallis lorem, sit amet dapibus ante mollis a. Integer bibendum interdum sem, eget volutpat purus pulvinar in. Sed tristique augue vitae sagittis porta. Phasellus ullamcorper, dolor suscipit mattis viverra, sapien elit condimentum odio, ut imperdiet nisi risus sit amet ante. Sed sem lorem, laoreet et facilisis quis, tincidunt non lorem. Etiam tempus, dolor in sollicitudin faucibus, sem massa accumsan erat. “ Many [...]</p>

          <div class="row divider_bdr">
          <div class="col-sm-10 comments">
            <p><i class="fa fa-user"></i> BY JOE DOE <i class="fa fa-folder"></i> FASHION, MODEL <i class="fa fa-comments"></i> 0 COMMENTS</p>
          </div>
          <div class="col-sm-2 read_more">
            <a href="#">Read More</a>
          </div>
        </div>
          </div>
        </div>


        <div class="full_blog_img">
          <img src={deal8545785} alt="" />
        </div>
        <div class="row">
          <div class="col-sm-1 date_info">
            <b>27</b>
            <span>FEB</span>
          </div>
          <div class="col-sm-11 blog_info">
            <h3>Etiam laoreet sem</h3>
            <p>Quisque elementum nibh at dolor pellentesque, a eleifend libero pharetra. Mauris neque felis, volutpat nec ullamcorper eget, sagittis vel enim. Nam sit amet ante egestas, gravida tellus vitae, semper eros. Nullam mattis mi at metus egestas, in porttitor lectus sodales. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate laborum vero voluptatum.Lorem quasi aliquid maiores iusto suscipit perspiciatis a aspernatur et fuga repudiandae deleniti excepturi nesciunt animi reprehenderit similique sit. ipsum dolor sit amet, consectetur adipisicing elit. Qui at [...]</p>

          <div class="row divider_bdr">
          <div class="col-sm-10 comments">
            <p><i class="fa fa-user"></i> BY JOE DOE <i class="fa fa-folder"></i> FASHION, MODEL <i class="fa fa-comments"></i> 0 COMMENTS</p>
          </div>
          <div class="col-sm-2 read_more">
            <a href="#">Read More</a>
          </div>
        </div>
          </div>
        </div>


        <div class="full_blog_img">
          <img src={deal78965412} alt="" />
        </div>
        <div class="row">
          <div class="col-sm-1 date_info">
            <b>28</b>
            <span>FEB</span>
          </div>
          <div class="col-sm-11 blog_info">
            <h3>Post Format Standard</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras non placerat mi. Etiam non tellus sem. Aenean pretium convallis lorem, sit amet dapibus ante mollis a. Integer bibendum interdum sem, eget volutpat purus pulvinar in. Sed tristique augue vitae sagittis porta. Phasellus ullamcorper, dolor suscipit mattis viverra, sapien elit condimentum odio, ut imperdiet nisi risus sit amet ante. Sed sem lorem, laoreet et facilisis quis, tincidunt non lorem. Etiam tempus, dolor in sollicitudin faucibus, sem massa accumsan erat. “ Many [...]</p>

          <div class="row divider_bdr">
          <div class="col-sm-10 comments">
            <p><i class="fa fa-user"></i> BY JOE DOE <i class="fa fa-folder"></i> FASHION, MODEL <i class="fa fa-comments"></i> 0 COMMENTS</p>
          </div>
          <div class="col-sm-2 read_more">
            <a href="#">Read More</a>
          </div>
        </div>
          </div>
        </div>
        
      </div>
      <div class="col-sm-3 sidebar_right">
        <div class="blog_category">
          <h3>BLOG CATEGORIES</h3>
          <ul>
            <li><a href="#">Articles</a></li>
            <li><a href="#">Asides</a></li>
            <li><a href="#">Bag</a></li>
            <li><a href="#">Fashion</a></li>
            <li><a href="#">Model</a></li>
            <li><a href="#">Traveling</a></li>
            <li><a href="#">Uncategorized</a></li>
          </ul>
          <h3>RECENT POSTS</h3>
          <div class="row">
            <div class="col-sm-5 recent-post">
              <img src={discount2} alt="" />
            </div>
            <div class="col-sm-7 recent-post-info">
              <h4>Post Format Standard</h4>
              <h6>February 26, 2018</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5 recent-post">
              <img src={logo2} alt="" />
            </div>
            <div class="col-sm-7 recent-post-info">
              <h4>Post Format Standard</h4>
              <h6>February 26, 2018</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5 recent-post">
              <img src={logo4} alt="" />
            </div>
            <div class="col-sm-7 recent-post-info">
              <h4>Post Format Standard</h4>
              <h6>February 26, 2018</h6>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<div id="clockdiv" style={{"display": "none"}}>
  <div>
    <span class="days"></span>
    <div class="smalltext">Days</div>
  </div>
  <div>
    <span class="hours"></span>
    <div class="smalltext">Hours</div>
  </div>
  <div>
    <span class="minutes"></span>
    <div class="smalltext">Minutes</div>
  </div>
  <div>
    <span class="seconds"></span>
    <div class="smalltext">Seconds</div>
  </div>
</div>




<Footer />
			</div>
		);
	}
}

export default Brands;