import React, {Component} from 'react';
import Banner from './banner.js';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../assets/css/bootstrap.css';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/font-awesome.min.css';
import '../../assets/css/style.css';
import CateContent from './category-content.js';
import FleshSale from './FleshSlae.js';
import FeatureProduct from './FeaturedProduct.js';
import LComponent from './LoadComponent.js';
import Top from './Topcategory.js';
import NewArrival from './NewArrival.js';
import MostView from './MostView.js';
import MyStore from './MyStore.js';
import EventSale from './EventSale.js';
import {api_url} from '../../Config.js';
import loader from '../../assets/images/loading.gif';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
class Home extends Component {
constructor(props){
        super(props);
        this.state={
            com_url: api_url.com_url,
            changecategory:localStorage.getItem('categoryName'),
            base_url : api_url.base_url,
            settings:[],
            flesh_sale_enable:0,
            mystore_enable:0,
            featured_products_enable:0,
            featured_products_view:0,
            most_viewed_enable:0,
            newarrival_enable:0,
            eventsale_enable:0,
            loadings:false,
            products:[],
            cartCount:(localStorage.getItem('cartCount')? parseInt(localStorage.getItem('cartCount')) : 0),
        }
        this.setStateOfParent = this.setStateOfParent.bind(this);
    }
    async componentDidUpdate() {
    $('ul.tabs_featured li').click(function(){
        var tab_id = $(this).attr('data-tab');

        $('ul.tabs_featured li').removeClass('current');
        $('.tab-content_featured').removeClass('current');

        $(this).addClass('current');
        $("#"+tab_id).addClass('current');
      })
   }
   async setStateOfParent (result) {

      await this.setState({
        cartCount:result
      },()=>{
        localStorage.setItem('cartCount',result);
        localStorage.setItem('cartCount',result);

      })
    }

    async componentDidMount(){

      var filterId = this.state.filterId;

    const apiUrl = this.state.base_url+'products_api/getProducts/15/all/all/featuredproducts/1';

    this.setState({products:[]});
    var api_Data = {user_id:sessionStorage.getItem('userData')};
    await fetch(apiUrl,{
          method: 'POST',
          headers:
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body:JSON.stringify(api_Data)
        })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            products: result.products,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )



        if(localStorage.getItem('success'))
        {
            localStorage.removeItem('success');
        }
        this.setState({loadings:false})
    const categoryUrl =this.state.com_url+'moiz_api/get_admin_settings';
        fetch(categoryUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            flesh_sale_enable:result[0].flesh_sale_enable

          },()=>{
                this.setState({
                    featured_products_view:result[0].featured_products_view,
                    newarrival_enable:result[0].new_arrival_enable,
                    most_viewed_enable:result[0].most_viewed_enable,
                    mystore_enable:result[0].mystore_enable,
                    eventsale_enable:result[0].event_sale_enable,
                    loadings:false,
                    featured_products_enable:result[0].featured_products_enable,
                   settings: result
                })
          });
        },
        (error) => {
          this.setState({ error , loadings:false });
        }
      )


    }

render() {

return (


<div>
<Header />
<Banner />
<div>
    <section className="tabs_blog">
        <div className="container">
            {<CateContent  /> || <Skeleton />}
        </div>
            <FleshSale setStateOfParent={this.setStateOfParent} />


        {

        <React.Fragment>
        <Top setStateOfParent={this.setStateOfParent} />

        {

          <div className="container" id="dynamic-tabs">
        <ul className="tabs_featured">

        {(this.state.featured_products_enable==="1" )? <li className="tab-link_featured current" data-tab="tab-26">Featured Products</li> : null}

        {(this.state.newarrival_enable==="1")?<li className="tab-link_featured" data-tab="tab-27">New Arrivals</li> : null}

        {(this.state.most_viewed_enable==="1")? <li className="tab-link_featured" data-tab="tab-28">Most Viewed</li> : null}
        {
            sessionStorage.getItem('userData') &&

        (this.state.mystore_enable==="1")? <li className="tab-link_featured" data-tab="tab-29">My Store</li> : null
        }
          </ul>

          <div id="tab-26" className="tab-content_featured current">
                {(this.state.featured_products_enable==="1" )? <FeatureProduct setStateOfParent={this.setStateOfParent} /> : <LComponent />}
              </div>
              <div id="tab-27" className="tab-content_featured">
                {(this.state.newarrival_enable==="1")? <NewArrival setStateOfParent={this.setStateOfParent} /> : <LComponent />}
              </div>
              <div id="tab-28" className="tab-content_featured">
               {(this.state.most_viewed_enable==="1")? <MostView setStateOfParent={this.setStateOfParent} /> : <LComponent />}
              </div>

              <div id="tab-29" className="tab-content_featured">
                {(this.state.mystore_enable==="1")? <MyStore setStateOfParent={this.setStateOfParent} /> : <LComponent />}
              </div>
        </div>
        }


        </React.Fragment>
    }





    {(this.state.eventsale_enable==="1")? <EventSale /> : null}


    </section>
               {
                   this.state.loading
                    ?
                        <div style={{textAlign:'center'}}>
                             <img src={loader} alt="loader" />
                         </div>

                     :
                     <div className="footer_btm">
                  <ul>
                    <li>
                      <span>
                        <svg  aria-hidden="true" focusable="false" width="30px" height="30px"  preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#626262"/></svg>
                        Home
                      </span>
                    </li>
                    <li>
                      <span>
                        <svg  aria-hidden="true" focusable="false" width="30px" height="30px"  preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M3 8V7h17v1H3zm17 4v1H3v-1h17zM3 17h17v1H3v-1z" fill="#626262"/></svg>
                        Category
                      </span>
                    </li>
                    <li>
                      <span>
                        <svg  aria-hidden="true" focusable="false" width="30px" height="30px"   preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M9.5 4a6.5 6.5 0 0 1 4.932 10.734l5.644 5.644l-.707.707l-5.645-5.645A6.5 6.5 0 1 1 9.5 4zm0 1a5.5 5.5 0 1 0 0 11a5.5 5.5 0 0 0 0-11z" fill="#626262"/></svg>
                        Search
                      </span>
                    </li>
                    <li>
                      <span>
                        <svg  aria-hidden="true" focusable="false" width="30px" height="30px"  preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M16 18a2 2 0 1 1 0 4a2 2 0 0 1 0-4zm0 1a1 1 0 1 0 0 2a1 1 0 0 0 0-2zm-9-1a2 2 0 1 1 0 4a2 2 0 0 1 0-4zm0 1a1 1 0 1 0 0 2a1 1 0 0 0 0-2zM18 6H4.273l2.547 6H15a.994.994 0 0 0 .8-.402l3-4h.001A1 1 0 0 0 18 6zm-3 7H6.866L6.1 14.56L6 15a1 1 0 0 0 1 1h11v1H7a2 2 0 0 1-1.75-2.97l.72-1.474L2.338 4H1V3h2l.849 2H18a2 2 0 0 1 1.553 3.26l-2.914 3.886A1.998 1.998 0 0 1 15 13z" fill="#626262"/></svg>
                        Cart
                      </span>
                    </li>
                  </ul>
                </div>
               }

            </div>


<Footer />

</div>
);
}
}

export default Home;
