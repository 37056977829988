import React, { Component } from 'react';
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import $ from 'jquery';
import full6 from '../assets/images/full_6.png';
import { NavLink } from 'react-router-dom';
import { api_url } from '../Config.js';
import loader from '../assets/images/loading.gif';
import styled from 'styled-components';
import { PostData } from '../services/PostData';
import DComponent from './Home/DynamicComponent.js';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const Container = styled.div``;
class SearchResults extends Component {
   constructor(props) {
      super(props);
      this.state = {
         error: null,
         products: [],
         backupproducts: [],
         cartProducts: [],
         brands: [],
         itemShow: 10,
         categories: [],
         categories_ids: [],
         subcategories: [],
         subcategories_ids: [],
         productsFilters: [],
         filter: [],
         categoriesss: [],
         totalItems: "",
         addtocart: '',
         itemquantity: 1,
         items: [],
         cartCount: (localStorage.getItem('cartCount') ? parseInt(localStorage.getItem('cartCount')) : 0),
         filterId: localStorage.getItem('filterId'),
         base_url: api_url.base_url,
         com_url: api_url.com_url,
         img_url: api_url.img_url,
         filterData: [],
         fillterCategory: '',
         filterOption: '',
         changeCategory: "",
         backupSubcategories: [],
         loading: false,
         totalProducts: 0,
         filterValue: '',
         category_feature_image: '',
         loading_btn: false,
         view_all_loading: false,
         lastitem_id: 0,
         sub_category_id: 'all',
         sort_by: 'popularity',
         'order_by': 'ASC',
         limit: 50,
         test_state:'SomeString',
         cate_id: this.props.match.params.category_id ? props.match.params.category_id : 'all',
         product_filter: this.props.match.params.product_filter ? props.match.params.product_filter : 'all',
         special_filter: this.props.match.params.special_filter ? props.match.params.special_filter : 'all',
      }
      this.checkProducts = this.checkProducts.bind(this);
      this.getDefaultProducts = this.getDefaultProducts.bind(this);
      this.setStateOfParent = this.setStateOfParent.bind(this);
      this.sortProducts = this.sortProducts.bind(this);
      // this.order_by = this.order_by.bind(this);
   }


   sortProducts(field, type) {

   }
   quickSort(sortby = null) {
      this.setState({
         loading:true,
         test_state:'stingValue'
      })
      var products = this.state.products;
      const order_by = this.state.order_by;
      var filter = 'ASC';
      var prev = this.state.order_by;
      if (prev === 'ASC') {
         filter = 'DESC';
         $('.' + sortby + '_teer').removeClass('fa-sort-amount-desc');
         $('.' + sortby + '_teer').addClass('fa-sort-amount-asc');
      } else {
         $('.' + sortby + '_teer').removeClass('fa-sort-amount-asc');
         $('.' + sortby + '_teer').addClass('fa-sort-amount-desc');
      }
      var sorted = [];
      var productsSort = [];
      this.setState({ order_by: filter }, () => {
         if (sortby === 'newest') {
            if (order_by === 'ASC') {
               console.log('sort price by ASC')
               const productsSorts = [].concat(this.state.products)
                  .sort((a, b) => parseInt(a.created_on) > parseInt(b.created_on) ? 1 : -1)
               this.setState({
                  products: productsSorts,
                  backupproducts: productsSorts,
               }, () => {
                  console.log(productsSorts)
               })
            } else {
               console.log('sort price by DESC')
               const productsSorts = [].concat(this.state.products)
                  .sort((a, b) => parseInt(a.created_on) < parseInt(b.created_on) ? 1 : -1)
               this.setState({
                  products: productsSorts,
                  backupproducts: productsSorts,
               }, () => {
                  console.log(productsSorts)
               })
            }
         }
         if (sortby === 'price') {
            if (order_by === 'ASC') {
               console.log('sort price by ASC')
               const productsSorts = [].concat(this.state.products)
                  .sort((a, b) => parseInt(a.calculation_rate) > parseInt(b.calculation_rate) ? 1 : -1)
               this.setState({
                  products: productsSorts,
                  backupproducts: productsSorts,
               }, () => {
                  console.log(productsSorts)
               })
            } else {
               console.log('sort price by DESC')
               const productsSorts = [].concat(this.state.products)
                  .sort((a, b) => parseInt(a.calculation_rate) < parseInt(b.calculation_rate) ? 1 : -1)
               this.setState({
                  products: productsSorts,
                  backupproducts: productsSorts,
               }, () => {
                  console.log(productsSorts)
               })
            }
         }
         if (sortby === 'discount') {
            if (order_by === 'ASC') {
               console.log('sort price by ASC')
               const productsSorts = [].concat(this.state.products)
                  .sort((a, b) => parseFloat((100 - (a.discounted_price / a.saleRate) * 100)).toFixed(0) > parseFloat((100 - (b.discounted_price / b.saleRate) * 100)).toFixed(0) ? 1 : -1)
               this.setState({
                  products: productsSorts,
                  backupproducts: productsSorts,
               }, () => {
                  console.log(productsSorts)
               })
            } else {
               console.log('sort price by DESC')
               const productsSorts = [].concat(this.state.products)
                  .sort((a, b) => parseFloat((100 - (a.discounted_price / a.saleRate) * 100)).toFixed(0) < parseFloat((100 - (b.discounted_price / b.saleRate) * 100)).toFixed(0) ? 1 : -1)
               this.setState({
                  products: productsSorts,
                  backupproducts: productsSorts,
               }, () => {
                  console.log(productsSorts)
               })
            }
         }
         if (sortby === 'popularity') {
             
            if (order_by === 'ASC') {
               console.log('sort price by ASC')
               const productsSorts = [].concat(this.state.products)
                  .sort((a, b) => parseInt(a.most_views_item) > parseInt(b.most_views_item) ? 1 : -1)
               this.setState({
                  products: productsSorts,
                  backupproducts: productsSorts,
               }, () => {
                  console.log(productsSorts)
               })
            } else {
               console.log('sort price by DESC')
               const productsSorts = [].concat(this.state.products)
                  .sort((a, b) => parseInt(a.most_views_item) < parseInt(b.most_views_item) ? 1 : -1)
               this.setState({
                  products: productsSorts,
                  backupproducts: productsSorts,
               }, () => {
                  console.log(productsSorts)
               })
            }
         }
      });

      // console.log(products)

      $('.discount_active').removeClass('active');
      $('.newest_active').removeClass('active');
      $('.popularity_active').removeClass('active');
      $('.price_active').removeClass('active');
      $('.' + sortby + '_active').addClass('active');
      // console.log(products);
      this.setState({
         // products: productsSort,
         // backupproducts: productsSort,
         sort_by: sortby,
         loading:false,
         test_state:'stingValue'
      }, () => {
         // console.log(products)
         this.getSubCategories('', this.state.cate_id);
      })
   }
   async setStateOfParent(result) {
      await this.setState({
         cartCount: result
      }, () => {
         localStorage.setItem('cartCount', result);
         localStorage.setItem('cartCount', result);
         console.log(localStorage.getItem('cartCount'));
      })
   }
   componentDidUpdate() {
      $(".onclick_sortby").click(function () {
         $(".hide_category_items").toggle();
      });
      var selector = '.category_filter .tab-content-sort a';
      $(selector).on('click', function () {
         $(selector).removeClass('active');
         $(this).addClass('active');
      });
   }
   componentDidMount() {
      const caturlss = this.state.base_url + 'products_api/GetCategories';
      fetch(caturlss)
         .then(res => res.json())
         .then(
            (result) => {
               this.setState({
                  categoriesss: result,
                  categories: result

               });
            },
            (error) => {
               this.setState({ error });
            }
         )

      this.setState({ loading: true })
      const allSub = this.state.base_url + `products_api/GetAllSubCategories`;
      fetch(allSub)
         .then(res => res.json())
         .then(
            (result) => {
               this.setState({
                  subcategories: result,
                  backupSubcategories: result

               });

            },
            (error) => {
               this.setState({ error, loading: false });
            }
         )
      $('.cart_btn_a').on('click', function () {
         $('.cart_btn_a').removeClass('active');
         $(this).addClass('active');
         alert('ok');
      });





      $(".heart").click(function () {
         $(this).toggleClass('fa-heart');
         $(this).toggleClass('fa-heart-o');

      });
      $(".cart").click(function () {
         $(this).toggleClass('fa-shopping-cart');
         $(this).toggleClass('fa-cart-plus');
      });

      localStorage.removeItem('calculation_rate');
      localStorage.removeItem('unit_id');
      localStorage.removeItem('unit_name');

      this.getDefaultProducts(this.state.cate_id, this.state.sub_category_id, this.state.product_filter, this.state.special_filter, this.state.limit, this.state.sort_by, this.state.order_by, 0);
      $(document).on('click', 'ul.tabs li', function () {
         // $('ul.tabs li').click(function(){
         var tab_id = $(this).attr('data-tab');

         $('ul.tabs li').removeClass('current');
         $('.tab-content').removeClass('current');

         $(this).addClass('current');
         $("#" + tab_id).addClass('current');
      })
      $(".tab-section- .tabs a").on("click", function (e) {
         $(this.hash).slideDown().siblings().slideUp();
         e.preventDefault();
      }).first().click();

      // for active class
      $(".tab-section- .tabs a").on("click", function () {
         $(".tabs a.active").removeClass("active");
         $(this).addClass("active");
      });

      $('ul.tabs_sort li').click(function () {
         var tab_id = $(this).attr('data-tab');
         $('ul.tabs_sort li').removeClass('active');
         $('.tab-content-sort').removeClass('active');
         $(this).addClass('active');
         $("#" + tab_id).addClass('active');
      });

      $(".collapsed").click(function () {
         $(".collapse_info").slideToggle();
      });

      $(".cata-sub-slider").on('scroll', function () {
         var val = $(this).scrollLeft();
         if ($(this).scrollLeft() + $(this).innerWidth() >= $(this)[0].scrollWidth) {
            $(".cata-sub-slider .nav-next-").hide();
         } else {
            $(".cata-sub-slider .nav-next-").show();
         }
         if (val === 0) {
            $(".cata-sub-slider .nav-prev-").hide();
         } else {
            $(".cata-sub-slider .nav-prev-").show();
         }
      });
      $(".cata-sub-slider .nav-next-").on("click", function () {
         $(".cata-sub-slider").animate({ scrollLeft: '+=185' }, 200);

      });
      $(".cata-sub-slider .nav-prev-").on("click", function () {
         $(".cata-sub-slider").animate({ scrollLeft: '-=185' }, 200);
      });




      $(".cata-sub-nav").on('scroll', function () {
         var val = $(this).scrollLeft();

         if ($(this).scrollLeft() + $(this).innerWidth() >= $(this)[0].scrollWidth) {
            $(".nav-next").hide();
         } else {
            $(".nav-next").show();
         }

         if (val === 0) {
            $(".nav-prev").hide();
         } else {
            $(".nav-prev").show();
         }
      });
      $(".nav-next").on("click", function () {
         $(".cata-sub-nav").animate({ scrollLeft: '+=43.2' }, 200);

      });
      $(".nav-prev").on("click", function () {
         $(".cata-sub-nav").animate({ scrollLeft: '-=43.2' }, 200);
      });
      // let cate_id = this.state.cate_id;
      // let e = '';
      // this.getByAllCategoryID(e,cate_id);
   }

   getDefaultProducts(cate_id, sub_category_id, product_filter, special_filter, limit, sort_by, order_by, lastitem_id, btn = null) {

      $('.discount_active').removeClass('active');
      $('.newest_active').removeClass('active');
      $('.popularity_active').removeClass('active');
      $('.price_active').removeClass('active');
      $('.' + sort_by + '_active').addClass('active');

      this.setState({
         sort_by: sort_by,
         cate_id: cate_id,
         sub_category_id: sub_category_id,
         order_by: order_by,
         product_filter: product_filter,
         special_filter: special_filter,
         limit: limit,
         lastitem_id: lastitem_id
      }, function () {
         if (btn == 'load_more') {
            this.setState({
               loading_btn: true
            })
         }
         if (btn == 'view_all') {
            this.setState({
               view_all_loading: true
            })
         }
         // this.setState({ loading: true });
         if (btn == null) {
            this.setState({
               loading: true
            })
         }
         var api_Data = {
            user_id: localStorage.getItem('userData'),
            cate_id: this.state.cate_id,
            sub_category_id: this.state.sub_category_id,
            special_filter: this.state.special_filter,
            limit: this.state.limit,
            product_filter: this.state.product_filter,
            sort_by: this.state.sort_by,
            order_by: this.state.order_by,
            lastitem_id: this.state.lastitem_id,
         };

         const apiUrl = this.state.base_url + 'products_api/getProductsSearch';
         fetch(apiUrl, {
            method: 'POST',
            headers:
            {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
            },
            body: JSON.stringify(api_Data)
         })
            .then(res => res.json())
            .then(
               (result) => {
                  let newproducts = result.products;
                  // return;
                  if (result.products) {

                     if (btn == null || btn=='view_all') {
                        this.setState({
                           products: result.products,
                           backupproducts: result.products,
                           totalProducts: result.total,
                           loading: false,
                           loading_btn: false,
                           view_all_loading: false,
                           lastitem_id: 0,
                           category_feature_image: (result.products.length > 0 && result.products[0].feature_image !== undefined) ? result.products[0].feature_image : '',
                        });
                     } else {
                        this.setState({
                           products: [...this.state.backupproducts, ...newproducts],
                           backupproducts: [...this.state.backupproducts, ...newproducts],
                           totalProducts: result.total,
                           // loading: false,
                           loading_btn: false,
                           view_all_loading: false,
                           lastitem_id: parseFloat(this.state.lastitem_id),
                           category_feature_image: (result.products.length > 0 && result.products[0].feature_image !== undefined) ? result.products[0].feature_image : '',
                        });
                     }


                  } else {
                     this.setState({
                        loading: false,
                        loading_btn: false,
                        view_all_loading: false,
                     });
                  }
                  if (result.category_id) {
                     this.getSubCategories('', result.category_id);
                  }
               },
               (error) => {
                  this.setState({ error, loading: false });
               }
            )
      })
   }

   getSubCategories = (e, cate_id) => {
      this.setState({ loading: true });
      const allSub = this.state.base_url + 'products_api/GetAllSubCategories/' + cate_id;
      fetch(allSub)
         .then(res => res.json())
         .then(
            (result) => {
               this.setState({
                  subcategories: result,
                  backupSubcategories: result,
                  loading: false
               });
            },
            (error) => {
               this.setState({ error, loading: false });
            }
         )
   }
   checkProducts() {
      this.setState({ loading: true });
      setTimeout(() => {
         this.setState({ loading: false });
         return ('No Data Found');
      }, 2000);
   }


   componentWillUnmount() {
      console.log('component is unmounted');
   }



   render() {
      var skeltons = [];
      for (var i = 0; i < 49; i++) {
         skeltons.push(<Skeleton height={200} width={160} />);
      }
      const { products, categories, loading, loading_btn, view_all_loading } = this.state;
      var feature_image_path = this.state.img_url + '/uploads/category_images/home14_category_banner.42988c51.jpg';
      // if(this.state.category_feature_image)
      // {
      //   feature_image_path = this.state.img_url+'/'+this.state.category_feature_image;
      // }
      var sectionStyle = {
         backgroundImage: `url(${feature_image_path})`
      };
      return (
         <div>
            <Header />
            <Container top={this.state.top}>{this.state.addtocart}</Container>
            <section className="main_wrap" id="mobile_box">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-12 col-md-12 col-sm-12 sidebar_category scrollbar" >
                        <ul className="scrollbar" id="style-4">

                           {this.state.categoriesss.map((cat, index) => (
                              <NavLink to={`/search-results/${cat.id}/all/all`} key={index}>
                                 <li key={index} className="cl-item-women" value={cat.id} onClick={e => this.getDefaultProducts(cat.id, this.state.sub_category_id, "all", this.state.special_filter, this.state.limit, this.state.sort_by, this.state.order_by, 0)}>
                                    <img src={cat.icon_image || <Skeleton />} alt="skelton" />
                                    {cat.groupName || <Skeleton />}
                                 </li>
                              </NavLink>
                           ))}

                        </ul>
                     </div>
                  </div>
               </div>
            </section>
            <div className="banner-container my-banner">
               <div id="banner-wrapper" className="products-category" style={sectionStyle}>
                  <div className="container">
                     <div className="deals_box">
                        <p> <span></span></p>
                     </div>
                  </div>
               </div>
            </div>
            <div className="tabs_blog search_result_shop">
               <div className="container">
                  <div className="row flash_categories">
                     <div className="col-sm-12 padd_none">
                        <div className="tabs medical_box">
                           <div className="row">
                              <div className="col-sm-12 tabs padd_none cata_sub_items">
                                 <div className="cata-sub-slider">
                                    <div className="nav-prev- arrow" style={{ 'display': 'none' }}>
                                       <i className="fa fa-angle-left"></i>
                                    </div>
                                    <ul className="tabs">
                                       {categories.map((product, index) => (
                                          <li className={product.id === this.state.cate_id ? 'current tab-link' : 'tab-link'} style={{ 'cursor': 'pointer' }} value={product.id} key={index} onClick={e => this.getDefaultProducts(product.id, 'all', "all", this.state.special_filter, this.state.limit, this.state.sort_by, this.state.order_by, 0)}>
                                             {(
                                                product.icon_image ? <img src={product.icon_image} alt="product" />
                                                   :
                                                   <img src={full6} alt="product" />
                                             )}
                                             <b>{product.groupName}</b>
                                          </li>
                                       ))}
                                    </ul>
                                    <div className="nav-next- arrow">
                                       <i className="fa fa-angle-right"></i>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="row product__search">
                     <div className="col-sm-8">
                        <div className="search_head">
                           <h3>Products</h3>
                           <ul className="sortby">
                              <li><strong>Sort by:</strong></li>
                              <li className="popularity_active" style={{ cursor: 'pointer' }} onClick={() => this.quickSort('popularity')}>
                                 <span className="active" >
                                    Popularity

                                 </span>
                                 <i className="fa fa-sort-amount-desc popularity_teer">
                                 </i>
                              </li>
                              <li className="newest_active" onClick={() => this.quickSort('newest')} style={{ cursor: 'pointer' }}>
                                 <span className="" >
                                    Newest
                                    <i className="fa fa-sort-amount-desc newest_teer">
                                    </i>
                                 </span>

                              </li>
                              <li className="price_active" onClick={() => this.quickSort('price')} style={{ cursor: 'pointer' }}>
                                 <span className="" >
                                    Price
                                    <i className="fa fa-sort-amount-desc price_teer">
                                    </i>
                                 </span>

                              </li>
                              <li className="discount_active" style={{ cursor: 'pointer' }} onClick={() => this.quickSort('discount')}>
                                 <span className="" >Discount
                                    <i className="fa fa-sort-amount-desc discount_teer">
                                    </i>
                                 </span>

                              </li>
                           </ul>
                        </div>
                     </div>
                     <div className="col-sm-4 filter_category">
                        <label>Special Filter</label>
                        <select onChange={(e) => this.getDefaultProducts(this.state.cate_id, this.state.sub_category_id, "all", e.target.value, this.state.limit, this.state.sort_by, this.state.order_by, 0)}>
                           <option value="all">All</option>
                           <option value='featuredproducts'>Featured Products</option>
                           <option value='newarrivals'>New Arrivals</option>
                           {(
                              localStorage.getItem('userData') > 0 ?
                                 <option value='mystore'>My Store</option>
                                 : ""
                           )}
                           <option value='eventsale'>Event Sale</option>
                           <option value='mostviewed'>Most Viewed</option>
                        </select>
                     </div>
                  </div>
                  {
                     loading ?
                        <div style={{ textAlign: 'center', padding: '12px 0', background: 'white' }}>
                           {/* <img src={loader} alt="loader" /> */}
                           {
                              skeltons.map((skelton) => {
                                 return (
                                    skelton
                                 )
                              })
                           }

                        </div>
                        :
                        <React.Fragment>
                           <div className="col-sm-2 category_filter">
                              <h3 className="hide_sortby">Sub Categories</h3>
                              <h3 className="onclick_sortby">SORT BY <i className="fa fa-angle-down hide_mobile_arrow"></i></h3>
                              <div className="hide_category_items">
                                 <ul className="tabs_sort">
                                    {/*<li className="tab-link active" data-tab="tab-31">Categories</li>*/}
                                    {/*<li className="tab-link" data-tab="tab-32">Brands</li>*/}
                                 </ul>
                                 <div id="tab-31" className="tab-content-sort active">
                                    {this.state.subcategories.map(product => (
                                       <span onClick={e => this.getDefaultProducts(this.state.cate_id, product.id, "all", this.state.special_filter, this.state.limit, this.state.sort_by, this.state.order_by, 0)} key={product.id}>
                                          <p className="checkbox" key={product.id}>
                                             <label>
                                                <span className="lbl padding-8">{product.groupName}</span>
                                             </label>
                                          </p>
                                       </span>
                                    ))}
                                 </div>
                                 <div id="tab-32" className="tab-content-sort">
                                    {this.state.brands.map(product => (
                                       <span onClick={event => this.brand_click(event, product.id)} key={product.id}>
                                          <p className="checkbox">
                                             <label>
                                                <span className="lbl padding-8">{product.brandName}</span>
                                             </label>
                                          </p>
                                       </span>
                                    ))}
                                 </div>
                              </div>
                           </div>
                           <div className="col-sm-10 new_arrivals" id="search_result-page">
                              <section className="best_deal">
                                 <div className="shadow_box">
                                    <div className="row">
                                       {
                                          (this.state.products.length > 0) &&
                                          products.map((product, index) => (
                                             <DComponent setStateOfParent={this.setStateOfParent} title={product} key={index} user_id={localStorage.getItem('userData')} value={index} />
                                          ))
                                       }
                                    </div>
                                    {
                                       !loading &&
                                       this.state.totalProducts > this.state.products.length && this.state.products.length > 49 && 
                                       <div className="read_more_btn">
                                          <button
                                             className="loadmore_products"
                                             style={{ fontSize: "18px" }}
                                             type='button'
                                             onClick={() => this.getDefaultProducts(this.state.cate_id, this.state.sub_category_id, "all", this.state.special_filter, this.state.limit, this.state.sort_by, this.state.order_by, parseFloat(this.state.lastitem_id) + 1, 'load_more')}
                                             disabled={loading_btn}
                                          >
                                             {loading_btn && (
                                                <i
                                                   className="fa fa-refresh fa-spin"
                                                   style={{ marginRight: "5px" }}
                                                />
                                             )}
                                             {loading_btn && <span>Loading...</span>}
                                             {!loading_btn && <span>Load More</span>}
                                          </button>

                                          <button
                                             type='button'
                                             onClick={() => this.getDefaultProducts(this.state.cate_id, this.state.sub_category_id, "all", this.state.special_filter, 'all', this.state.sort_by, this.state.order_by, 'all', 'view_all')}
                                             disabled={view_all_loading}
                                          >
                                             {view_all_loading && (
                                                <i
                                                   className="fa fa-refresh fa-spin"
                                                   style={{ marginRight: "5px" }}
                                                />
                                             )}
                                             {view_all_loading && <span>Loading...</span>}
                                             {!view_all_loading && <span>View All</span>}
                                          </button>
                                       </div>

                                    }
                                    {
                                       this.state.products.length === 0 &&
                                       <div style={{ textAlign: 'center', padding: '12px 0', background: 'white' }}>
                                          No Record Found !
                                       </div>
                                    }
                                 </div>
                              </section>

                           </div>
                        </React.Fragment>
                  }
               </div>
            </div>
            <Footer />
         </div>
      );
   }
}
export default SearchResults;