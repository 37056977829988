import React, {Component} from 'react';
import twitter from '../../assets/images/twitter.png';
import facebook from '../../assets/images/facebook.png';
import googleplus from '../../assets/images/google-plus.png';
import {Redirect} from 'react-router-dom';
import {PostData} from '../../services/PostData';
import {NavLink} from 'react-router-dom';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';
class Login extends Component {
constructor(){
super();
this.state = {
username: '',
password: '',
redirectToReferrer: false
};
this.login = this.login.bind(this);
this.onChange = this.onChange.bind(this);
}
login() {
if(this.state.username && this.state.password){
PostData(this.state).then((result) => {
let responseJson = result;
if(responseJson.userData){
sessionStorage.setItem('userData',JSON.stringify(responseJson));
this.setState({redirectToReferrer: true});
}
else
alert(result.error);
});
}
}
onChange(e){
this.setState({[e.target.name]:e.target.value});
}
render() {
if (this.state.redirectToReferrer) {
return (<Redirect to={'/home'}/>)
}
if(sessionStorage.getItem('userData')){
return (<Redirect to={'/home'}/>)
}
return (
   <div>
   <Header />
<div className="form_wrap">
            <div className="container">
               <div className="row middle_form">
                  <div className="col-sm-8 sign_up_left">
                     <div className="social_media">
                        <h3>Sign In</h3>
                        <p>Sign In with social media</p>
                        <ul>
                           <li><a href="#" target="_blank"><img src={twitter} alt="" /></a></li>
                           <li><a href="#" target="_blank"><img src={facebook} alt="" /></a></li>
                           <li><a href="#" target="_blank"><img src={googleplus} alt="" /></a></li>
                        </ul>
                        <span>Or with Email</span>
                     </div> 




                        <input type="text" name="username"  placeholder="Username" onChange={this.onChange} />
                        <input type="password" name="password" placeholder="Password" onChange={this.onChange} />
                        <div className="login_form">
                           <a href="#">Forgot Your Password?</a>
                            <button type="submit" onClick={this.login}>Login</button>
                        </div>
                     
                  </div>
                  <div className="col-sm-4 login_right_bg">
                     <div className="details_info">
                        <h3>Hello, Friend!</h3>
                        <p><span>Enter your personal Login </span> Detail and start journey with Us!</p>
                        <NavLink to="/signup">Sign Up</NavLink>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <Footer />
         </div>
);
}
}
export default Login;