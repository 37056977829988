import React, { Component } from 'react';

import envalope from '../../assets/images/envelope.png';
import app1 from '../../assets/images/app1.png';
import app2 from '../../assets/images/app2.png';
import {NavLink} from 'react-router-dom';
import {api_url} from '../../Config.js';

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			base_url:api_url.base_url,
      		img_url:api_url.img_url,
      		com_url:api_url.com_url,
      		title:'',
      		date:'',
      		phone:'',
		};
	}
	componentDidMount(){
		const home_setting = this.state.com_url+'home_api/gethomesetting';
	    fetch(home_setting)
	      .then(res => res.json())
	      .then(
	        (result)=>{
	          this.setState({
	            title:result.data.detail.title,
	            date:result.data.detail.date,
	            phone:result.data.detail.phone,

	          });
	        },
	        (error) => {
	          this.setState({error});
	        }
	      )
	}
	render() {
		const phone_number = this.state.phone;
    	const whatsappurl = "https://wa.me/"+phone_number;
		return (
			<div>
			<footer className="footer_wrap" id="footer_wrapper">
			   <div className="container">
				   <div className="need_help">
				      <div className="row">
				         <div className="col-sm-4 need_helping">
				            <b>NEED HELP?</b>
				            <p>SUPPORT TEAM 24/7 AT {this.state.phone}</p>
				         </div>
				         <div className="col-sm-8 subscribe_box">
				            <input type="text" placeholder="Enter your email" />
				            <img src={envalope} alt="" />
				            <button type="button">Subscribe</button>
				         </div>
				      </div>
				   </div>
				   <div className="row download_info">
				      <div className="col-sm-8 footer_links">
				         <ul>
				            <li><NavLink to="/contact-us">CONTACT US</NavLink></li>
				            <li><NavLink to="/about-us">ABOUT US</NavLink></li>
				         </ul>
				         <p>©{this.state.date}  {this.state.title}. All Rights Reserved.</p>
				      </div>
				   </div>
			   </div>
			</footer>


			<div className="whats_app">
			<a href={whatsappurl} target="_blank"><i className="fa fa-whatsapp"></i> </a>
			</div>
				
			</div>
		);
	}
}

export default Footer;