import React, { Component } from 'react';
import Header  from "./Header/Header";
import Footer  from "./Footer/Footer";
import {Redirect} from 'react-router-dom';
import {PostData} from '../services/PostDataContact.js';
import {api_url} from '../Config.js';
import swal from 'sweetalert';

 class ContactUs extends Component {
 	constructor(props){
 		super(props);
 		this.state={
 			name:'',
 			nameErr:'',
 			email:'',
 			emailErr:'',
 			subject:'',
 			subErr:'',
 			message:'',
 			msgErr:'',
 			successMsg:'',
 			user_id:'',
 			base_url:api_url.base_url,
 		}
 	}

 	componentDidMount()
 	{
 		this.setState({user_id:(localStorage.getItem('userData'))? localStorage.getItem('userData') : ''},function(){



 		if(this.state.user_id!='')
 		{
 			fetch(this.state.base_url+`products_api/getUserDetails?userId=${localStorage.getItem('userData')}`)
 			.then(res=> res.json())
 			.then(result=>{
 				console.log(result)
 				this.setState({
 					name:result.first_name +' '+result.last_name,
 					email:result.email
 				})

 			},
	 			(error) => {
	          this.setState({ error });
	        }

        )


 		}

	})

 	}

 	onChange(e)
 	{
 		this.setState({[e.target.name]:e.target.value});
 	}

 	async contactUs(){
 		if(this.state.name == '' && this.state.email=='' && this.state.subject=='' && this.state.message=='')
      {
         this.setState(
            {
               nameErr:'Please enter your name',
               emailErr: 'Please enter your email',
               subErr: 'Please enter your subject',
               msgErr: 'Please enter your message',
            }

         )
      }
      else if(this.state.name =='')
      {
         this.setState(
            {
               enameErr:'Please enter your name',
               emailErr: '',
               subErr: '',
               msgErr: '',
            }
         )
      }else if(this.state.email == '')
      {
         this.setState(
            {
               nameErr:'',
               emailErr: 'Please enter your email',
               subErr: '',
               msgErr: '',
            }
         )
      }else if(this.state.subject == '')
      {
         this.setState(
            {
               nameErr:'',
               emailErr: '',
               subErr: 'Please enter your subject',
               msgErr: '',
            }
         )
      }else if(this.state.message == '')
      {
         this.setState(
            {
               nameErr:'',
               emailErr: '',
               subErr: '',
               msgErr: 'Please enter your message',
            }
         )
      }else{
      	this.setState({
      					nameErr:'',
               	emailErr: '',
               	subErr: '',
               	msgErr: '',
      	})
       await  PostData(this.state).then(result=>{
        	console.log(result)
        	swal(result)
        	if(this.state.user_id==''){
        		this.setState({
        		subject:'',
        		message:'',
        		name:'',
        		email:''
        	},function(){
        		console.log(this.state)
        	})

        	}else{
        		this.setState({
        		subject:'',
        		message:'',
        	},function(){
        		console.log(this.state)
        	})
        	}


        })
      }
 	}


render() {

	return (
	  <div>
<Header />

		<div className="showcase-wrap contact-us">
		   <div className="container">
		      <div className="row">
		        <div className="col-lg-12">
		            <div className="page-title">
		              <h1>Contact Us</h1>
		            </div>
		        </div>
		      </div>
		   </div>
		</div>

		<div className="legal-content-wrap sec-padd light-gray-bg">
		    <div className="container">
		        <div className="row">
		            <div className="col-lg-6 col-md-6 col-sm-12 m-p-0">
		              <div className="legal-content contact-form">
		                <h4>KEEP IN CONTACT WITH US</h4>
		                      <div className="form-group">
		                          <label>Your Name<span>*</span></label>
		                          <input className="form-control" type="text" name="name"  value={this.state.name} placeholder="Your Name" onChange={this.onChange.bind(this)} />
		                          <span style={{color: "red"}}>{this.state.nameErr}</span>
		                      </div>

		                      <div className="form-group">
		                          <label>Email<span>*</span></label>
		                          <input className="form-control" type="email" name="email" placeholder="Your Email"  defaultValue={this.state.email}  onChange={this.onChange.bind(this)} />
		                          <span style={{color: "red"}}>{this.state.emailErr}</span>
		                      </div>
		                      <div className="form-group">
		                          <label>Subject<span>*</span></label>

		                          	<input className="form-control" type="text" name="subject"  value={this.state.subject} placeholder="Your Subject" onChange={this.onChange.bind(this)} />

		                          <span style={{color: "red"}}>{this.state.subErr}</span>
		                      </div>

		                      <div className="form-group">
		                          <label>Message<span>*</span></label>
		                          <textarea className="form-control" name="message" rows="8"  placeholder="Your Message" onChange={this.onChange.bind(this)} value={this.state.message}></textarea>
		                          <span style={{color: "red"}}>{this.state.msgErr}</span>
		                      </div>
		                      <button type="button" className="btn btn-lg btn-success" onClick={this.contactUs.bind(this)}>Send Message</button> <span><h2>{this.state.successMsg}</h2></span>
		              </div>

		            </div>

		            <div className="col-lg-6 col-md-6 col-sm-12 m-p-0">
		                <div className="contact-info">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.7883784376722!2d74.34694571463045!3d31.502500155177316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919056f3c6d34d1%3A0x897fafd52bf903b1!2sMiOS.pk!5e0!3m2!1sen!2s!4v1604571722311!5m2!1sen!2s"width="100%" height="460" frameBorder="0"  allowFullScreen=""></iframe>


		                    <div className="socials contact-socials">
		                    <p>We're continually working on our online store and are open to any suggestions. If you have any questions or proposals, please do not hesitate to contact us.</p>
		                        <ul>
		                          <li><a target="_blank" href="https://www.facebook.com/mios.pk"><i className="fa fa-facebook"></i></a></li>
		                          <li><a target="_blank" href="#"><i className="fa fa-google-plus"></i></a></li>
		                          <li><a target="_blank" href="#"><i className="fa fa-twitter"></i></a></li>
		                          <li><a target="_blank" href="#"><i className="fa fa-linkedin"></i></a></li>
		                         <li><a target="_blank" href="#"><i className="fa fa-pinterest pinterest"></i></a>
		                        </li></ul>
		                      </div>
		                </div>
		            </div>

		        </div>
		    </div>
		</div>
		<Footer />
	  </div>
	)
  }
}
export default ContactUs;
