import React, { Component } from 'react';
import Header  from "./Header/Header";
import Footer  from "./Footer/Footer";
import team1 from '../assets/images/team-img1.jpg';
import team2 from '../assets/images/team-img2.jpg';
import team3 from '../assets/images/team-img3.jpg';
import team4 from '../assets/images/team-img4.jpg';
import images from '../assets/images/19SS_Ecom.jpg';
import {Redirect} from 'react-router-dom';
 class AboutUs extends Component {

render() {

	return (
	  <div>
<Header />
	  	
		<div className="wrapper-about">
   <div className="row">
      <div className=" col-lg-12 our-about">
         <h1></h1>
      </div>
   </div>
</div>


<div className="wrapper-content">
   <div className="container">
      <div className="row">
         <div className="col-lg-8 about-content-p">
            <p>
              Mios.pk is the most reliable online shopping store with variant products. You can purchase items from Mios.pk at much discounted price, it has taken the ecommerce era by storm. As you know nowadays it’s very tough for buyers to choose reliable online shopping platform because of sudden increase of scammers in the market. We take client’s feedback seriously that is the only reason we come up with best online shopping platform for our clients.
With few clicks you can order anything and anytime with ease. We aim to change the perception and divert the clients towards the rising online shopping trend across the country. Due to our exceptional service we have managed to develop a large customer base. We aim to improvise more in the future and is implementing more tactics in order to become the best online shopping platform across the country.
            </p>
         </div>
         <div className="col-lg-4 about-content-img">
            <img src={images} alt="" />
         </div>
      </div>
   </div>
</div>

      <div className="feature-wrapper">
         <div className="container">
            <div className="row">
               <div className="col-lg-12 feature-h3">
                  <h3>We Give Our Best</h3>
               </div>
               <div className="col-sm-4 feature-icon">
                  <i className="fa fa-star"></i>
                  <h4>24/7 Customer Support</h4>
                  <p>We provide best customer support to our clients</p>
               </div>
            
            <div className="col-sm-4 feature-icon">
                  <i className="fa fa-reply"></i>
                  <h4>Quality Products</h4>
                  <p>Our products aren’t compromised on the quality</p>
               </div>

               <div className="col-sm-4 feature-icon">
                  <i className="fa fa-paper-plane"></i>
                  <h4>Product Prices</h4>
                  <p>Our Product prices are reasonable and affordable</p>
               </div>

            </div>
         </div>
      </div>
		<Footer />
	  </div>
	)
  }
}
export default AboutUs;