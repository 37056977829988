import React, { Component } from 'react';
import loading from '../assets/images/loading.gif';
import {NavLink} from 'react-router-dom';
import {PostData} from '../services/PostDataAddress.js';
import Header  from "./Header/Header";
import Footer  from "./Footer/Footer";
import {Redirect} from 'react-router-dom';
import {api_url} from '../Config.js';
import $ from 'jquery';
import UserSidebar from './UserSidebar';
class ShippingAddress extends Component {
constructor(props) {
super(props);
this.state = {
    is_default:'0',
    contact_name:'',
    country:'',
    cities:[],
    street:'',
    appartment:'',
    province:'',
    city:'',
    postal_code:'0',
    is_postal:'0',
    state_id:'',
    phone_code:'',
    phone_number:'',
    data:[],
    countries:[],
    states:[],
    address:[],
    editData:[],
    edit_id:'',
    redirectToReferrer: false,
    base_url:api_url.base_url,
    img_url:api_url.img_url,
    com_url:api_url.com_url,
    user_id:localStorage.getItem('userData'),
    loading:false,
};
    this.getState = this.getState.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.getAddresses = this.getAddresses.bind(this);
    this.editform = this.editform.bind(this);
    this.deleteForm = this.deleteForm.bind(this);
    this.getStateId = this.getStateId.bind(this);
    this.getStateIdagain = this.getStateIdagain.bind(this);
}
componentDidMount(){
   const user_id = this.state.user_id;
   const caturl = this.state.com_url+'moiz_api/getAllCountries?user_id='+user_id;
      this.setState({loading:true})
        fetch(caturl)
         .then(res => res.json())
         .then(
           (result) => {
             this.setState({
               countries : result.countries,
               // cities : result.cities,
               states : result.states,
               address : result.address,
               loading:false,
             });
           },
           (error) => {
             this.setState({ error });
             this.setState({loading:false})
           }
         )
         const settingUrl =this.state.com_url+'moiz_api/get_general_settings';
             fetch(settingUrl)
              .then(res => res.json())
              .then(
                (result) => {
                  this.setState({
                   country: result.country,
                   states:result.state,
                  })
                },
                (error) => {
                  this.setState({ error });
                }
      )
         


   $(".edit_form").click(function(){
    $(".form-wrapper-set").slideToggle()
});
}

componentWillMount() {
if(localStorage.getItem("userData")){
this.getUserData();
}
else{
this.setState({redirectToReferrer: true});
}
}
getUserData() {
JSON.parse(localStorage.getItem("userData"));

}
getAddresses(){
   
}
onChange(e)
{
  let isChecked = e.target.checked;
  let isPostsl = e.target.checked;
  if(isChecked){
    this.setState({is_default:'1'})
  }else{
    this.setState({is_default:'0'})
  }

  if(isPostsl){
    this.setState({is_postal:'1'})
  }else{
    this.setState({is_postal:'0'})
  }
   this.setState({
      [e.target.name]:[e.target.value]
   })
}

editform(e)
{

   const editForm = this.state.com_url+`moiz_api/getFormData?edit_id=${e}`;
      this.setState({loading:true})
        fetch(editForm)
         .then(res => res.json())
         .then(
           (result) => {
             this.setState({
               editData:result,
               edit_id:result.id,
               is_default:result.is_default,
               is_postal:result.is_postal,
               loading:false,
             },()=>{
                this.getStateIdagain(result.province);
             });
             $(".form-wrapper-set").slideToggle()
             
             
           },
           (error) => {
             this.setState({ error });
           }
         )
}
deleteForm(e)
{
  const user_id = this.state.user_id;
   const delform = this.state.com_url+`moiz_api/deleteFormData?del_id=${e}&user_id=${user_id}`;
      this.setState({loading:true})
        fetch(delform)
         .then(res => res.json())
         .then(
           (result) => {
             this.setState({
               address:result,
               loading:false,
             });
             console.log(this.state.address)
             
           },
           (error) => {
             this.setState({ error });
           }
         )
}

showForm(){
  $(".form-wrapper-set").slideToggle();
  this.setState({editData:'',edit_id:''})
}
getStateId=(e)=>
{
  this.setState({province:e.target.value});
   const getCity = this.state.com_url+'moiz_api/getCity/'+e.target.value;
    fetch(getCity)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            cities: result
          })
        },
        (error) => {
          this.setState({ error });
        }
      )
}
getStateIdagain=(state)=>
{ 
   const getCity = this.state.com_url+'moiz_api/getCity/'+state;
    fetch(getCity)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            cities: result
          })
        },
        (error) => {
          this.setState({ error });
        }
      )
}
saveForm(e){
  
   console.log(this.state)
 var contact_name = $('#contact_name').val();
  var city = $('#city').val();
  var phone_number = $('#phone_number').val();
  var postal_code = $('#postal_code').val();
  var appartment = $('#appartment').val();
  var street = $('#street').val();
  var city = $('#city').val();
  var country = $('#country').val();
  var province = $('#province').val();
  var phone_code = $('#phone_code').val();
    this.setState({
      contact_name:contact_name,
      city:city,
      phone_number:phone_number,
      postal_code:postal_code,
      appartment:appartment,
      street:street,
      town:city,
      country:country,
      province:province,
      phone_code:phone_code,
    },function(){
       
            this.setState({
                firstnameErr:'',
                lastnameErr:'',
                emailErr:'',
                cityErr:'',
                addressErr:'',
                phoneErr:'',
                streetErr:'',
                countryErr:'',
            })
            PostData(this.state).then((result) => {
              this.setState({
                loading:true,
              })
              let responseJson = result;
                if(responseJson)
                {
                  this.setState({address:responseJson, loading:false})
                  $(".form-wrapper-set").slideToggle()
                }
                else
                {
                  $(".form-wrapper-set").slideToggle()
                }
            });
            
        
    })
}

getState(e)
{
   console.log('hello')
}
render() {

      return (
         <div>
         <Header />
            <div className="user_profile_wrap">
               <div className="container">
                  <div className="row">
                     <div className="col-sm-2 user_profile_left">
                        <h3>Orders</h3>
                        <UserSidebar active="shipping" />
                     </div>
                     
                     <div className="col-sm-10 accont_right_layout">
                        <h3>My Shipping Address</h3>
                        <div className="add_new_address">
                           <button type="button" onClick={this.showForm.bind(this)} className="add_form">Add a new address</button>
                        </div>
                        <div className="row" >
                           {(this.state.loading)?

                              
                              <div style={{margin:'auto'}}>
                              <img src={loading} /></div>
                              
                              :

                              (this.state.address)?
                              this.state.address.map((ad,ind)=>{
                                 return(

                                        <div className={(ad.is_default=='1')? 'col-sm-4 location_box location_box_active':'col-sm-4 location_box'} key={ind}>
                                          <ul>
                                             <li>
                                                <i className="fa fa-user"></i>
                                                {ad.contact_name}
                                             </li>
                           <li>
                              <i className="fa fa-map-marker"></i>
                               {ad.street}, {ad.appartment} {ad.city}, {ad.province}, {ad.country}
                                
                           </li>
                           <li>
                              <i className="fa fa-phone"></i>
                              {ad.phone_code} {ad.phone_number}
                           </li>
                                          <a className="edit_form" onClick={()=>this.editform(ad.id)} >Edit</a> <span>|</span> <a style={{cursor:'pointer'}} onClick={()=>this.deleteForm(ad.id)}>Delete</a>
                                          </ul>
                                       </div>


                                        )
                              })
                              :
                              ''
                           }
                        </div>




   <div className="boxshadow_box">
      <div className="form-wrapper-set">
         <div className="row">
            <div className="col-lg-4 form-input-d padiing-top">
               <div className="form-ch-data">
                  <label htmlFor="">Contact Name</label>
                  <input type="text" id="contact_name" name='contact_name' defaultValue={(this.state.editData!=null)? this.state.editData.contact_name : ''} placeholder="Contact Name" onChange={(e)=>this.setState({contact_name:e.target.value})} /> 
               </div>
            </div>
            <div className="col-lg-4 form-input-d padiing-top">
                  <div className="form-ch-data">
                     <label htmlFor="">Country/Region</label>
                     <select id="country">
                     {(this.state.loading)?
                        <option defaultValue='selected'>Loading</option> :

                        this.state.countries.map((country,index)=>{
                           return(<option selected={(country.id == 167) ? 'selected' : ''} key={index}  value={country.name} name='country'  id="country" onChange={(e)=>this.setState({country:e.target.value})}>{country.name}</option>)
                        })

                       
                     }
                        
                     </select>
                  </div>
               </div>

            <div className="col-lg-4 form-input-d padiing-top">
               <div className="form-ch-data">
                  <label htmlFor="">Street Address:</label>
                  <input type="text" id="street" defaultValue={(this.state.editData)? this.state.editData.street : ''} name='street' placeholder="Street Address"  onChange={(e)=>this.setState({street:e.target.value})} /> 
               </div>
            </div>

            <div className="col-lg-4 form-input-d padiing-top">
               <div className="form-ch-data">
                  <label htmlFor="">Apartment</label>
                  <input type="text" id="appartment"  defaultValue={(this.state.editData)? this.state.editData.appartment : ''} name='appartment' placeholder="Apartment, suite, unit etc. (optional)"  onChange={(e)=>this.setState({appartment:e.target.value})} /> 
               </div>
            </div>
            
            <div className="col-lg-4 form-input-d padiing-top">
                  <div className="form-ch-data">
                     <label htmlFor="">State/Province/Region:</label>
                     <select id="province" onChange={e=>this.getStateId(e)}>
                     <option value="">Select State</option>
                     {(this.state.loading)?
                        <option selected='selected'>Loading</option> :

                        this.state.states.map((country,index)=>{
                           return(<option key={index} value={country.id} selected={country.id===this.state.editData.province} name='province' id="province" >{country.name}</option>)
                        })

                       
                     }
                        
                     </select>
            </div>
         </div>

         <div className="col-lg-4 form-input-d padiing-top">
               <div className="form-ch-data">
                  <label htmlFor="">City/Department:</label>
                  
                  <select id="city" className="form-control" name="city" onChange={(e)=>this.setState({city:e.target.value})}>
                      <option defaultValue="">Select a city</option>
                      {this.state.cities.length > 0 && this.state.cities.map((element,index)=>{
                        return(
                            <option value={element.id} key={index} selected={element.id===this.state.editData.city}>{element.name}</option>
                            )
                      })}
                  </select>
               </div>
            </div>

            <div className="col-lg-4 form-input-d padiing-top" style={{display:'none'}}>
               <div className="form-ch-data">
                  <label htmlFor="">Zip/Postal Code:</label>
                  <input type="text" id="postal_code" defaultValue="0" name='postal_code' placeholder="Zip/Postal Code" onChange={(e)=>this.setState({postal_code:e.target.value})} /> 

                  <div className="custom-control custom-checkbox padd-check">
                   <input type="checkbox" value={this.state.is_postal} onClick={this.onChange} defaultChecked={(this.state.is_postal == '1') ? 'checked' : ''} className=" custom-control-input" id="customCheck" name="example1" />
                   <label className="custom-control-label news-top" htmlFor="customCheck"> My address does not have a ZIP code.</label>
                 </div>

               </div>
            </div>

            <div className="col-lg-6 form-input-d padiing-top">
               <div className="form-ch-data" >
                  <label htmlFor="">Phone:</label>
                  <select name="" id="phone_code" name='phone_code' style={{width: '18%'}} onChange={(e)=>this.setState({phone_code:e.target.value})}>
                     <option defaultValue="+1">+1</option>
                     <option defaultValue="+2">+2</option>
                     <option selected defaultValue="+92">+92</option>
                     <option defaultValue="+23">+23</option>
                     <option defaultValue="+91">+91</option>
                     <option defaultValue="+32">+32</option>

                  </select>
                  <input type="text" id="phone_number" defaultValue={(this.state.editData)? this.state.editData.phone_number : ''} placeholder="Phone Number" name='phone_number' style={{width: '51%'}}  onChange={(e)=>this.setState({phone_number:e.target.value})} /> 
                  <label htmlFor="" className="news-top">Country Code - Mobile Number</label>
               </div>
            </div>

            <div className="col-lg-6 form-input-d padiing-top">
               <div className="custom-control custom-checkbox padd-check">
                   <input type="checkbox"  defaultValue={this.state.is_default} defaultChecked={(this.state.is_default =='1') ? 'checked' : ''} onClick={this.onChange} className="custom-control-input" id="customCheck12" name="example1" />
                   <label className="custom-control-label news-top" htmlFor="customCheck12">  Set as default</label>
                 </div>
               <button onClick={this.saveForm}>Save</button>
            </div>
         </div>
      </div>
   </div>


                     </div>
                  </div>
               </div>
            </div>
            <Footer />
         </div>
      );
   }
}

export default ShippingAddress;