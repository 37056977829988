import {api_url} from '../../Config.js';
export function PostData(userData) {
	let BaseURL = api_url.base_url+'products_api/login_user';
		return new Promise((resolve, reject) =>{
		fetch(BaseURL,
		{

			method: 'POST',
			headers:
			{
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body:JSON.stringify(userData)
		})
		.then((response) => response.json()
		.then((res) => {
			resolve(res);
		}))
		.catch((error) => {
			reject(error);
		});
	});
}