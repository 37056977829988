import React, { Component } from 'react';
import dealimg from '../assets/images/deal-103.jpg';
import loading from '../assets/images/loading.gif';
import {NavLink} from 'react-router-dom';
import Header  from "./Header/Header";
import Footer  from "./Footer/Footer";
import {Redirect} from 'react-router-dom';
import {api_url} from '../Config.js';
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import UserSidebar from './UserSidebar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class Order extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allorder:[],
	        data: [],
			redirectToReferrer: false,
			base_url:api_url.base_url,
	  		img_url:api_url.img_url,
	  		com_url:api_url.com_url,
	  		current_user:localStorage.getItem('userData'),
	  		loading:false,
	  		success_message:(localStorage.getItem('success')) ? localStorage.getItem('success'):'',
	  		type:'all',
	  		start_date:'',
	  		end_date:'',
	  		total_profit:0,
	  		paid_status:'',
		};
		this.startDateHandler = this.startDateHandler.bind(this);
		this.endDateHandler = this.endDateHandler.bind(this);
		this.getCustomerProfit = this.getCustomerProfit.bind(this);
	}
	componentDidMount(){ 
		const date = new Date();
	    var d = date.getDate();
	    var m = date.getMonth();
	    var y = date.getFullYear();
	    var month = m+parseFloat(1);
	    var formatedDate = y+'-'+month+'-'+d;
	    const start_date = formatedDate;
	    const end_date = formatedDate; 
	    this.setState({
			start_date:start_date,
			end_date:end_date
		});
		if(localStorage.getItem('success'))
		{
			localStorage.removeItem('success');
		}
		this.getCustomerProfit();
	}

	componentWillMount() {
	if(localStorage.getItem("userData")){
			this.getUserData(this.state.type);
		}
		else
		{
			this.setState({redirectToReferrer: true});
		}
	}
	changeType(type){ 
		this.setState({type:type},()=>{

			this.receivedData(this.state.type);
		});
	}
	getUserData() {
		JSON.parse(localStorage.getItem("userData"));
	}
 getCustomerProfit(type=null){
	let sendData = {
		start:this.state.start_date,
		end:this.state.end_date,
		is_paid:this.state.paid_status
	};
	const caturl = this.state.com_url+'moiz_api/getProfitReport/'+this.state.current_user;
    this.setState({loading:true})
	fetch(caturl,
           {
            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(sendData)
        })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
          	data:result.data.allData,
          	loading:false,
          	total_profit: result.data.totalProfit
          }); 
        },
        (error) => {
          this.setState({ loading:false });
        }
      )
}

startDateHandler = (date) =>{
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    var month = m+parseFloat(1);
    var formatedDate = y+'-'+month+'-'+d;
    this.setState({start_date:formatedDate})
  }
  endDateHandler = (date) =>{
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    var month = m+parseFloat(1);
    var formatedDate = y+'-'+month+'-'+d;
    this.setState({end_date:formatedDate})
  }
searchComissionHandler()
{
	let sendData = {
		start_date:this.state.start_date,
		end_date:this.state.end_date
	};
	console.log(sendData);
}

render() {

	return (
			<div>
			<Header />
			   <div className="user_profile_wrap">
			      <div className="container">
			      	<div className="row">
			      		{(
			      			this.state.success_message!='' ?
				      		<div className="col-sm-12">
				      			<div className="alert alert-success">
				      				{this.state.success_message}
				      			</div>
				      		</div>
				      		:""
			      		)}
			      	</div>
			         <div className="row">
			            <div className="col-sm-2 user_profile_left">
			               <h3 >Orders</h3>
			               <UserSidebar active="profit" />
			            </div>
			            <div className="col-sm-10 accont_right_layout">
			            	<div className="row">
			            		<div className="col-sm-2 sidegapp order_dropbox">
					               <h3 >User Profit</h3>
			            		</div>
			            		 
			            	
			            	</div>


			                
			               <div className="form-wrapper-trad">
			                  <div className="row">
			                     <div className="col-lg-12 padding-set-left">


			                        	{(this.state.loading) ?
						                  <div style={{textAlign:'center'}}>
										  <img src={loading} /></div>
										:
											<div className="table-responsive seller_comission_box">
						                  		<DatePicker
				                               		value = {this.state.start_date}
				                                	onChange={(e)=>this.startDateHandler(e)}
				                              	/>
				                              	<DatePicker
				                               		value = {this.state.end_date}
				                                	onChange={(e)=>this.endDateHandler(e)}
				                              	/> 
				                              	<select onChange={(e)=>this.setState({'paid_status':e.target.value})}>
						                  			<option value="">Select Status</option>
						                  			<option selected={this.state.paid_status==='1'} value="1">Paid</option>
						                  			<option selected={this.state.paid_status==='2'} value="2">Unpaid</option>
						                  		</select>
						                  		<input type='submit' value="Search" onClick={this.getCustomerProfit} />
						                  		
						                  <div className="table-responsive">
						                  <table id="customers">

				                        <thead>
				                           <tr>
				                              <th rowSpan="1" colSpan="1">SrNo</th>
				                              <th rowSpan="1" colSpan="1">Invoice No</th>
				                              <th rowSpan="1" colSpan="1">Date</th>
				                              <th rowSpan="1" colSpan="1">Address</th>
				                              <th rowSpan="1" colSpan="1">City</th>
				                              <th rowSpan="1" colSpan="1">Sub Total</th>
				                              <th rowSpan="1" colSpan="1">Shipping Charges</th>
				                              <th rowSpan="1" colSpan="1">Total Amount</th>
				                              <th rowSpan="1" colSpan="1">Dropdship Amount</th>
				                              <th rowSpan="1" colSpan="1">Profit</th>
				                              <th rowSpan="1" colSpan="1">Status</th>
				                           </tr>
				                        </thead>
				                        <tbody>
				                        	{this.state.data.map((item,key) =>
							                    <tr className="fixed-table" key={key}>
							                  <td colSpan="1">
							                        <p>{key+parseFloat(1)}</p>
							                  </td>
							                  <td colSpan="1">
							                       <p>{item.order_id} </p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.created_on}</p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.address}</p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.city}</p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.price_subtotal}</p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.price_shipping}</p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.ordertotal}</p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.dropsiptotal}</p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.profit}</p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>
							                  	{
							                  		item.is_paid=='1'? 'Paid' : 'UnPaid'
							                  	}
							                  	</p>
							                  </td>

							                  
							               </tr>
							               )}

				                         </tbody>

				                         <tfoot>
				                         	<td colSpan="9" className="background-grey">
							                  	<p></p>
							                </td>

							                <td colSpan="1" className="background-grey">
							                  	<p>{this.state.total_profit}</p>
							                </td>
							                <td colSpan="1" className="background-grey">
							                </td>
				                         </tfoot>

			                        </table>
			                        </div>
				                         
						               </div>
			                        }
			                     </div>

			                  </div>
			               </div>
			            </div>
			         </div>
			      </div>
			   </div>
			<Footer />
		</div>
		);
	}
}

export default Order;
