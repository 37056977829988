import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { PostData } from './PostData';
import { NavLink } from 'react-router-dom';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';
class Login extends Component {
   constructor() {
      super();
      this.state = {
         email: '',
         password: '',
         redirectToReferrer: false,
         wrongErr: '',
         emailErr: '',
         passErr: '',
         loading: false,
         success_message: (localStorage.getItem('signupSucc')) ? localStorage.getItem('signupSucc') : '',
      };
      this.login = this.login.bind(this);
      this.onChange = this.onChange.bind(this);
      this.validation = this.validation.bind(this);
   }
   componentDidMount() { 
      // localStorage.clear();
      console.log(localStorage)
		if (localStorage.getItem('signupSucc')) {
			localStorage.removeItem('signupSucc');
		} 
	}
   login() {
      if (this.validation()) {
         this.setState({ emailErr: '', passErr: '', wrongErr: '', loading: true })
         PostData(this.state).then((result) => {
            let responseJson = result;
            if (responseJson.userData) {
               sessionStorage.setItem('userData', JSON.stringify(responseJson.userData));
               localStorage.setItem('userData', JSON.stringify(responseJson.userData));
               this.setState({ redirectToReferrer: true });
            }
            else
               this.setState({ wrongErr: result.error, loading: false }, function () {

               })
         });
      }
   }

   validation() {
      if (this.state.email === '' && this.state.password === '') {
         this.setState(
            {
               emailErr: 'Please enter your email',
               passErr: 'Please enter your password',
            }
         )
      } else if (this.state.email === '') {
         this.setState(
            {
               emailErr: 'Please enter your email',
               passErr: '',
            }
         )
      } else if (this.state.password === '') {
         this.setState(
            {
               emailErr: '',
               passErr: 'Please enter your password',
            }
         )
      } else {
         return true
      }
   }



   onChange(e) {
      this.setState({ [e.target.name]: e.target.value });
   }
   render() {
      const { loading } = this.state;
      if (this.state.redirectToReferrer) {
         if (localStorage.getItem('checkoutVali') === 1) {
            return (<Redirect to={'/check-out'} />)
         }
         else {
            return (<Redirect to={'/order'} />)
         }
      }
      return (
         <div>
            <Header />
            <div className="form_wrap">
               <div className="container">
                  <div className="row middle_form" id="sign_up_bg">
                     <div className="col-sm-8 sign_up_left">
                        <div className="social_media">
                           <h3>Sign In</h3>
                           {/*<p>Sign In with social media</p>
                        <ul>
                           <li><a href="#" target="_blank"><img src={twitter} alt="" /></a></li>
                           <li><a href="#" target="_blank"><img src={facebook} alt="" /></a></li>
                           <li><a href="#" target="_blank"><img src={googleplus} alt="" /></a></li>
                        </ul>
                        <span>Or with Email</span>*/}
                        </div>


                        <span style={{ color: "red" }}>{this.state.wrongErr}</span>
                        <div className="row">
                           {(
                              this.state.success_message !== '' ?
                                 <div className="col-sm-12">
                                    <div className="alert alert-success">
                                       {this.state.success_message}
                                    </div>
                                 </div>
                                 : ""
                           )}
                        </div>
                        <div>


                           <input type="email" name="email" placeholder="Email" onChange={this.onChange} />
                           <span style={{ color: "red" }}>{this.state.emailErr}</span>

                           <input type="password" name="password" placeholder="Password" onChange={this.onChange} />
                           <span style={{ color: "red" }}>{this.state.passErr}</span>

                           <div className="login_form">

                              <button
                                 className="button-loadings"
                                 style={{ fontSize: "18px" }}
                                 type="submit"
                                 onClick={this.login}
                                 disabled={loading}
                              >
                                 {loading && (
                                    <i
                                       className="fa fa-refresh fa-spin"
                                       style={{ marginRight: "5px" }}
                                    />
                                 )}
                                 {loading && <span>Loading...</span>}
                                 {!loading && <span>Login</span>}
                              </button>
                              <NavLink to="/forget-password">Forget Password?</NavLink>

                           </div>
                        </div>
                     </div>
                     <div className="col-sm-4 login_right_bg">
                        <div className="details_info">
                           <h3>Hello, Friend!</h3>
                           <p><span>Enter your personal Login </span> Detail and start journey with Us!</p>
                           <NavLink to="/signup">Sign Up</NavLink>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <Footer />
         </div>
      );
   }
}
export default Login;
