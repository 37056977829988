import React, { Component } from 'react';
import Annotation from '../assets/images/Annotation.png';
import loading from '../assets/images/loading.gif';
import { NavLink } from 'react-router-dom';
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Redirect } from 'react-router-dom';
import { api_url } from '../Config.js';
import UserSidebar from './UserSidebar';

class Mycoupon extends Component {
   constructor(props) {
      super(props);
      this.state = {
         data: [],
         coupons: [],
         redirectToReferrer: false,
         base_url: api_url.base_url,
         img_url: api_url.img_url,
         com_url: api_url.com_url,
         current_user: localStorage.getItem('userData'),
         loading: false,
      };
   }
   componentDidMount() {

      const caturl = this.state.com_url + 'moiz_api/getAllCoupons';
      this.setState({ loading: true })
      fetch(caturl)
         .then(res => res.json())
         .then(
            (result) => {
               this.setState({
                  coupons: result.coupons,
                  loading: false,
               });
               console.log(this.state.coupons)

            },
            (error) => {
               this.setState({ error });
               this.setState({ loading: false })
            }
         )
   }
   componentWillMount() {
      if (localStorage.getItem("userData")) {
         this.getUserData();
      }
      else {
         this.setState({ redirectToReferrer: true });
      }
   }
   getUserData() {
      JSON.parse(localStorage.getItem("userData"));

   }
   render() {
      const { coupons } = this.state;
      const todayDate = new Date();
      const date = todayDate.getYear() + '-' + (todayDate.getMonth() + parseFloat(1)) + '-' + todayDate.getDate();
      console.log(date)
      return (
         <div>
            <Header />
            <div className="user_profile_wrap">
               <div className="container">
                  <div className="row">
                     <div className="col-sm-2 user_profile_left">
                        <h3>Orders</h3>
                        <UserSidebar active="coupon" />
                     </div>
                     <div className="col-sm-10 accont_right_layout">
                        <h3>My Coupons</h3>
                        <div className="tab-wrapper">
                           <div className="row">
                              <div className="col-lg-12 tab-section-" style={{ 'paddingTop': '0' }}>

                                 <div className="main_tab">
                                    <div className="tabs_wrap" id="home" style={{ display: 'block' }}>
                                       <ul className="payment_info">
                                          <li><a href="#" className="active">All Coupons 0 </a></li>
                                          <li><a href="#">Valid Now 0 </a></li>
                                          <li><a href="#">Not Valid Yet 0 </a></li>
                                          <li><a href="#">Used 0 </a></li>
                                          <li><a href="#">Expired 0</a></li>
                                       </ul>
                                       {(this.state.loading) ?
                                          <div style={{ textAlign: 'center' }}>
                                             <img src={loading} /></div> :

                                          (coupons.length > 0) ?
                                             coupons.map((coupon, index) => (
                                                <div className="top-list-items-" key={index}>
                                                   <div className="row">
                                                      <div className="col-lg-4 lleft-img">
                                                         <div className="coupon_bg">
                                                            <span className="starting_date"> <p>Expire On:  {coupon.expiary_date}</p></span>
                                                            <span className="expriy_date"> <p>{coupon.value} {coupon.promotion_type == "1" ? "%" : ''} OFF</p></span>
                                                            <span className="coupon_code"> <p>{coupon.promotion_code}</p></span>
                                                         </div>
                                                      </div>

                                                      <div className="col-lg-8">
                                                         <div className="list-span-center">
                                                            <span><b>Validity period</b><h6>:</h6><p>{coupon.starting_date} - {coupon.expiary_date}</p></span>
                                                            {/* <span><b>Maximum users</b><h6>:</h6><p>{coupon.max_users}</p></span> */}
                                                            <span><b>Coupon Name</b><h6>:</h6><p>{coupon.promotion_code}</p></span>
                                                            <span><b>Restrictions</b><h6>:</h6><p>{(coupon.apply_once == 1) ? 'Can be used once' : 'No restrictions'}</p></span>
                                                            <span><b>Coupon status</b><h6>:</h6><p>{coupon.status}</p></span>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>

                                             ))
                                             :
                                             <h4>No record found</h4>



                                       }





                                    </div>


                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="form-wrapper-trad">
                           <div className="row">
                              <div className="col-lg-12 padding-set-left">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <Footer />
         </div>
      );
   }
}

export default Mycoupon;

