import React, { Component } from 'react';
import Header  from "./Header/Header";
import Footer  from "./Footer/Footer";
import $ from 'jquery';
import {NavLink} from 'react-router-dom';
import {api_url} from '../Config.js';
import styled from 'styled-components';
const Container = styled.div`
  background-color:#444;
  color:white;
  padding:16px;
  position:fixed;
  top:${props=> props.top}px;
  right:16px;
  z-index:999;
  transition: top 0.9s ease;
`;

class Brands extends Component {
  constructor(props) {
    super(props);
    this.state={
         error: null,
      products: [],
      backupproducts: [],
      cartProducts:[],
      brands:[],
      itemShow:10,
      top:-8000,
      categories:[],
      productsFilters:[],
       filter:[],
      totalItems:"",
      loading:false,
      addtocart:'',
      itemquantity:1,
      items: JSON.parse(localStorage.getItem('session')),
      cartCount:localStorage.getItem('cartCount'),
      filterId:localStorage.getItem('filterId'), 
      base_url:api_url.base_url,
      img_url:api_url.img_url,
      filterData:[],
      fillterCategory:'',
      filterOption:'',
      changeCategory:"",



    }
    this.loadmore = this.loadmore.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.handleChanged = this.handleChanged.bind(this);

  }

 componentDidMount() {

   const caturl = this.state.base_url+'products_api/GetCategories';

    fetch(caturl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            categories: result

          });
        },
        (error) => {
          this.setState({ error });
        }
      )
     
  
const allCat = this.state.base_url+'products_api/AllCategories';

    fetch(allCat)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            brands: result
          });
          console.log(this.state.brands)
        },
        (error) => {
          this.setState({ error });
        }
      )



    
    const apiUrl = this.state.base_url+'products_api/getProducts';

    fetch(apiUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            products: result,
            backupproducts:result,
          });
          console.log(this.state.products)
        },
        (error) => {
          this.setState({ error });
        }
      )




    $('ul.tabs li').click(function(){
    var tab_id = $(this).attr('data-tab');

    $('ul.tabs li').removeClass('current');
    $('.tab-content').removeClass('current');

    $(this).addClass('current');
    $("#"+tab_id).addClass('current');
  })
     $(".tab-section- .tabs a").on("click",function(e){
  $(this.hash).slideDown().siblings().slideUp();
    e.preventDefault();
  }).first().click();
   
   // for active class
    $(".tab-section- .tabs a").on("click",function(){
   $(".tabs a.active").removeClass("active");
   $(this).addClass("active");
});

    $('ul.tabs_sort li').click(function(){
    var tab_id = $(this).attr('data-tab');
    $('ul.tabs_sort li').removeClass('active');
    $('.tab-content-sort').removeClass('active');
    $(this).addClass('active');
    $("#"+tab_id).addClass('active');
  });



$(".collapsed").click(function(){
    $(".collapse_info").slideToggle();
});


$(".cata-sub-slider").on('scroll', function() {
      var val = $(this).scrollLeft();
      if($(this).scrollLeft() + $(this).innerWidth()>=$(this)[0].scrollWidth){
          $(".cata-sub-slider .nav-next-").hide();
        } else {
        $(".cata-sub-slider .nav-next-").show();
      }
      if(val == 0){
        $(".cata-sub-slider .nav-prev-").hide();
      } else {
        $(".cata-sub-slider .nav-prev-").show();
      }
    });
  console.log( 'init-scroll: ' + $(".cata-sub-slider .nav-next-").scrollLeft() );
  $(".cata-sub-slider .nav-next-").on("click", function(){
    $(".cata-sub-slider").animate( { scrollLeft: '+=460' }, 200);
    
  });
  $(".cata-sub-slider .nav-prev-").on("click", function(){
    $(".cata-sub-slider").animate( { scrollLeft: '-=460' }, 200);
  });


  $(".cata-sub-nav").on('scroll', function() {
       var val = $(this).scrollLeft();

      if($(this).scrollLeft() + $(this).innerWidth()>=$(this)[0].scrollWidth){
          $(".nav-next").hide();
        } else {
        $(".nav-next").show();
      }

      if(val == 0){
        $(".nav-prev").hide();
      } else {
        $(".nav-prev").show();
      }
    });
  console.log( 'init-scroll: ' + $(".nav-next").scrollLeft() );
  $(".nav-next").on("click", function(){
    $(".cata-sub-nav").animate( { scrollLeft: '+=460' }, 200);
    
  });
  $(".nav-prev").on("click", function(){
    $(".cata-sub-nav").animate( { scrollLeft: '-=460' }, 200);
  });
  }

  loadmore(){
  this.setState((old)=>{
    return {itemShow:old.itemShow + 10}
  })
}
addToCart(e){
  this.setState((old)=>{
    return {cartCount:old.cartCount + 1}
   } )
// localStorage.clear();
this.setState({
      top:5,
    },function(){
      setTimeout(()=>{
        this.setState({
          top: -800,
        })
      },1500)
    })

this.setState({addtocart:"Product Added to Cart!"})
var count = this.state.cartCount;
console.log(this.state.cartCount)
count = parseInt(this.state.cartCount)+parseInt(1);
this.setState({cartCount:count},function(){})

localStorage.setItem('cartCount',count);
var newproducts = JSON.parse(localStorage.getItem('session'))
var products =  this.state.products[e.target.name] ;
var proid=products.id



 products['itemQty']='1';
        a = JSON.parse(localStorage.getItem('session')) || [];
        a.push(products);
        localStorage.setItem('session', JSON.stringify(a));






for(var i= 0 ; i<newproducts.length ; i++)
{
var newid = newproducts.map((key,index)=>(key.id))
var idd = newproducts[i]
  if(products.id == idd.id)
  {

const updateditems = newproducts.filter(item=>item.id !==idd.id);

localStorage.setItem('session', JSON.stringify(updateditems));

    
    var changeproduct = newproducts[i]
   
    var qty = changeproduct.itemQty
  
   products['itemQty']=parseInt(changeproduct.itemQty)+ parseInt(1);
   var a = [];
    a = JSON.parse(localStorage.getItem('session')) || [];
    var length = a.length;
    a.push(products);
    localStorage.setItem('session', JSON.stringify(a));
    
  }
}
 
}
changeCategory(e){
  this.setState({changeCategory:e.target.value},function(){
    localStorage.setItem('categoryName',this.state.changeCategory)

  })
}
handleChanged(e)
  {
    var filterId = e.target.value
    this.setState({idtoFilter:e.target.value},function(){
        this.setState({idtoFilter:filterId})
    })
    
let isChecked = e.target.checked;
if(isChecked == true){

const filterurl = `https://demo.a.eloerp.net/accounting/products_api/getFilteredProducts?filterId=${filterId}`;
fetch(filterurl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            productsFilters: result
          });
        var filter = this.state.filter
            this.state.productsFilters.map((product, index)=>(
              console.log(product),
               filter.push(product),
               this.setState({products:filter},function(){}),
               console.log(this.state.products)
              ))
        },
        (error) => {
          this.setState({ error });
        }
      )

}else{
  
const filterurl = `https://demo.a.eloerp.net/accounting/products_api/getFilteredProducts?filterId=${filterId}`;
fetch(filterurl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            unfilter: result
          });
          for(var i= 0 ; i<this.state.products.length ; i++)
          {
           
            console.log(i)
            var unfilterresult = this.state.unfilter[0]
           

          var newid = this.state.products.map((key,index)=>(key.id))
          var idd = this.state.products[i]
          
            if(this.state.unfilter[0].id == idd.id)
            {

          const updateditems = this.state.products.filter(item=>item.id !==idd.id);

          this.setState({products:updateditems})
              
            }
          }
            
        },
        (error) => {
          this.setState({ error });
        }
      )
if(this.state.products.length == 1)
{

  this.setState({products:this.state.backupproducts,function(){
    this.setState({productsFilters:[]})
  }})
  this.setState({productsFilters:[]})
  console.log(this.state.productsFilters)
}

}
}
render() {
    const { error, products, categories} = this.state;
		return (
			<div>
      <Header />
		<Container top={this.state.top}>{this.state.addtocart}</Container>		

<div className="banner-container my-banner">
  <div id="banner-wrapper" className="products-category">
     <div className="container">
       <div className="deals_box">
          <p>CHECK OUR OVER <span>200+</span></p>
          <h4>INCREDIBLE DEALS</h4>
          <a href="#">SHOP NOW</a>
       </div>
     </div>
  </div>
</div>
<div className="tabs_blog search_result_shop">

<div className="container" >

   
      <div className="row ">
<div className="col-sm-2 category_filter">
            <h3>SORT BY</h3>
            <ul className="tabs_sort">

              <li className="tab-link active" data-tab="tab-32">Brands</li>
            </ul>
          

            <div id="tab-32" className="tab-content-sort active">
            {this.state.brands.map(product => (
             <p className="checkbox" key={product.id}>
             <label>
               <input type="checkbox" name="idtoFilter" value={product.id} onChange={e => this.handleChanged(e)} />
                <span className="lbl padding-8">{product.brandName}</span>
               </label> 
             </p>
             ))}
            </div>
          </div>       







      <div className="col-sm-10 new_arrivals">
            <section className="best_deal">
                
                <div className="shadow_box">
                  <div className="row">
                  {products.slice(0,this.state.itemShow).map((product,index) => (
                    <div className="col-sm-3 side_gaps" key={product.product_id} >

                      <div className="inner_box_sec">
                      <NavLink key={product.product_id} to={`/product-detail/${product.product_id}`}>
                        <img src={this.state.img_url+`/${product.image_name}`} />
                          
                        <div className="discount">
                          
                        </div>
                        </NavLink>
                        <h4>{product.product_name}</h4>
                        <div className="stars-inner">
                          
                          <span className="rating_price"></span>
                          <p>₨.{product.saleRate}</p>
                          
                          <h3>₨.{product.purchaseRate} <i className="fa fa-heart-o"></i> <i className="hide-icon fa fa-heart"></i></h3>
                          <div className="cart_btn">
                          <a  onClick={this.addToCart} name={index} value={product.id}>Add to Cart</a></div>
                        </div>
                      </div>
                      <div className="_2N1Tif">
                        <div className="shopee-badge shopee-badge--fixed-width shopee-badge--promotion">
                          <div className="shopee-badge--promotion__label-wrapper shopee-badge--promotion__label-wrapper--en">
                            <span className="percent">93%</span><span className="shopee-badge--promotion__label-wrapper__off-label shopee-badge--promotion__label-wrapper__off-label--en">off</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    ))}
                  </div>
                  
                 
                  <div className="read_more_btn">
                  {this.state.itemShow < this.state.products.length && <button type='button'  className="loadmore_products" onClick={this.loadmore}>Load More</button>}
                    <button type="button">View All</button>
                  </div>
                </div>
            </section>
        </div>
</div>
</div>
</div>



<Footer />
			</div>
		);
	}
}

export default Brands;