import React, { Component } from 'react';
import $ from 'jquery';
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert';
import { api_url } from "../Config.js";
class Cart extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      redirectToReferrer: false,
      products: [],
      items: JSON.parse(localStorage.getItem('cartProducts')) ? JSON.parse(localStorage.getItem('cartProducts')) : [],
      dropshipItem: JSON.parse(localStorage.getItem('cartProducts')),
      totalItems: localStorage.getItem('itemQty'),
      cartCount: localStorage.getItem('cartCount'),
      subtotal: 0,
      user_id: localStorage.getItem('userData'),
      grandtotal: 0,
      prefix: '',
      suffix: "",
      base_url: api_url.base_url,
      img_url: api_url.img_url,
      com_url: api_url.com_url,
      changeItemQty: 0,
      subtotalNew: 0,
      customer_type: 0,
      dropbox: false,
      dropshipsubtotal: 0,
      is_dropsip: 0
    };
    this.removeSelectedProduct = this.removeSelectedProduct.bind(this);
    this.removeSelectedProductDropship = this.removeSelectedProductDropship.bind(this);
    // this.plusValue = this.plusValue.bind(this);
    // this.minusValue = this.minusValue.bind(this);
    // this.plusQty = this.plusQty.bind(this);
    // this.newValue = this.newValue.bind(this);
    this.calculateCaretTotal = this.calculateCaretTotal.bind(this);
    // this.checkUserType = this.checkUserType.bind(this);
    // console.log(this.state.items);
  }
  // checkUserType(user_id)
  // {
  //   const  userUrl = this.state.com_url+'moiz_api/checkUserType/'+user_id;
  //   fetch(userUrl)
  //   .then(res => res.json())
  //       .then(
  //         (result) => {
  //           this.setState({
  //             customer_type: result.customer_detail.customer_type,
  //           }, function(){
  //           });
  //         },
  //         (error) => {
  //           this.setState({ error });
  //         }
  //       )
  // }
  async componentDidMount() {

    function sortDescending() {
      const { itmes } = this.state.items;
      itmes.sort((a, b) => a - b).reverse()
      this.setState({ itmes })
    }
    this._isMounted = true;
    {
      (this.state.items == null) ? this.setState({ subtotal: 0 }) : this.state.items.map(item => (
        this.setState((old) => {
          return {
            subtotal: old.subtotal + (item.calculation_rate) * (item.itemQty),
            dropshipsubtotal: old.subtotal + (item.calculation_rate) * (item.itemQty)
          }
        })
      ))
    }


    if (localStorage.getItem('userData')) {
      var user_id = localStorage.getItem('userData');
      const prefixUrl = this.state.com_url + 'moiz_api/getCustomerDetail/' + user_id;
      await fetch(prefixUrl)
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result)
            this.setState({
              customer_type: result.customer_detail.group_id,
            }, function () {
              if (this.state.customer_type === "3") {
                this.setState({ dropbox: true, is_dropsip: 1 }, function () {

                  localStorage.setItem('dropshipProduct', JSON.stringify(this.state.dropshipItem));
                  localStorage.setItem('isdropship', this.state.is_dropsip);
                });
              }
            });
          },
          (error) => {
            this.setState({ error });
          }
        )
    }

    const prefixUrl = this.state.base_url + 'products_api/GetPrefix';
    await fetch(prefixUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            prefix: result
          }, function () {
          });
        },
        (error) => {
          this.setState({ error });
        }
      )
    const suffixUrl = this.state.base_url + 'products_api/GetSuffix';
    fetch(suffixUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result
          }, function () {
          });
        },
        (error) => {
          this.setState({ error });
        }
      )


    // console.log(this.state.items);

  }

  componentWillMount() {
    if (localStorage.getItem("userData")) {
      this.getUserData();
    }
    else {
      this.setState({ redirectToReferrer: true });
    }
  }
  getUserData() {
    JSON.parse(localStorage.getItem("userData"));
  }
  removeSelectedProduct(e) {
    this.removeSelectedProductDropship(e);
    var target = e.target.id
    this.setState((old) => {
      return { cartCount: parseFloat(old.cartCount) - 1 }
    }, function () {
      localStorage.setItem('cartCount', this.state.cartCount);
    })

    const items = [...this.state.items];
    const updateditems = items.filter(item => item.id !== e.target.name);
    // localStorage.setItem('cartProducts', JSON.stringify(products));
    this.setState({ items: updateditems }, function () {
      localStorage.setItem('cartProducts', JSON.stringify(this.state.items));
      this.calculateCaretTotal();
    });

  }
  removeSelectedProductDropship(e) {

    var target = e.target.id
    this.setState((old) => {
      return { cartCount: parseFloat(old.cartCount) - 1 }
    }, function () {
      localStorage.setItem('cartCount', this.state.cartCount);
    })

    const items = [...this.state.dropshipItem];
    const updateditems = items.filter(item => item.id !== e.target.name);
    // localStorage.setItem('cartProducts', JSON.stringify(products));
    this.setState({ dropshipItem: updateditems }, function () {
      localStorage.setItem('dropshipProduct', JSON.stringify(this.state.dropshipItem));
      this.calculateDropshipTotal();
    });

  }

  plusQty = (id, unit_id) => {
    let items = this.state.items;
    items = items.map(item => {
      if (item.id == id && item.unit_id == unit_id) {
        item.itemQty = parseFloat(item.itemQty) + 1;
      }
      return item;
    });
    localStorage.setItem('cartProducts', JSON.stringify(this.state.items));
    localStorage.setItem('cartCount', this.state.items.length);
    this.setState({ items: items });
    this.calculateCaretTotal();

    let Dropitems = this.state.dropshipItem;
    Dropitems = Dropitems.map(item => {
      if (item.id == id && item.unit_id == unit_id) {
        item.itemQty = parseFloat(item.itemQty) + 1;
      }
      return item;
    });
    localStorage.setItem('dropshipProduct', JSON.stringify(this.state.dropshipItem));
    localStorage.setItem('cartCount', this.state.items.length);
    this.setState({ dropshipItem: Dropitems });
    this.calculateDropshipTotal();
  }
  minusQty = (id, unit_id) => {
    let items = this.state.items;
    items = items.map(item => {
      if (item.id == id && item.unit_id == unit_id && item.itemQty > 1) {
        item.itemQty = parseFloat(item.itemQty) - 1;
      }
      return item;
    });
    localStorage.setItem('cartProducts', JSON.stringify(this.state.items));
    localStorage.setItem('cartCount', this.state.items.length);
    this.setState({ items: items });
    this.calculateCaretTotal();

    let Dropitems2 = this.state.dropshipItem;
    Dropitems2 = Dropitems2.map(item => {
      if (item.id == id && item.unit_id == unit_id && item.itemQty > 1) {
        item.itemQty = parseFloat(item.itemQty) - 1;
      }
      return item;
    });
    localStorage.setItem('dropshipProduct', JSON.stringify(this.state.dropshipItem));
    localStorage.setItem('cartCount', this.state.items.length);
    this.setState({ dropshipItem: Dropitems2 });
    this.calculateDropshipTotal();
  }
  newValue = (e, id, unit_id) => {
    let items = this.state.items;
    items = items.map(item => {
      if (item.id == id && item.unit_id == unit_id) {
        item.itemQty = e.target.value;
      }
      return item;
    });
    localStorage.setItem('cartProducts', JSON.stringify(this.state.items));
    localStorage.setItem('cartCount', this.state.items.length);
    this.setState({ items: items });
    this.calculateCaretTotal();
    let dropItems = this.state.dropshipItem;
    dropItems = dropItems.map(item => {
      if (item.id == id && item.unit_id == unit_id) {
        item.itemQty = e.target.value;
      }
      return item;
    });
    localStorage.setItem('dropshipProduct', JSON.stringify(this.state.dropshipItem));
    localStorage.setItem('cartCount', this.state.items.length);
    this.setState({ dropshipItem: dropItems });
    this.calculateDropshipTotal();
  }

  // plusQtyDropship = (id,unit_id) => {

  // }
  // minusQtyDropship = (id,unit_id) => {

  // }
  // newValueDropship=(e,id,unit_id)=>{

  // }
  setItemQty(e) {
    localStorage.setItem('cartqty', e.target.className)
  }
  calculateCaretTotal() {
    var cartTotal = 0;
    for (var i = 0; i < this.state.items.length; i++) {
      var calculation_rate = this.state.items.map(item => (item.calculation_rate));
      var qty = this.state.items.map(item => (item.itemQty));
      cartTotal += parseFloat(calculation_rate[i] * qty[i]);
    }
    this.setState({ subtotal: cartTotal });
    // (this.state.items ==null) ? this.setState({subtotal:0}) : this.state.items.map(item=>(
    //   this.setState((old)=>{
    //     return {
    //             subtotal:+ (item.saleRate)*(item.itemQty)
    //           }
    //     })
    // ))
  }

  calculateDropshipTotal() {
    var cartTotal = 0;
    for (var i = 0; i < this.state.dropshipItem.length; i++) {
      var calculation_rate = this.state.dropshipItem.map(item => (item.calculation_rate));
      var qty = this.state.dropshipItem.map(item => (item.itemQty));
      cartTotal += parseFloat(calculation_rate[i] * qty[i]);
    }
    this.setState({ dropshipsubtotal: cartTotal });
  }

  checkDropShip(e) {
    let isChecked = e.target.checked;
    if (isChecked) {
      this.setState({ dropbox: true, is_dropsip: 1 }, function () {

        localStorage.setItem('dropshipProduct', JSON.stringify(this.state.dropshipItem))
        localStorage.setItem('isdropship', this.state.is_dropsip)

      })

    } else {
      this.setState({ dropbox: false, is_dropsip: 0 }, function () {
        localStorage.removeItem('dropshipProduct')
        localStorage.setItem('isdropship', this.state.is_dropsip)
      })
    }
  }
  changeunitPrice = (e, id, unit_id, qty) => {
    console.log(e.target.value)
    let items = this.state.dropshipItem;
    console.log(items)
    items = items.map(item => {
      if (item.id == id && item.unit_id == unit_id) {
        item.calculation_rate = e.target.value;
      }
      return item;
    });
    localStorage.setItem('dropshipProduct', JSON.stringify(this.state.dropshipItem));
    localStorage.setItem('cartCount', this.state.items.length);
    this.setState({ dropshipItem: items });
    this.calculateDropshipTotal();
  }
  render() {
    var pro = localStorage.getItem('cartProducts');
    var data = JSON.parse(pro);
    return (
      <div>
        <Header />
        <div id="main" className="column1 boxed">
          <div className="container">
            <div className="row main-content-wrap">
              <div className="main-content col-lg-12">
                <div id="content" role="main">
                  <article className="post-208 page type-page status-publish hentry">
                    <div className="page-content">
                      <div className="woocommerce">
                        <div className="woocommerce-notices-wrapper"></div>
                        <div className="cart-v2 ">
                          <h2 className="heading-primary m-b-md font-weight-normal clearfix">
                            <span>Shopping Cart</span>

                          </h2>

                          <div className="row">
                            <div className="col-lg-8 col-xl-9 shoping_carts cart_page">
                              <div className="featured-box featured-box-primary align-left">
                                <div className="box-content">
                                  <form className="woocommerce-cart-form" action="#" method="post">
                                    <div className="table-responsive">
                                      <table className="shop_table responsive cart woocommerce-cart-form__contents" cellSpacing="0">
                                        <thead>
                                          <tr>
                                            <th className="product-remove"></th>
                                            <th className="product-name">Sr.No</th>
                                            <th className="product-thumbnail"></th>
                                            <th className="product-name">Product Name</th>
                                            <th className="product-price">Unit Price</th>
                                            <th className="product-quantity">Qty</th>
                                            <th className="product-subtotal">Subtotal</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {(this.state.items.length == 0) ?
                                            <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td>No data found</td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            :
                                            this.state.items.map((item, index) => (
                                              <tr className="cart_item" key={index}>
                                                <td className="product-remove">
                                                  <a onClick={this.removeSelectedProduct} name={item.id} id={item.qty} style={{ 'cursor': 'pointer' }} className="remove remove-product">×</a>
                                                </td>
                                                <td>{parseFloat(index + 1)}</td>
                                                <td className="product-thumbnail">
                                                  <a href="#"><img width="300" height="300" src={this.state.img_url + `/${item.image_name}`} alt="" /></a>
                                                </td>
                                                <td className="product-name">

                                                  <NavLink onClick={this.setItemQty} key={index} to={`/product-detail/${item.product_id}/1`}>{item.product_name}   ({item.unit_name})
                                                  </NavLink>
                                                </td>

                                                <td className="product-price">
                                                  <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">{item.prefix}</span>  {item.calculation_rate} {item.suffix}</span>
                                                </td>
                                                <td className="product-add-to-cart">
                                                  <div className="quantity_box">
                                                    <input align={index} type="button" id={item.id} defaultValue="-" name={item.itemQty} className="qty-minus minus_plus" onClick={e => this.minusQty(item.id, item.unit_id)} />
                                                    <input align={index} type="text" id="enter_a_value" className="value_box" value={item.itemQty} className="value_box" onChange={e => this.newValue(e, item.id, item.unit_id)} onKeyUp={e => this.newValue(e, item.id, item.unit_id)} />
                                                    <input align={index} type="button" id={item.id} defaultValue="+" className="qty-plus minus_plus" name={item.itemQty} onClick={e => this.plusQty(item.id, item.unit_id)} />
                                                  </div>
                                                </td>
                                                <td className="product-subtotal">
                                                  <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">
                                                    {item.prefix} {((item.calculation_rate) * (item.itemQty)).toLocaleString()} {item.suffix}
                                                  </span></span>
                                                </td>
                                              </tr>
                                            ))


                                          }
                                          {
                                            (this.state.dropbox || this.state.is_dropsip === 1) &&
                                            <tr>
                                              <td colSpan="7">
                                                <div className="droship-heading"><h1>Dropship Products</h1></div>
                                              </td>
                                            </tr>
                                          }


                                          {
                                            (this.state.dropbox || this.state.is_dropsip === 1) &&
                                            this.state.dropshipItem.length > 0 &&
                                            this.state.dropshipItem.map((item, index) => (
                                              <tr className="cart_item dropship_table" key={index}>
                                                <td className="product-remove">
                                                  <a onClick={this.removeSelectedProductDropship} name={item.id} id={item.qty} style={{ 'cursor': 'pointer' }} className="remove remove-product">×</a>
                                                </td>
                                                <td>{parseFloat(index + 1)}</td>
                                                <td className="product-thumbnail">
                                                  <a href="#"><img width="300" height="300" src={this.state.img_url + `/${item.image_name}`} alt="" /></a>
                                                </td>
                                                <td className="product-name">

                                                  <NavLink onClick={this.setItemQty} key={index} to={`/product-detail/${item.product_id}/1`}>{item.product_name}   ({item.unit_name})
                                                  </NavLink>
                                                </td>

                                                <td className="product-price">
                                                  <input style={{ width: '90px' }} type="text" defaultValue={item.calculation_rate} onKeyUp={e => this.changeunitPrice(e, item.id, item.unit_id, item.itemQty)} />
                                                </td>
                                                <td className="product-add-to-cart">
                                                  <div className="quantity_box" style={{ paddingLeft: '38px' }}>

                                                    <input align={index} type="text" id="enter_a_value" className="value_box" value={item.itemQty} className="value_box" readOnly={true} />

                                                  </div>
                                                </td>
                                                <td className="product-subtotal">
                                                  <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">
                                                    {item.prefix} {((item.calculation_rate) * (item.itemQty)).toLocaleString()} {item.suffix}
                                                  </span></span>
                                                </td>

                                              </tr>
                                            ))
                                          }

                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="cart-actions">
                                      <NavLink to={`/`}>
                                        Continue Shopping
                                      </NavLink>
                                    </div>
                                    <div className="clear"></div>
                                  </form>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 col-xl-3 cart_total">
                              <div className="cart-collaterals">
                                <div className="cart_totals">
                                  <div className="cart_totals_toggle">
                                    <div className="card card-default">
                                      <div className="card-header arrow toggle_drop">
                                        <h2 className="card-title"><a >CART TOTALS </a></h2>
                                      </div>
                                      <div id="panel-cart-total" className="accordion-body  show">
                                        <div className="card-body">
                                          <table className="responsive cart-total" cellSpacing="0">
                                            <tbody>
                                              <tr className="cart-subtotal">
                                                <th>Subtotal</th>
                                                <td><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol"></span>{this.state.prefix}{this.state.subtotal.toLocaleString()} </span>{this.state.suffix}</td>
                                              </tr>
                                              <tr className="order-total">
                                                <th>Grand Total</th>
                                                <td><strong><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">{this.state.prefix}</span>{this.state.subtotal.toLocaleString()}</span></strong>{this.state.suffix} </td>
                                              </tr>
                                              {
                                                this.state.is_dropsip === 1 ?
                                                  <React.Fragment>
                                                    <tr>
                                                      <th colSpan="2">
                                                        <div className="card-header arrow toggle_drop">
                                                          <h2 className="card-title"><a >DROPSHIP TOTALS </a></h2>
                                                        </div>
                                                      </th>
                                                    </tr>
                                                    <tr className="cart-subtotal">
                                                      <th>Subtotal</th>
                                                      <td><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol"></span>{this.state.prefix}{this.state.dropshipsubtotal.toLocaleString()} </span>{this.state.suffix}</td>
                                                    </tr>
                                                    <tr className="order-total">
                                                      <th>Grand Total</th>
                                                      <td><strong><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">{this.state.prefix}</span>{this.state.dropshipsubtotal.toLocaleString()}</span></strong>{this.state.suffix} </td>
                                                    </tr>
                                                  </React.Fragment>
                                                  : ''
                                              }
                                            </tbody>
                                          </table>
                                          <div className="wc-proceed-to-checkout">
                                            <NavLink className="btn btn-primary btn-block" to="/check-out">Proceed to checkout</NavLink>

                                          </div>
                                          {this.state.user_id === null &&

                                            <div className="wc-proceed-to-checkout" style={{ marginTop: '10px' }}>
                                              <NavLink className="btn btn-primary btn-block" to="/guest-check-out" style={{ background: '#d6a748' }}>Guest Checkout</NavLink>

                                            </div>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {
                                //   this.state.is_dropsip=="1" ?
                                //   <div className="cart-collaterals">
                                //    <div className="cart_totals">
                                //       <div className="cart_totals_toggle">
                                //          <div className="card card-default">
                                //             <div className="card-header arrow toggle_drop">
                                //                <h2 className="card-title"><a >DROPSHIP TOTALS </a></h2>
                                //             </div>
                                //             <div id="panel-cart-total" className="accordion-body  show">
                                //                <div className="card-body">
                                //                   <table className="responsive cart-total" cellSpacing="0">
                                //                      <tbody>

                                //                      </tbody>
                                //                   </table>
                                //                   <div className="wc-proceed-to-checkout">
                                //                      <NavLink className="btn btn-primary btn-block" to="/check-out">Proceed to checkout</NavLink>

                                //                   </div>
                                //                   {this.state.user_id===null &&

                                //                     <div className="wc-proceed-to-checkout" style={{marginTop:'10px'}}>
                                //                        <NavLink className="btn btn-primary btn-block" to="/guest-check-out" style={{background:'#d6a748'}}>Guest Checkout</NavLink>

                                //                     </div>
                                //                   }
                                //                </div>
                                //             </div>
                                //          </div>
                                //       </div>
                                //    </div>
                                // </div>
                                // :''
                              }
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <div className="">
                  </div>
                </div>
              </div>
              <div className="sidebar-overlay"></div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Cart;
