import React, { Component } from 'react';
import $ from 'jquery';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import image2 from '../assets/images/next-icon.png';
import Avatar from '../assets/images/avatar.webp';
import Header  from "./Header/Header";
import Footer  from "./Footer/Footer";
import {Redirect} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import {api_url} from '../Config.js';
import styled from 'styled-components';
import DComponent from './Home/DynamicComponent.js';
import {Favourite} from '../services/Favourite.js';
import {Unfavourite} from '../services/Unfavourite.js';
import {Myshop} from '../services/Myshop.js';
import {RemoveMyShop} from '../services/RemoveMyShop.js';
import swal from 'sweetalert';
import loader from '../assets/images/loading.gif';
const Container = styled.div`
  background-color:#444;
  color:white;
  padding:16px;
  position:fixed;
  top:${props=> props.top}px;
  right:16px;
  z-index:999;
  transition: top 0.5s ease;
`;
class ProductDetails extends Component {
constructor(props) {
super(props);
this.state = {
      error: null,
      products: [],
      backupproducts: [],
      cartProducts:[],
      brands:[],
      itemShow:10,
      top:-8000,
      categories:[],
      productsFilters:[],
      filter:[],
      loading:true,
      addtocart:'',
      itemquantity:1,
      items: [],
      cartCount:(localStorage.getItem('cartCount')? parseInt(localStorage.getItem('cartCount')) : 0),
      filterId:localStorage.getItem('filterId'),
      filterData:[],
      fillterCategory:'',
      filterOption:'',
      changeCategory:"",
      data:[],
      top: -800,
      redirectToReferrer: false,
      productsAll :localStorage.getItem('session'),
      relatedProducts :[],
      name:'',
      productId:'',
      totalItems:(localStorage.getItem('cartCount')? localStorage.getItem('cartCount') : 0),
      itemsShow:sessionStorage.getItem('itemQty'),
      base_url:api_url.base_url,
      img_url:api_url.img_url,
      com_url:api_url.com_url,
      imageGalery:[],
      uomproducts:[],
      dynamicprice:0,
      dynamicsku:'',
      dynamicDiscountedprice:0,
      dynamic_unit:'',
      dynmicProduct:[],
      cart:JSON.parse(localStorage.getItem('session')),
      counter:1,
      image_galery:[],
      calculation_rate:0,
      discounted_price:0,
      saleRate:0,
      out_of_stock:0,
      barcode:'',
      unitdetail:[],
      unit_id:'',
      unitName:'',
      country:'',
      phone_number:'',
      itemQty:1,
      product_id:'',
      is_favourite:0,
      is_shop:0,
  };
  // this.plusValue = this.plusValue.bind(this);
  // this.minusValue = this.minusValue.bind(this);
  // this.newValue = this.newValue.bind(this);
  this.addToCart = this.addToCart.bind(this);
  this.setStateOfParent = this.setStateOfParent.bind(this);
}
async setStateOfParent (result) {


      await this.setState({
        cartCount:result
      },()=>{
        localStorage.setItem('cartCount',result);
        localStorage.setItem('cartCount',result);
        console.log(localStorage.getItem('cartCount'));
      })
    }
async componentDidMount() {
    $(".heart").click(function() {
      $(this).toggleClass('fa-heart');
      $(this).toggleClass('fa-heart-o');
    });
    $(".cart").click(function(){
      $(this).toggleClass('fa-shopping-cart');
      $(this).toggleClass('fa-shoppong-basket');
    });
      window.scrollTo(0, 0);
      $('.show-small-img:first-of-type').css({'border': 'solid 1px #951b25', 'padding': '2px'})
      $('.show-small-img:first-of-type').attr('alt', 'now').siblings().removeAttr('alt');
      $(document).on('click','.show-small-img',function(){
      // $('.show-small-img').click(function () {
        $('#show-img').attr('src', $(this).attr('src'))
        $('#big-img').attr('src', $(this).attr('src'))
        $(this).attr('alt', 'now').siblings().removeAttr('alt')
        $(this).css({'border': 'solid 1px #951b25', 'padding': '2px'}).siblings().css({'border': 'none', 'padding': '0'})
        if ($('#small-img-roll').children().length > 4) {
          if ($(this).index() >= 3 && $(this).index() < $('#small-img-roll').children().length - 1){
            $('#small-img-roll').css('left', -($(this).index() - 2) * 76 + 'px')
          } else if ($(this).index() == $('#small-img-roll').children().length - 1) {
            $('#small-img-roll').css('left', -($('#small-img-roll').children().length - 4) * 76 + 'px')
          } else {
            $('#small-img-roll').css('left', '0')
          }
        }
      })
//Enable the next button
      $(document).on('click','#next-img',function(){
        $('#show-img').attr('src', $(".show-small-img[alt='now']").next().attr('src'))
          $('#big-img').attr('src', $(".show-small-img[alt='now']").next().attr('src'))
          $(".show-small-img[alt='now']").next().css({'border': 'solid 1px #951b25', 'padding': '2px'}).siblings().css({'border': 'none', 'padding': '0'})
          $(".show-small-img[alt='now']").next().attr('alt', 'now').siblings().removeAttr('alt')
          if ($('#small-img-roll').children().length > 4) {
            if ($(".show-small-img[alt='now']").index() >= 3 && $(".show-small-img[alt='now']").index() < $('#small-img-roll').children().length - 1){
              $('#small-img-roll').css('left', -($(".show-small-img[alt='now']").index() - 2) * 76 + 'px')
            } else if ($(".show-small-img[alt='now']").index() == $('#small-img-roll').children().length - 1) {
              $('#small-img-roll').css('left', -($('#small-img-roll').children().length - 4) * 76 + 'px')
            } else {
              $('#small-img-roll').css('left', '0')
            }
          }
      })

//Enable the previous button
$('#prev-img').click(function (){
  $('#show-img').attr('src', $(".show-small-img[alt='now']").prev().attr('src'))
  $('#big-img').attr('src', $(".show-small-img[alt='now']").prev().attr('src'))
  $(".show-small-img[alt='now']").prev().css({'border': 'solid 1px #951b25', 'padding': '2px'}).siblings().css({'border': 'none', 'padding': '0'})
  $(".show-small-img[alt='now']").prev().attr('alt', 'now').siblings().removeAttr('alt')
  if ($('#small-img-roll').children().length > 4) {
    if ($(".show-small-img[alt='now']").index() >= 3 && $(".show-small-img[alt='now']").index() < $('#small-img-roll').children().length - 1){
      $('#small-img-roll').css('left', -($(".show-small-img[alt='now']").index() - 2) * 76 + 'px')
    } else if ($(".show-small-img[alt='now']").index() == $('#small-img-roll').children().length - 1) {
      $('#small-img-roll').css('left', -($('#small-img-roll').children().length - 4) * 76 + 'px')
    } else {
      $('#small-img-roll').css('left', '0')
    }
  }
})
 // $(document).on('click', '.qty-plus', function () {
 //  const newvalue = $(this).prev().val(+$(this).prev().val() + 1);

 //    });
 //    $(document).on('click', '.qty-minus', function () {
 //       if ($(this).next().val() > 1) $(this).next().val(+$(this).next().val() - 1);
 //    });


      $(document).ready(function(){

        $('ul.tabs li').click(function(){
          var tab_id = $(this).attr('data-tab');
          $('ul.tabs li').removeClass('current');
          $('.tab-content').removeClass('current');
          $(this).addClass('current');
          $("#"+tab_id).addClass('current');
        })
      })

      const settingUrl =this.state.com_url+'moiz_api/get_general_settings';
      await fetch(settingUrl)
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
             country: result.country,
             phone_number:result.phone,
            })

          },
          (error) => {
            this.setState({ error });
          }
        )

    const value=this.props.match.params;
    this.setState({product_id:value.productId});
    var api_Data = {user_id:sessionStorage.getItem('userData')};
    const apiUrl = this.state.base_url+'products_api/getProductsFilter/'+value.productId+'/'+value.unitID;
    await fetch(apiUrl,{
          method: 'POST',
          headers:
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body:JSON.stringify(api_Data)
        })
      .then(res => res.json())
      .then(
        (result) => {
            this.setState({
              productsFilters: result,
              image_galery:result.image_galery,
              calculation_rate:result.calculation_rate,
              discounted_price:result.discounted_price,
              saleRate:result.saleRate,
              barcode:result.barcode,
              unitdetail:result.unitsdetail,
              out_of_stock:result.out_of_stock,
              description:result.description,
              unit_id:value.unitID,
              unitName:result.unit_name,
              is_favourite:result.is_favourit,
              is_shop:result.is_shop,
            })

            this.setState({loading:false})
            this.state.products=[];
            this.state.backupproducts=[];
            const apiUrls = this.state.base_url+'products_api/getProducts/20/'+result.groupId;
            var api_Data = {user_id:sessionStorage.getItem('userData')};
            fetch(apiUrls,{
                method: 'POST',
                headers:
                {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                },
                body:JSON.stringify(api_Data)
              })
              .then(res => res.json())
              .then(
                (result) => {
                  this.setState({
                    products: result.products,
                    backupproducts:result.products,
                    loading:false,
                    totalProducts:result.total,
                  });
                },
                (error) => {
                  this.setState({ error ,loading:false });
                }
              )

        },
        (error) => {
          this.setState({ error });
        }
      )


  }
plusQty = (id,unit_id) => {
    this.setState({itemQty:parseFloat(this.state.itemQty) + 1});
  }
minusQty = (id,unit_id) => {
    this.setState({itemQty:parseFloat(this.state.itemQty) - 1});
  }
  newValue=(e,id,unit_id)=>{
      this.setState({itemQty:e.target.value});
  }
buyNow=(e,product)=>
{
  this.setState({
      top:5,
    },function(){
      setTimeout(()=>{
        this.setState({
          top: -800,
        })
      },1500)
    })
    product.calculation_rate = this.state.calculation_rate;
    product.unit_name = this.state.unitName;
    product.unit_id = this.state.unit_id;
    product.image_name=this.state.productsFilters.firstImage;
    product.itemQty = this.state.itemQty;
    var flag = 0;
    var products_array=[];
    products_array = (JSON.parse(localStorage.getItem('cartProducts'))!=null) ? JSON.parse(localStorage.getItem('cartProducts')):[];
    if(products_array!=null)
    {
      for(var i in products_array)
      {
        var item = products_array[i];
        if(item.product_id == product.product_id && item.unit_id == product.unit_id)
        {
          flag = 1;
          item.itemQty+=parseFloat(1);
          break;
        }
      }
      if(flag  == 0)
      {
          products_array.push(product);
      }
    }
    else
    {
      products_array.push(product);
    }
    localStorage.setItem('cartProducts',JSON.stringify(products_array));
    this.setState({addtocart:"Product Added to Cart!"});
    this.setState({cartCount:products_array.length});
    localStorage.setItem('cartCount',products_array.length);
    // window.location.href="/check-out";
}
addToCart=(e,product)=>
{
  this.setState({
      top:5,
    },function(){
      setTimeout(()=>{
        this.setState({
          top: -800,
        })
      },1500)
    })
    product.calculation_rate = this.state.calculation_rate;
    product.unit_name = this.state.unitName;
    product.unit_id = this.state.unit_id;
    product.image_name=this.state.productsFilters.firstImage;
    product.itemQty = this.state.itemQty;
    var flag = 0;
    var products_array=[];
    products_array = (JSON.parse(localStorage.getItem('cartProducts'))!=null) ? JSON.parse(localStorage.getItem('cartProducts')):[];
    if(products_array!=null)
    {
      for(var i in products_array)
      {
        var item = products_array[i];
        if(item.product_id == product.product_id && item.unit_id == product.unit_id)
        {
          flag = 1;
          item.itemQty+=parseFloat(1);
          break;
        }
      }
      if(flag  == 0)
      {
          products_array.push(product);
      }
    }
    else
    {
      products_array.push(product);
    }
    localStorage.setItem('cartProducts',JSON.stringify(products_array));
    this.setState({addtocart:"Product Added to Cart!"});
    this.setState({cartCount:products_array.length});
    localStorage.setItem('cartCount',products_array.length);

}
getUnitDetail = (e,qty,out_of_stock,discounted_price,saleRate,unit_id,unitName,calculation_rate,barcode) =>
{
  this.setState({
      unit_id:unit_id,
      discounted_price:discounted_price,
      saleRate:saleRate,
      out_of_stock:out_of_stock,
      calculation_rate:calculation_rate,
      unitName:unitName,
      unit_id:unit_id,
      barcode:barcode,
      top:-8000,
    });
  if(out_of_stock == 0 && qty < 0)
  {
    this.setState({out_of_stock:1});
  }
}
changeCategory(e){
  this.setState({changeCategory:e.target.value},function(){
    localStorage.setItem('categoryName',this.state.changeCategory)
  })
}
addToFavoite(e){
  const value=this.state.product_id;
  // value.title.user_id=1;
  Favourite(value);
}
Unfavourite(e){
    const value=this.state.product_id;
    Unfavourite(value);
}
addtoMyshop(e){
    const value=this.state.product_id;
    Myshop(value);
}
RemoveMyshop(e){
    const value=this.state.product_id;
    RemoveMyShop(value);
}
componentDidUpdate(){


     var itemsMainDiv = ('.MultiCarousel');
    var itemsDiv = ('.MultiCarousel-inner');
    var itemWidth = "";

    $('.leftLst, .rightLst').click(function () {
        var condition = $(this).hasClass("leftLst");
        if (condition)
            click(0, this);
        else
            click(1, this)
    });

    ResCarouselSize();




    $(window).resize(function () {
        ResCarouselSize();
    });

    //this function define the size of the items
    function ResCarouselSize() {
        var incno = 0;
        var dataItems = ("data-items");
        var itemClass = ('.item');
        var id = 0;
        var btnParentSb = '';
        var itemsSplit = '';
        var sampwidth = $(itemsMainDiv).width();
        var bodyWidth = $('body').width();
        $(itemsDiv).each(function () {
            id = id + 1;
            var itemNumbers = $(this).find(itemClass).length;
            btnParentSb = $(this).parent().attr(dataItems);
            itemsSplit = btnParentSb.split(',');
            $(this).parent().attr("id", "MultiCarousel" + id);


            if (bodyWidth >= 1200) {
                incno = itemsSplit[3];
                itemWidth = sampwidth / incno;
            }
            else if (bodyWidth >= 992) {
                incno = itemsSplit[2];
                itemWidth = sampwidth / incno;
            }
            else if (bodyWidth >= 768) {
                incno = itemsSplit[1];
                itemWidth = sampwidth / incno;
            }
            else {
                incno = itemsSplit[0];
                itemWidth = sampwidth / incno;
            }
            $(this).css({ 'transform': 'translateX(0px)', 'width': itemWidth * itemNumbers });
            $(this).find(itemClass).each(function () {
                $(this).outerWidth(itemWidth);
            });

            $(".leftLst").addClass("over");
            $(".rightLst").removeClass("over");

        });
    }


    //this function used to move the items
    function ResCarousel(e, el, s) {
        var leftBtn = ('.leftLst');
        var rightBtn = ('.rightLst');
        var translateXval = '';
        var divStyle = $(el + ' ' + itemsDiv).css('transform');
        var values = divStyle.match(/-?[\d\.]+/g);
        var xds = Math.abs(values[4]);
        if (e == 0) {
            translateXval = parseInt(xds) - parseInt(itemWidth * s);
            $(el + ' ' + rightBtn).removeClass("over");

            if (translateXval <= itemWidth / 2) {
                translateXval = 0;
                $(el + ' ' + leftBtn).addClass("over");
            }
        }
        else if (e == 1) {
            var itemsCondition = $(el).find(itemsDiv).width() - $(el).width();
            translateXval = parseInt(xds) + parseInt(itemWidth * s);
            $(el + ' ' + leftBtn).removeClass("over");

            if (translateXval >= itemsCondition - itemWidth / 2) {
                translateXval = itemsCondition;
                $(el + ' ' + rightBtn).addClass("over");
            }
        }
        $(el + ' ' + itemsDiv).css('transform', 'translateX(' + -translateXval + 'px)');
    }

    //It is used to get some elements from btn
    function click(ell, ee) {
        var Parent = "#" + $(ee).parent().attr("id");
        var slide = $(Parent).attr("dataSlide");
        ResCarousel(ell, Parent, slide);
    }

}
render() {
   console.log('page is rendering'+ localStorage.getItem('cartCount'))
    const {loading} = this.state;
    const phone_number = this.state.phone_number;
    const whatsappurl = "https://api.whatsapp.com/send?phone="+phone_number+"&text="+window.location.origin+"/#/product-detail/"+this.state.productsFilters.id+'/'+this.state.unit_id;
    const { error, products,relatedProducts} = this.state;
    if(error) {
      return (
        <div>Error: {error.message}</div>
      )
    } else {
      return(
          <div>
          <Header />
            <Container top={this.state.top}>{this.state.addtocart}</Container>
            <div id="main" className="thumbnail_slider column2 column2-right-sidebar boxed">
              <div className="container">
                <div className="row main-content-wrap">
                   <div className="main-content col-lg-12 details_box">
                      <div id="primary" className="content-area">
                         <main id="content" className="site-main" role="main">
                               <div className="product-summary-wrap">
                                  <div className="row">
                                     <div className="summary-before col-md-5 ">
                                        <div className="hot_deals">
                                          <div className="labels">
                                           <div className="onhot">Hot</div>
                                        </div>
                                        <div className="show" href="1.jpg">
                                          {<img src={this.state.img_url+`/${this.state.productsFilters.firstImage }`} id='show-img' /> || <Skeleton height={200} width={150} />}
                                       </div>
                                        <div className="small-img">
                                          <img src={image2} className="icon-left" alt="" id="prev-img" />
                                           <div className="small-container">
                                            <div id="small-img-roll">

                                            {this.state.image_galery.map((img,index)=>{

                                              const imgname = this.state.img_url+`/${img.image_name}`;
                                              return(

                                              <img key={index}  src={ imgname  || <Skeleton />} className="show-small-img"  alt=""  />
                                              )

                                            }

                                              )
                                            }

                                            </div>
                                           </div>
                                            <img src={image2} className="icon-right" alt="" id="next-img" />
                                         </div>
                                        </div>
                                     </div>
                                     <div className="summary entry-summary col-md-7">
                                      <h2 itemProp="name" className="product_title entry-title show-product-nav">{this.state.productsFilters.product_name || <Skeleton />}</h2>
                                        <div className="woocommerce-product-rating">
                                           <div className="review-link noreview">
                                           {(
                                              (this.state.productsFilters.brandName) ?
                                                <h5>Brand: <a href="#">{this.state.productsFilters.brandName || <Skeleton /> }</a></h5>
                                              :""
                                            )}
                                              <a href="#" className="woocommerce-write-review-link" rel="nofollow">
                                                 <div className="start-icons">
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                 </div>
                                              </a>
                                              <span>( 4 Reviews )</span>
                                           </div>
                                        </div>
                                        <p className="price">
                                          <span className="woocommerce-Price-amount amount">
                                            <span className="woocommerce-Price-currencySymbol">{this.state.productsFilters.prefix}{sessionStorage.setItem('curencySymbol',this.state.productsFilters.prefix)} </span>

                                           <span  className="total_price"> {this.state.calculation_rate.toLocaleString()}{sessionStorage.setItem('unitPrice',this.state.calculation_rate.toLocaleString())}{this.state.productsFilters.suffix  || <Skeleton />}</span>

                                          </span>
                                          {(
                                            this.state.discounted_price > 0 ?
                                              <span>
                                                <del><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">{this.state.productsFilters.prefix}</span>{this.state.saleRate.toLocaleString()}</span></del>
                                                 <b className="sale">({(100-((this.state.discounted_price/this.state.saleRate)*100).toFixed(0))}%)</b>
                                              </span>
                                            :""
                                          )}
                                        </p>
                                        {(
                                            this.state.out_of_stock  == 1 ?
                                              <div className="mf-summary-meta"><p className="stock in-stock"><label>Status:</label>Out of stock</p>
                                              </div>
                                            :""
                                        )}
                                        <div className="cart">
                                           <div className="links-on-image">
                                              <div className="add-links-wrap">
                                                 <div className="add-links clearfix">
                                                  <div className="quantity_box">
                                                     <div className="quantity_bdr">
                                                     <label>Quantity</label>
                                                      <div className="quantity_plus" id="quantity_box">
                                                      <input type="button" defaultValue="-" className="qty-minus minus_plus" onClick={e =>this.minusQty(e,this.state.productsFilters.id,this.state.unit_id)} />
                                                     <input type="text" name="totalItems" value={this.state.itemQty} className="value_box" onChange={e => this.newValue(e,this.state.productsFilters.id,this.state.unit_id)} onKeyUp={e => this.newValue(e,this.state.productsFilters.id,this.state.unit_id)} />
                                                     <input type="button" defaultValue="+" className="qty-plus minus_plus" onClick={e => this.plusQty(e,this.state.productsFilters.id,this.state.unit_id)} />
                                                      </div>
                                                    </div>
                                                    {(
                                                      this.state.out_of_stock == 0 ?
                                                        <div className="add_tocart">
                                                        <a  style={{'cursor':'pointer'}} className={this.state.productsFilters.id} onClick={e=>this.addToCart(e,this.state.productsFilters)}>Add to Cart</a>
                                                        <NavLink className={this.state.productsFilters.id} onClick={e=>this.buyNow(e,this.state.productsFilters)} to={`/check-out`} className="buy_now_btn">Buy Now</NavLink>
                                                        <a href={whatsappurl} target="_blank" className="whatsapp_btn"><i className="fa fa-whatsapp"></i> Order on WhatsApp</a>
                                                      </div>
                                                      :""
                                                    )}

                                                  </div>
                                                 </div>

                                                {(
                                                  sessionStorage.getItem('userData') &&
                                                    <div className="compare_browser">
                                                     <ul>
                                                       <li>
                                                         <a>
                                                          {(
                                                              this.state.is_favourite == 1 ?
                                                              <i className="fa heart fa-heart" onClick={e=>this.Unfavourite(e)}></i>
                                                              :
                                                              <i className="fa fa-heart-o heart" onClick={e=>this.addToFavoite(e)}></i>

                                                          )}
                                                           Wishlist
                                                          </a>
                                                        </li>
                                                       <li>
                                                         <a>
                                                          {(
                                                              this.state.is_shop == 1 ?
                                                              <i className="fa fa-shopping-basket cart fa-shopping-cart fa-shoppong-basket" onClick={e=>this.RemoveMyshop(e)}></i>
                                                              :
                                                              <i className="fa fa-shopping-basket cart" onClick={e=>this.addtoMyshop(e)}></i>
                                                          )}
                                                             Store
                                                          </a>
                                                        </li>
                                                     </ul>
                                                   </div>
                                                )}

                                                 <div className="report_abuse">
                                                 <b>Report Abuse</b>
                                                  <div className="product_meta">
                                                    {(
                                                        (this.state.barcode) ?
                                                          <span className="sku_wrapper">SKU: <span className="sku" itemProp="sku">{this.state.barcode}</span></span>
                                                        :""
                                                    )}
                                                    {(
                                                        (this.state.productsFilters.groupName) ?
                                                          <span className="posted_in">Categories: <a href="#" rel="tag">{this.state.productsFilters.groupName  || <Skeleton />}</a>,
                                                            <a href="#" rel="tag">{this.state.productsFilters.groupName   || <Skeleton />}</a>
                                                          </span>
                                                        :""
                                                    )}

                                                    </div>
                                                    {
                                                      (this.state.unitdetail.length > 0) ?
                                                        this.state.unitdetail.map((item,index)=>(
                                                          <div className="products-variations" key={index}>
                                                              <a className="active"  name={item.unit_id} onClick={e=>this.getUnitDetail(e,item.qty,item.out_of_stock,item.discounted_price,item.saleRate,item.unit_id,item.UOMName,item.calculation_rate,item.barcode)}>{item.UOMName  || <Skeleton />}</a>
                                                          </div>
                                                      ))
                                                      :""
                                                    }


                                                 </div>
                                                 <div className="social_links">
                                                 <ul>
                                                   <li><a href="#" targe="_blank"><i className="fa fa-facebook"></i></a></li>
                                                   <li><a style={{'background':'#00aeef'}} href="#" targe="_blank"><i className="fa fa-twitter"></i></a></li>
                                                   <li><a style={{'background':'#cc0000'}} href="#" targe="_blank"><i className="fa fa-google-plus"></i></a></li>
                                                   <li><a style={{'background':'#0077b5'}} href="#" targe="_blank"><i className="fa fa-linkedin"></i></a></li>
                                                   <li><a style={{'background':'#4c75a3'}} href="#" targe="_blank"><i className="fa fa-vk"></i></a></li>
                                                   <li><a  style={{'background':'#c8232c'}} href="#" targe="_blank"><i className="fa fa-pinterest"></i></a></li>
                                                 </ul>
                                                 </div>
                                                 <div className="total_bln_cart">
                                                     <span className="product_cart_price"><h3>Total Price:  <b>{this.state.productsFilters.prefix} {((this.state.calculation_rate)*(this.state.itemQty)).toLocaleString()}{this.state.productsFilters.suffix}</b> </h3> </span>
                                                 </div>

                                              </div>
                                           </div>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="products_details_tabs">
                                  <ul className="tabs">
                                     <li className="tab-link current" data-tab="tab-1">Description</li>
                                     <li className="tab-link" data-tab="tab-2">Reviews </li>
                                  </ul>
                                  <div id="tab-1" className="tab-content current">
                                     <div className="woocommerce-tabs" id="product-tab">
                                      <div className="resp-tabs-container">
                                         <div  id="tab-description">
                                            <div className="vc_row wpb_row row m-b-md">
                                               <div className="vc_column_container col-md-12">
                                                  <div className="wpb_wrapper vc_column-inner">
                                                     <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                          <p dangerouslySetInnerHTML={{__html:this.state.description  || <Skeleton />}}></p>
                                                        </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </div>
                                            <div className="vc_row wpb_row row m-b-md vc_custom_1511248807031">
                                               <div className="vc_column_container col-md-12">
                                                  <div className="wpb_wrapper vc_column-inner">
                                                     <ul id="porto-info-list9713549045cdf81dd298f3" className="porto-info-list ">
                                                        <li className="porto-info-list-item">
                                                           <div className="porto-info-list-item-desc">
                                                           </div>
                                                        </li>
                                                        <li className="porto-info-list-item">
                                                           <div className="porto-info-list-item-desc">
                                                           </div>
                                                        </li>
                                                        <li className="porto-info-list-item">
                                                           <div className="porto-info-list-item-desc">
                                                           </div>
                                                        </li>
                                                     </ul>
                                                  </div>
                                               </div>
                                            </div>
                                            <div className="vc_row wpb_row row">
                                               <div className="vc_column_container col-md-12">
                                                  <div className="wpb_wrapper vc_column-inner">
                                                     <div className="wpb_text_column wpb_content_element ">
                                                        <div className="wpb_wrapper">
                                                           <p></p>
                                                        </div>
                                                     </div>
                                                  </div>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                  </div>
                                  <div id="tab-2" className="tab-content ">
                                     <div className="customer_reviews">
                                       <h3>Customer Reviews</h3>
                                       <ul>
                                         <li><i className="fa fa-star"></i></li>
                                         <li><i className="fa fa-star"></i></li>
                                         <li><i className="fa fa-star"></i></li>
                                         <li><i className="fa fa-star"></i></li>
                                         <li><i className="fa fa-star"></i></li>
                                         <p>Based on 1 review</p>
                                       </ul>
                                     </div>
                                     <div className="row">
                                       <div className="col-sm-1 avatar_reviews">
                                         <img src={Avatar} />
                                       </div>
                                       <div className="col-sm-11 review_content">
                                         <h5>Head Fone Out className <span> wesleynkala16 on Apr 07, 2020</span></h5>
                                         <p>Delivery was A1 came when expected to the UK within 7 days which was impressive, sound quality is very good for the price, very good product hard to give less than 4* Great satisfaction, fast delivery. The goods match what I ordered. Thank you</p>
                                       </div>
                                     </div>
                                  </div>
                               </div>
                         </main>
                      </div>
                   </div>
                   <div className="sidebar-overlay"></div>
                </div>
             </div>
             <section className="tabs_blog new_add_products">
              <div className="container">
                <div className="row">
                      <div className="col-sm-12 new_arrivals">
                      {
                        this.state.products!=undefined &&
                          <section className="best_deal">
                              <div className="main_head">
                                <h3>Related products</h3>
                              </div>
                              <div className="section-title">
                                <div className="bar"></div>
                              </div>

                               <div className="shadow_box">
                              <div className="row">
                              {loading?

                                        <div className=" row Image_loader "  style={{margin:'0 auto'}} >
                                        <img src={loader} />
                                        </div>


                                       :
                                  <div className="MultiCarousel" data-items="1,2,3,4" dataslide="1" id="MultiCarousel"  data-interval="1000">
                                      <div className="MultiCarousel-inner">
                                      {
                                      this.state.products.map((product,index) => (
                                      <div className="item" key={index}>
                                      <DComponent setStateOfParent={this.setStateOfParent} title={product} key={index} value={index} />
                                      </div>
                                      ))}

                                      </div>

                                      {(this.state.products.length > 4) &&
                                        <div className="ant-carousel-arrow-left leftLst"><i class="fa fa-angle-left"></i></div>
                                       }
                                      {(this.state.products.length > 4) &&
                                        <div className="ant-carousel-arrow-right rightLst"><i class="fa fa-angle-right"></i></div>
                                      }

                                  </div>

                                    }



                                </div>
                              </div>
                          </section>
                      }
                      </div>
                    </div>
              </div>
            </section>
          </div>

            <Footer />
            </div>
        );
    }
}
}
export default ProductDetails;
