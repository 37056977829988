import React, { Component } from 'react';
import $ from 'jquery';
import full6 from '../../assets/images/full_6.png';
import {NavLink} from 'react-router-dom';
import {api_url} from '../../Config.js';
import styled from 'styled-components';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import * as ReactBootStrap from 'react-bootstrap';
const Container = styled.div`
  background-color:#444;
  color:white;
  padding:16px;
  position:fixed;
  top:${props=> props.top}px;
  right:16px;
  z-index:999;
  transition: top 0.9s ease;
`;
class CategoryContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      products: [],
      backupproducts: [],
      cartProducts:[],
      brands:[],
      itemShow:10,
      top:-8000,
      categories:[],
      categories_ids:[],
      subcategories:[],
      subcategories_ids:[],
      productsFilters:[],
      filter:[],
      totalItems:"",
      loading:false,
      addtocart:'',
      itemquantity:1,
      items: [],
      cartCount:(localStorage.getItem('cartCount') ? parseInt(localStorage.getItem('cartCount')) : 0),
      filterId:localStorage.getItem('filterId'),
      base_url:api_url.base_url,
      img_url:api_url.img_url,
      filterData:[],
      fillterCategory:'',
      filterOption:'',
      changeCategory:"",
      colorName:'#555555',
      backupSubcategories:[],
      loading:true,
      setloading:false,
      totalProducts:0,
    }
    // this.addToCart = this.addToCart.bind(this);
    this.loadmore = this.loadmore.bind(this);
    this.viewAllProducts = this.viewAllProducts.bind(this);
    // this.handleChanged = this.handleChanged.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.changePageTitle = this.changePageTitle.bind(this);
    // this.category_click = this.category_click.bind(this);
  }



async componentDidMount() {
  var pathArray = window.location.pathname.split('/');
  if(pathArray[1] == 'product-detail')
  {
    window.location.href = window.location.origin+'/#'+window.location.pathname;
  }
  const allSub = this.state.base_url+`products_api/GetAllSubCategories`;
    fetch(allSub)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
           subcategories: result,
           backupSubcategories:result,

          });

        },
        (error) => {
          this.setState({ error });
        }
      )
  $('.cart_btn_a').on('click',function(){
    $('.cart_btn_a').removeClass('active');
    $(this).addClass('active');
    alert('ok');
  });





  $(".heart").click(function() {
    $(this).toggleClass('fa-heart');
    $(this).toggleClass('fa-heart-o');

  });
  $(".cart").click(function() {
    $(this).toggleClass('fa-shopping-cart');
    $(this).toggleClass('fa-cart-plus');
  });

    localStorage.removeItem('calculation_rate');
    localStorage.removeItem('unit_id');
    localStorage.removeItem('unit_name');


   const caturl = this.state.base_url+'products_api/GetCategories';
    fetch(caturl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            categories: result

          });
        },
        (error) => {
          this.setState({ error });
        }
      )
    const allCat = this.state.base_url+'products_api/AllCategories';
    fetch(allCat)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            brands: result
          });
        },
        (error) => {
          this.setState({ error });
        }
      )
    const apiUrl = this.state.base_url+'products_api/getProducts/10';
    var api_Data = {user_id:sessionStorage.getItem('userData')};
    await fetch(apiUrl,{
          method: 'POST',
          headers:
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body:JSON.stringify(api_Data)
        })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            products: result.products,
            backupproducts:result.products,
            loading:false,
            totalProducts:result.total,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )
    $(document).on('click','ul.tabs li',function(){
        // $('ul.tabs li').click(function(){
        var tab_id = $(this).attr('data-tab');

        $('ul.tabs li').removeClass('current');
        $('.tab-content').removeClass('current');

        $(this).addClass('current');
        $("#"+tab_id).addClass('current');
      })
         $(".tab-section- .tabs a").on("click",function(e){
      $(this.hash).slideDown().siblings().slideUp();
        e.preventDefault();
      }).first().click();

       // for active class
        $(".tab-section- .tabs a").on("click",function(){
        $(".tabs a.active").removeClass("active");
        $(this).addClass("active");
    });

    $('ul.tabs_sort li').click(function(){
    var tab_id = $(this).attr('data-tab');
    $('ul.tabs_sort li').removeClass('active');
    $('.tab-content-sort').removeClass('active');
    $(this).addClass('active');
    $("#"+tab_id).addClass('active');
  });



  $(".collapsed").click(function(){
      $(".collapse_info").slideToggle();
  });


  $(".cata-sub-slider").on('scroll', function() {
        var val = $(this).scrollLeft();
        if($(this).scrollLeft() + $(this).innerWidth()>=$(this)[0].scrollWidth){
            $(".cata-sub-slider .nav-next-").hide();
          } else {
          $(".cata-sub-slider .nav-next-").show();
        }
        if(val == 0){
          $(".cata-sub-slider .nav-prev-").hide();
        } else {
          $(".cata-sub-slider .nav-prev-").show();
        }
      });
    $(".cata-sub-slider .nav-next-").on("click", function(){
      $(".cata-sub-slider").animate( { scrollLeft: '+=185' }, 200);

    });
    $(".cata-sub-slider .nav-prev-").on("click", function(){
      $(".cata-sub-slider").animate( { scrollLeft: '-=185' }, 200);
    });




    $(".cata-sub-nav").on('scroll', function() {
         var val = $(this).scrollLeft();

        if($(this).scrollLeft() + $(this).innerWidth()>=$(this)[0].scrollWidth){
            $(".nav-next").hide();
          } else {
          $(".nav-next").show();
        }

        if(val == 0){
          $(".nav-prev").hide();
        } else {
          $(".nav-prev").show();
        }
      });
    $(".nav-next").on("click", function(){
      $(".cata-sub-nav").animate( { scrollLeft: '+=43.2' }, 200);

    });
    $(".nav-prev").on("click", function(){
      $(".cata-sub-nav").animate( { scrollLeft: '-=43.2' }, 200);
    });
  }


  async viewAllProducts()
  {
    const apiUrl = this.state.base_url+'products_api/getProducts/all';
   this.setState({products:[],backupproducts:[],totalProducts:0});
    var api_Data = {user_id:sessionStorage.getItem('userData')};
    await fetch(apiUrl,{
          method: 'POST',
          headers:
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body:JSON.stringify(api_Data)
        })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            products: result.products,
            backupproducts:result.products,
            loading:false,
            totalProducts:result.total,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )
  }


 async loadmore(){
      var length = parseInt(this.state.products.length) + 10;
      const apiUrl = this.state.base_url+'products_api/getProducts/'+length;
       this.state.products=[];
       this.state.backupproducts=[];
        var api_Data = {user_id:sessionStorage.getItem('userData')};
        await fetch(apiUrl,{
              method: 'POST',
              headers:
              {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
              body:JSON.stringify(api_Data)
            })
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                products: result.products,
                backupproducts:result.products,
                loading:false,
                totalProducts:result.total,
              });
            },
            (error) => {
              this.setState({ error });
            }
          )

      // this.setState((old)=>{
      //   return {itemShow:old.itemShow + 10}
      // })
  }

changePageTitle() {
  alert('hello')
    var newPageTitle = 'Mios.pk | New';
    document.title = newPageTitle;
}


changeCategory(e){

  // this.setState({changeCategory:e.target.value},function(){
  //   localStorage.setItem('categoryName',this.state.changeCategory)

  // })
  this.setState({products:[],backupproducts:[],totalProducts:0});
  const apiUrl = this.state.base_url+'products_api/getProducts/all/all/all/'+e.target.value;
  var api_Data = {user_id:sessionStorage.getItem('userData')};
   fetch(apiUrl,{
      method: 'POST',
      headers:
      {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(api_Data)
    })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          products: result.products,
          backupproducts:result.products,
          loading:false,
          totalProducts:result.total,
        });
      },
      (error) => {
        this.setState({ error });
      }
    )



}
handleChange(e)
{
  let subId = e.target.value;
  if(subId == undefined)
  {
    this.setState({products:this.state.backupproducts},function(){})
  }
  if(subId != undefined){
    const suburl = this.state.base_url+`products_api/GetSubCategories?subId=${subId}`;
      fetch(suburl)
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
             subcategories: result
            });
          },
          (error) => {
            this.setState({ error });
          }
        )
    }
    if(e.target.value == undefined)
    {
      this.setState({products:this.state.backupproducts},function(){})
    }
    else
    {
        if(e.target.value == '0')
        {
          this.setState({
            products:this.state.backupproducts,
            subcategories:this.state.backupSubcategories
          },function(){

          })
        }else{
          const filterurl = this.state.base_url+`products_api/getFilteredProducts?filterId=${e.target.value}`;
          fetch(filterurl)
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                productsFilters: result
              });
             var filter = this.state.productsFilters
             this.setState({products:filter},function(){})


            },
            (error) => {
              this.setState({ error });
            }
          )
      }
    }
}


category_click = (e,sub_category_id) =>
{
  this.setState({products:[],backupproducts:[],totalProducts:0});
  const apiUrl = this.state.base_url+'products_api/getProductsAccordingToSubCategory/10/'+sub_category_id;
  fetch(apiUrl)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          products: result.products,
          backupproducts:result.products,
          loading:false,
          totalProducts:result.total,
        });
      },
      (error) => {
        this.setState({ error });
      }
    )
}
brand_click = (e,brand_id) =>
{
  this.setState({products:[],backupproducts:[],totalProducts:0});
  const apiUrl = this.state.base_url+'products_api/getProducts/all/all/'+brand_id;
  var api_Data = {user_id:sessionStorage.getItem('userData')};
  fetch(apiUrl,{
      method: 'POST',
      headers:
      {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(api_Data)
    })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          products: result.products,
          backupproducts:result.products,
          loading:false,
          totalProducts:result.total,
        });
      },
      (error) => {
        this.setState({ error });
      }
    )
}
getByAllCategory = (e) =>
{
  this.state.products=[];
  this.state.backupproducts=[];
  const apiUrl = this.state.base_url+'products_api/getProducts/10/all/';
  var api_Data = {user_id:sessionStorage.getItem('userData')};
  fetch(apiUrl,{
      method: 'POST',
      headers:
      {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(api_Data)
    })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          products: result.products,
          backupproducts:result.products,
          loading:false,
          totalProducts:result.total,
        });
      },
      (error) => {
        this.setState({ error });
      }
    )

  this.getSubCategories(e,'all');
}
getFlashSale = (e) =>
{
  this.state.products=[];
  this.state.backupproducts=[];
  const apiUrl = this.state.base_url+'products_api/getProducts/all/all/all/flash_sale';
  var api_Data = {user_id:sessionStorage.getItem('userData')};
  fetch(apiUrl,{
        method: 'POST',
        headers:
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body:JSON.stringify(api_Data)
      })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          products: result.products,
          backupproducts:result.products,
          loading:false,
          totalProducts:result.total,
        });
      },
      (error) => {
        this.setState({ error });
      }
    )
}
getByAllCategoryID = (e,cate_id) =>
{
  this.state.products=[];
  this.state.backupproducts=[];
  const apiUrl = this.state.base_url+'products_api/getProducts/10/'+cate_id;
  var api_Data = {user_id:sessionStorage.getItem('userData')};
   fetch(apiUrl,{
        method: 'POST',
        headers:
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body:JSON.stringify(api_Data)
      })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          products: result.products,
          backupproducts:result.products,
          loading:false,
          totalProducts:result.total,
        });
      },
      (error) => {
        this.setState({ error });
      }
    )
    this.getSubCategories(e,cate_id);
}
getSubCategories = (e,cate_id)=>
{
  const allSub = this.state.base_url+'products_api/GetAllSubCategories/'+cate_id;
    fetch(allSub)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
           subcategories: result,
           backupSubcategories:result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )
}
render() {

    const { error,products,  categories} = this.state;
    var tabview = '';
    if(this.state.categories !==''){
     tabview= <div className="row flash_categories">
              <div className="col-sm-12 padd_none">
                <div className="tabs medical_box">
                  <div className="row">
                    <div className="col-sm-12 tabs padd_none cata_sub_items">
                      <div className="cata-sub-slider">
                        <div className="nav-prev- arrow" style={{'display': 'none'}}>
                          <i className="fa fa-angle-left"></i>
                        </div>
                        <ul className="tabs">
                          {categories.map((product, index)=>(
                              <NavLink to={`/search-results/${product.id}/all/all`} key={index}>
                                  <li  className="tab-link"  style={{'cursor':'pointer'}}  value={product.id}  key={index}>
                                     {(
                                         product.icon_image ? <img src={product.icon_image} alt="category"  />
                                         :
                                         <img src={full6} alt="category"/>
                                      )}
                                      <b>{product.groupName}</b>
                                 </li>
                              </NavLink>
                            ))}
                        </ul>
                        <div className="nav-next- arrow">
                          <i className="fa fa-angle-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

    }

    if(error) {
      return (
        <div>Error: {error.message}</div>
      )
    } else {
      return(
    <div>
      <Container top={this.state.top}>{this.state.addtocart}</Container>
        <div className="container">
          {tabview || <Skeleton height={120} />}
          </div>
        </div>
      );
    }
  }
}
export default CategoryContent;
