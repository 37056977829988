import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import {api_url} from '../Config.js';
import '../assets/css/pirnt.css';
import logo from '../assets/images/logo.png';
import barcode from '../assets/images/barcode.png';
import $ from 'jquery';
import NumberFormat from 'react-number-format';
export default class orderDetail extends Component {
    constructor(props){
    super(props);
    this.state={
      prefix:'',
      suffix:'',
      customer : [],
      shipping : [],
      order:[],
      couriers:[],
      order_status:[],
      payment_method: [],
      order_products:[],
      history_statuses:[],
      dropship_orders:[],
      fulfillment:[],
      base_url:api_url.base_url,
      img_url:api_url.img_url,
      com_url:api_url.com_url,
      review:0,
      feedback:'',
      order_id:'',
      reviewErr:'',
      feedErr:'',
      resMsg:'',
      fulfillment_status:'',
      blurLoading:true,
      opacity:1,
      company_detail:[],
    };
this.validation = this.validation.bind(this)
this.deleteProductNow = this.deleteProductNow.bind(this)
this.plusProductNow = this.plusProductNow.bind(this)
this.minusProductNow = this.minusProductNow.bind(this)
this.callAllProducts = this.callAllProducts.bind(this)
  }
  componentDidMount()
  {

    this.callAllProducts();
      const prefixUrl = this.state.base_url+'products_api/GetPrefix';
    fetch(prefixUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            prefix: result
          }, function(){

          });

        },
        (error) => {
          this.setState({ error });
        }
      )
const suffixUrl = this.state.base_url+'products_api/GetSuffix';
    fetch(suffixUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result
          }, function(){

          });
        },
        (error) => {
          this.setState({ error });
        }
      )

      // rating
      $(document).ready(function(){

        $('#stars li').on('mouseover', function(){
          var onStar = parseInt($(this).data('value'), 10); // The star currently mouse on

          // Now highlight all the stars that's not after the current hovered star
          $(this).parent().children('li.star').each(function(e){
            if (e < onStar) {
              $(this).addClass('hover');
            }
            else {
              $(this).removeClass('hover');
            }
          });

        }).on('mouseout', function(){
          $(this).parent().children('li.star').each(function(e){
            $(this).removeClass('hover');
          });
        });


      $('#stars li').on('click', function(){
        var onStar = parseInt($(this).data('value'), 10); // The star currently selected
        var stars = $(this).parent().children('li.star');

        for (var i = 0; i < stars.length; i++) {
          $(stars[i]).removeClass('selected');
        }

        for (i = 0; i < onStar; i++) {
          $(stars[i]).addClass('selected');
        }

        // JUST RESPONSE (Not needed)
        var ratingValue = parseInt($('#stars li.selected').last().data('value'), 10);
        var msg = "";
        if (ratingValue > 1) {
            msg = "Thanks! You rated this " + ratingValue + " stars.";
        }
        else {
            msg = "We will improve ourselves. You rated this " + ratingValue + " stars.";
        }
        responseMessage(msg);

      });


    });
function responseMessage(msg) {
    $('.success-box').fadeIn(200);
    $('.success-box div.text-message').html("<span>" + msg + "</span>");
  }
}

 callAllProducts()
 {
   let filterid = this.props.match.params.orderId
    const filteredUrl = this.state.com_url+'moiz_api/getProductsById/'+filterid;
    fetch(filteredUrl)
      .then(res => res.json())
      .then(
        (result) => {
            console.log(result)
          this.setState({
            order:result.order,
            order_id:result.order.id,
            customer: result.customer,
            shipping: result.order_shipping,
            payment_method:result.payment_method,
            couriers: result.couriers,
            order_status:result.order_status,
            order_products:result.order_products,
            dropship_orders:result.dropship_orders,
            history_statuses:result.history_statuses,
            fulfillment_status:result.fulfillment_status,
            company_detail:result.company_detail[0],
          })
        },
        (error) => {
          this.setState({ error });
        }
      )
 }

   validation(){
      if(this.state.review == 0 && this.state.feedback=='')
      {
         this.setState(
            {
               reviewErr:'Please enter your review',
               feedErr: 'Please enter your feedback',
            }
         )
      }else if(this.state.review ==0)
      {
         this.setState(
            {
               reviewErr:'Please enter your review',
               feedErr: '',
            }
         )
      }else if(this.state.feedback == '')
      {
         this.setState(
            {
               reviewErr:'',
               feedErr: 'Please enter your feedback',
            }
         )
      }else{
         return true
      }
   }
 onchangeText(e){
  this.setState({feedback:e.target.value})
 }
 deleteProductNow(id,unit_price)
 {
   this.setState({opacity:0.1})
    const delUrl = this.state.com_url+'moiz_api/deleteProductOnEdit/'+id+'/'+this.state.order_id+'/'+unit_price+'/'+this.state.order.price_subtotal+'/'+this.state.order.price_total;
    console.log('id is'+id)
    console.log('order_id is'+this.state.order_id)
    console.log('unit_price is'+unit_price)
    console.log('price_subtotal is'+this.state.order.price_subtotal)
    console.log('price_total is'+this.state.order.price_total)
    fetch(delUrl)
      .then(res => res.json())
      .then(
        (result) => {
            this.callAllProducts();
            this.setState({opacity:1})
        },
        (error) => {
          this.setState({ error });
        }
      )
 }
 plusProductNow(id,unit_price,quantity,amount)
 {
   this.setState({opacity:0.1})
    const delUrl = this.state.com_url+'moiz_api/plusProductOnEdit/'+id+'/'+this.state.order_id+'/'+unit_price+'/'+quantity+'/'+amount+'/'+this.state.order.price_subtotal+'/'+this.state.order.price_total;
    console.log('id is'+id)
    console.log('order_id is'+this.state.order_id)
    console.log('unit_price is'+unit_price)
    console.log('quantity is'+quantity)
    console.log('amoutn is'+amount)
    console.log('price_subtotal is'+this.state.order.price_subtotal)
    console.log('price_total is'+this.state.order.price_total)
    fetch(delUrl)
      .then(res => res.json())
      .then(
        (result) => {
            this.callAllProducts()
            this.setState({opacity:1})
        },
        (error) => {
          this.setState({ error });
        }
      )
 }
 minusProductNow(id,unit_price,quantity,amount)
 {
   this.setState({opacity:0.1})
    const delUrl = this.state.com_url+'moiz_api/minusProductEdit/'+id+'/'+this.state.order_id+'/'+unit_price+'/'+quantity+'/'+amount+'/'+this.state.order.price_subtotal+'/'+this.state.order.price_total;
    console.log('id is'+id)
    console.log('order_id is'+this.state.order_id)
    console.log('unit_price is'+unit_price)
    console.log('quantity is'+quantity)
    console.log('amoutn is'+amount)
    console.log('price_subtotal is'+this.state.order.price_subtotal)
    console.log('price_total is'+this.state.order.price_total)
    fetch(delUrl)
      .then(res => res.json())
      .then(
        (result) => {
            this.callAllProducts()
            this.setState({opacity:1})
        },
        (error) => {
          this.setState({ error });
        }
      )
 }
 product_sku(id)
 {
    const skuRl = this.state.com_url+'moiz_api/getProductSku/'+id;
    fetch(skuRl)
      .then(res => res.json())
      .then(
        (result) => {
          console.log('result is ' + result)
            return result;
        },
        (error) => {
          this.setState({ error });
        }
      )
 }
  render() {
    const map_address = this.state.shipping.city + ',' + this.state.shipping.country
    const service_name = this.state.couriers.name

     const order_history = [];
      if(this.state.history_statuses.length > 0)
      {
        this.state.history_statuses.forEach((element, index) => {
          order_history.push(
              <tr key={index}>
                <td>{parseFloat(index) + parseFloat(1)}</td>
                <td>{element.status_name}</td>
                <td>{element.created_on}</td>
              </tr>
            )
          })
      }

        return (
            <div id="wrapper">
         <div class="invoice-wrapper-co">
            <div class="container_">
               <div class="main_row">
                  <div class="col_7-">
                     <div class="logo-set-er">
                        <img src={logo} alt="" />
                     </div>
                     <div class="bottom-bg-shape user_account" style={{background:'#125778 !important','-webkit-print-color-adjust': 'exact !important',   marginTop: '10px', position: 'relative',borderRadius: '0 62px 0px 0'}}>
                        <ul>
                           <li><a href="tel:+92 313 4882324"><i class="fa fa-phone"></i> {this.state.company_detail.mobile} </a></li>
                           <li><a href={`mailto:${this.state.company_detail.emailId}`}><i class="fa fa-envelope"></i>  {this.state.company_detail.emailId}</a></li>
                        </ul>
                     </div>
                  </div>
                  <div class="col_5-">
                     <div class="bg-right-set">
                        <div class="bg-right-inner">
                           <h1>Invoice</h1>
                           <ul>
                              <li><span style={{color: '#fff !important'}}>
                                 Invoice No
                                 </span> <b style={{color: '#fff !important'}}>:</b>
                                 {this.state.order_id}
                              </li>
                              <li><span style={{color: '#fff !important'}}>
                                 Invoice
                                 Date </span> <b style={{color: '#fff !important'}}>:</b>{this.state.order.created_on}
                              </li>
                              <li><span style={{color: '#fff !important'}}>Payment Method</span> <b style={{color: '#fff !important'}}>:</b> Cash</li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="main_row">
                  <div class="col-8- invoice-left-right padd-left- p-padd invoice-space">
                     <h6 style={{display: 'inlineBlock',    margin: '0 15px 0 0'}}>Invoice to: </h6>
                     <h1 style={{display: 'inlineBlock'}}>
                        Cash
                     </h1>
                     <ul>
                     </ul>
                  </div>
               </div>
               <div class="main_row padd-bottom">
                  <div class="col-12- invoice-table-">
                     <table>
                     <thead>
                        <tr>
                            <th>Sr.No</th>
                            <th>SKU</th>
                            <th>Product</th>
                            <th>Unit Price</th>
                            <th>Unit</th>
                            <th style={{width:'100px'}}>Quantity</th>
                            <th className="text-left">Amount</th>
                      </tr>
                    </thead>
                        <tbody>
                           {(this.state.order_products.length > 0)?
                        this.state.order_products.map((product,index)=>(
                          <tr key={index}>
                            <td>{parseFloat(index) + parseFloat(1)}</td>
                            <td>{product.barcode}</td>
                            <td className="close-product-items">
                            {product.product_name}
                            </td>
                            <td>
                            <NumberFormat value={product.unit_price} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} />
                            </td>
                            <td>{product.UOMName}</td>
                            <td>
                              {product.quantity}
                            </td>
                            <td><NumberFormat value={product.product_total} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>

                          </tr>
                          ))
                      :''
                    }


                        </tbody>
                     </table>
                  </div>
               </div>
               <div class="main_row padd-top-o">
                  <div class="col_7- invoice-left-right p-padd">


                  </div>
                  <div class="col_5- invoice-left-right last-table-ee p-padd">
                     <ul>
                        <li><span class="span_width">SubTotal</span> <b>:</b><NumberFormat value={this.state.order.price_subtotal} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></li>
                        <li><span class="span_width">Shipping</span> <b>:</b><NumberFormat value={(this.state.order.shipping_cost) ? this.state.order.shipping_cost :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></li>

                        <li><span class="span_width"> Discount</span> <b>:</b><NumberFormat value={(this.state.order.discount) ? this.state.order.discount :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></li>

                        <li><span class="span_width"> Extra</span> <b>:</b><NumberFormat value={(this.state.order.tax) ? this.state.order.tax :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></li>

                        <li><span class="span_width"> Tax</span> <b>:</b><NumberFormat value={(this.state.order.extra) ? this.state.order.extra :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></li>
                     </ul>
                     <div class="invoice-left-right total-amount">
                        <ul>
                           <li><span class="span_width">Total Amount</span> <b style={{color: '#fff !important'}}>:</b><NumberFormat value={(this.state.order.price_total) ? this.state.order.price_total :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="main_row new-top_set">
                  <div class="col_9- invoice-left-right p ">
                     <h6 class="padd-left">{this.state.company_detail.invoice_footer_text}</h6>
                     <div class="footer-shape">
                        <div class="footer-shape-inner">
                           <h3>{this.state.company_detail.companyName}</h3>
                           <i style={{color: '#fff !important','-webkit-print-color-adjust': 'exact !important'}} class="fa fa-map-marker"></i> {this.state.company_detail.address}
                           <p class="top-padddd" style={{textAlign: 'left',margin: '16px 0 0'}}>Software By: IT Vision (Pvt.) Ltd.|+923324510131 |www.itvsion.com.pk| www.eloerp.com| info@eloerp.com</p>
                        </div>
                     </div>
                  </div>
                  <div class=" signature">
                     <p> </p>
                     <span class="border-center"></span>
                     <h5>Admin Admin</h5>
                  </div>
               </div>
            </div>
         </div>
      </div>
        );
    }
}
