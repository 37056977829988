import React, {Component} from 'react'; 
import {Redirect} from 'react-router-dom';
import {PostData} from './PostData';
import {NavLink} from 'react-router-dom';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';
import swal from 'sweetalert';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {api_url} from '../../Config.js';
class ForgetPassword extends Component {
   constructor(){
      super();
      this.state = {
         email: '', 
         redirectToReferrer: false,
         wrongErr:'',
         message:'',
         emailErr:'', 
         loading:false,
         base_url:api_url.base_url,
         color:'red'
      };
      this.resetPassword = this.resetPassword.bind(this);
      this.onChange = this.onChange.bind(this);
      this.validation = this.validation.bind(this);
   }
   resetPassword() {
     if(this.validation())
      {
         this.setState({emailErr:'', wrongErr:'',loading:true})
        const forgetURL = this.state.base_url+'products_api/forgetPassword';
        fetch(forgetURL,
        {
            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({'email':this.state.email})
        }).then(res=>res.json())
        .then(result=>{  
            if (result.error===0) {
                this.setState({color:'green'});  
            }
            this.setState({loading:false , message:result.message})
        })
        .catch((error) => {
			this.setState({wrongErr:error,loading:false },function(){
            })
		});

      }
   }

   validation(){
      if(this.state.email === '' )
      {
         this.setState(
            {
               emailErr:'Please enter your email', 
            }
         )
      }else if(this.state.email ==='')
      {
         this.setState(
            {
               emailErr:'Please enter your email',
               passErr: '',
            }
         )
      }else{
         return true
      }
   }



onChange(e){
   this.setState({[e.target.name]:e.target.value});
}
render() {
   const {loading} = this.state;
if (this.state.redirectToReferrer) {
    return (<Redirect to={'/login'}/>);
}
return (
   <div>
   <Header />
      <div className="form_wrap">
            <div className="container">
               <div className="row middle_form" id="sign_up_bg">
                  <div className="col-sm-8 sign_up_left">
                     <div className="social_media">
                        <h3>Reset Password</h3> 
                     </div>
                        <span style={{color: this.state.color}}>{this.state.emailErr}</span>
                        <div>
                        <input type="email" name="email"  placeholder="Email" onChange={this.onChange} /> 
                       <span style={{color: this.state.color}}>{this.state.message}</span>
                        <div className="login_form">
                        {
                            this.state.color==='red' ?
                            <button
                                className="button-loadings"
                                style={{ fontSize: "18px"}}
                                type="submit"
                                onClick={this.resetPassword}
                                disabled={loading}
                                >
                                {loading && (
                                    <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                    />
                                )}
                                {loading && <span>Loading...</span>}
                                {!loading && <span>Reset Password</span>}
                            </button>
                            :
                            <NavLink to="/login"><button>Login</button></NavLink>
                        }
                         
                        </div>
                        </div>
                  </div>
                  <div className="col-sm-4 login_right_bg">
                    <div className="details_info">
                        <h3>Welcome, Back!</h3>
                        <p><span>To keep connected with us please </span> login with your personal info</p>
                        <NavLink to="/login">Sign In</NavLink>
                    </div>
                  </div>
               </div>
            </div>
         </div>
      <Footer />
   </div>
);
}
}
export default ForgetPassword;
