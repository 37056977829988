import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import Header  from "./Header/Header";
import Footer  from "./Footer/Footer";
import {Redirect} from 'react-router-dom';
import {api_url} from '../Config.js';
import {PostData} from '../services/ProfileData.js';
import {UpdatePass} from '../services/UpdatePass.js';
import UserSidebar from './UserSidebar';
import $ from 'jquery';
import swal from 'sweetalert';
import styled from 'styled-components';
const Container = styled.div`
  background-color:#444;
  color:white;
  padding:16px;
  position:fixed;
  top:${props=> props.top}px;
  right:16px;
  z-index:999;
  transition: top 0.9s ease;
`;
 class Profile extends Component {
constructor(props) {
super(props);
this.state = {
data:[],
redirectToReferrer: false,
user_id:localStorage.getItem('userData'),
base_url:api_url.base_url,
firstname:'',
lastname:'',
email:'',
zip:'',
address1:'',
address2:'',
phone:'',
city:'',
state:'',
country:'',
getUsers:'',
top:'-200',
oldpassword:'',
newpassword:'',
confpassword:'',
passErr:'',
};
this.updateDetails = this.updateDetails.bind(this)
}
componentDidMount(){
   const userId = localStorage.getItem('userData');
    
   const getDetails = this.state.base_url+`products_api/getUserDetails?userId=${userId}`;

   fetch(getDetails)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            userDetails: result

          }, function(){
            
          });
          
        },
        (error) => {
          this.setState({ error });
        }
      )
  
}
componentWillMount() {
if(localStorage.getItem("userData")){
this.getUserData();
}
else{
this.setState({redirectToReferrer: true});
}
}
getUserData() {
JSON.parse(localStorage.getItem("userData"));

}

updatePassword(){
  if(this.state.newpassword === this.state.confpassword){
  UpdatePass(this.state)
   
      .then(
        (result) => {
          this.setState({
            successMsg:result,
            
          },function(){
      this.setState({
      top:5,
      successMsg:this.state.successMsg,
        oldpassword:'',
        newpassword:'',
        confpassword:'',
        },function(){
          setTimeout(()=>{
            this.setState({
              top: -800,
            })
          },4500)
        })

          });
          console.log(this.state.successMsg)
        },
        (error) => {
          this.setState({ error });
        }
      )
  }else{
    
    this.setState({passErr:"Confirm Password should match with New Password"})
  }
}



updateDetails(){
  var firstname = $('#firstname').val();
     var lastname = $('#lastname').val();
  var email = $('#email').val();
  var address1 = $('#address').val();
  var zip = $('#zip').val();
  var phone = $('#phone').val();
  var city = $('#city').val();
  var country = $('#country').val();
    this.setState({
            firstname:firstname,
            lastname:lastname,
            email:email,
            address1:address1,
            zip:zip,
            phone:phone,
            town:city,
            country:country
    },function(){
      this.setState({
      top:5,
      successMsg:'Data Updated Successfully',
    },function(){
      setTimeout(()=>{
        this.setState({
          top: -800,
        })
      },1500)
    })

      PostData(this.state)
      .then(
        (result) => {
          this.setState({
            getUsers: result,
          });
          
        },
        (error) => {
          this.setState({ error });
        }
      )
    })
  
}
render() {
if (this.state.redirectToReferrer) {
return (<Redirect to={'/login'}/>)
}
  return (
      <div>
      <Header />
         <div className="user_profile_wrap">
            <div className="container">
              <div className="row">
                {(
                  localStorage.getItem('success') ? 
                  <div className="col-sm-12">
                    <div className="alert alert-success">
                      {localStorage.getItem('success')}
                    </div>
                  </div>
                  :""
                )}
              </div>
               <div className="row">
                  <div className="col-sm-2 user_profile_left">
                     <h3>Orders</h3>
                     <UserSidebar active="profile" />
                  </div>
                  <div className="col-sm-10 accont_right_layout">
                     <h3>Profile</h3>
                     <div className="col-sm-12" style={{'padding':'0px'}}>
                        <div className="profile_info">
                          <h4>Change Your Profile Information</h4>
                            
                               <div className="user_details">
                                 <div className="row">
                                   <div className="col-sm-12">
                                     
                                     <input type="text" id="firstname" placeholder="First Name" defaultValue={(this.state.userDetails!= null)?this.state.userDetails.first_name : ''} name="firstname" onChange={(e)=>this.setState({firstname:e.target.value})} />
                                   </div>
                                   <div className="col-sm-12">
                                  
                                     <input type="text" id="lastname" placeholder="Last Name" defaultValue={(this.state.userDetails!= null)?this.state.userDetails.last_name : ''}  name="lastname" onChange={(e)=>this.setState({lastname:e.target.value})} />
                                   </div>
                                 </div>
                                 <div className="row">
                                   <div className="col-sm-6">
                                     
                                     <input type="email" id="email" placeholder="Email" defaultValue={(this.state.userDetails != null)?this.state.userDetails.email : ''}  name="email" onChange={(e)=>this.setState({email:e.target.value})} />
                                   </div>
                                   <div className="col-sm-6">
                                     <input type="text" id="zip" placeholder="Zip" defaultValue={(this.state.userDetails != null)?this.state.userDetails.tin : ''}  name="zip" onChange={(e)=>this.setState({zip:e.target.value})} />
                                   </div>
                                 </div>
                                 <div className="row">
                                   <div className="col-sm-6">
                                     <input type="text" id="address" placeholder="address1" defaultValue={(this.state.userDetails != null)?this.state.userDetails.address : ''}  name="address1" onChange={(e)=>this.setState({address1:e.target.value})} />
                                   </div>
                                   <div className="col-sm-6">
                                     <input type="text" placeholder="address2" defaultValue={(this.state.userDetails != null)?this.state.userDetails.mobileNo2 : ''}  name="address2" onChange={(e)=>this.setState({address2:e.target.value})} />
                                   </div>
                                 </div>
                                 <div className="row">
                                   <div className="col-sm-6">
                                     <input type="text" id="phone" placeholder="Phone" defaultValue={(this.state.userDetails != null)?this.state.userDetails.phone : ''}  name="phone" onChange={(e)=>this.setState({phone:e.target.value})} />
                                   </div>
                                   <div className="col-sm-6">
                                     <input type="text" id="city" placeholder="City" defaultValue={(this.state.userDetails != null)?this.state.userDetails.city : ''}  name="city" onChange={(e)=>this.setState({city:e.target.value})} />
                                   </div>
                                   
                                 </div>
                                 <div className="row">
                                   <div className="col-sm-6">
                                     <input type="text" placeholder="State" defaultValue={(this.state.userDetails != null)?this.state.userDetails.cst : ''}  name="state" onChange={(e)=>this.setState({state:e.target.value})} />
                                   </div>
                                   <div className="col-sm-6">
                                     <input type="text" id="country" placeholder="Country" defaultValue={(this.state.userDetails != null)?this.state.userDetails.pan : ''}  name="country" onChange={(e)=>this.setState({country:e.target.value})} />
                                   </div>
                                 </div>
                                 
                                 
                                 <div className="row">
                                   <div className="col-sm-3">
                                     <button onClick={this.updateDetails}>Update</button>
                                   </div>
                                 </div>
                                  
                               </div>
                            
                        </div>
                     </div>

                     <div className="col-sm-12 " style={{'padding':'0px'}}>
                        <div className="profile_info">
                          <h4>Change Your Password</h4>
                     
                           <div className="row user_details">
                                <div className="col-sm-12">
                                  <input type="password" placeholder="Old Password" value={this.state.oldpassword} onChange={(e)=>this.setState({oldpassword:e.target.value})} />
                                </div>
                                <div className="col-sm-12">
                                  <input type="password" placeholder="New Password" value={this.state.newpassword} onChange={(e)=>this.setState({newpassword:e.target.value})} />
                                </div>
                                <div className="col-sm-12">
                                  <input type="password" placeholder="Confirm New Password" value={this.state.confpassword}  onChange={(e)=>this.setState({confpassword:e.target.value})} />
                                </div>
                                <div className="col-sm-12">
                                <span style={{'color':'red'}}>{this.state.passErr}</span>
                                  
                                </div>
                                <div className="col-sm-3">
                                  <button onClick={()=>this.updatePassword()}>Update</button>
                                </div>
                           </div>
                      
                        </div>
                         
                     </div>
                     
                  </div>
               </div>
            </div>
         </div>
      <Footer />
    </div>
    );
  }
}

export default Profile;