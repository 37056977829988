import React, { Component } from 'react';
import dealimg from '../assets/images/deal-103.jpg';
import loading from '../assets/images/loading.gif';
import {NavLink} from 'react-router-dom';
import Header  from "./Header/Header";
import Footer  from "./Footer/Footer";
import {Redirect} from 'react-router-dom';
import {api_url} from '../Config.js';
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import UserSidebar from './UserSidebar';
import DatePicker from "react-datepicker";
import $ from 'jquery';
import "react-datepicker/dist/react-datepicker.css";
class SellerComission extends Component {
	constructor(props) {
		super(props);
		this.state = { 
	        data: [],
	        mainData: [],
			redirectToReferrer: false,
			base_url:api_url.base_url,
	  		img_url:api_url.img_url,
	  		com_url:api_url.com_url,
	  		current_user:localStorage.getItem('userData'),
	  		loading:false,
	  		type:'all',
	  		start_date:'',
	  		end_date:'',
	  		total_profit:0
		};
		this.searchComissionHandler = this.searchComissionHandler.bind(this);
		this.startDateHandler = this.startDateHandler.bind(this);
		this.endDateHandler = this.endDateHandler.bind(this);
		this.getCustomerProfit = this.getCustomerProfit.bind(this);
		this.getCustomerProfitMain = this.getCustomerProfitMain.bind(this);
		this.viewSellerInvoice = this.viewSellerInvoice.bind(this);
	}
	componentDidMount(){ 
		const date = new Date();
	    var d = date.getDate();
	    var m = date.getMonth();
	    var y = date.getFullYear();
	    var month = m+parseFloat(1);
	    var formatedDate = y+'-'+month+'-'+d;
	    const start_date = formatedDate;
	    const end_date = formatedDate; 
		this.setState({
			start_date:start_date,
			end_date:end_date
		});
		if(localStorage.getItem('success'))
		{
			localStorage.removeItem('success');
		}
		// this.getCustomerProfit();
		this.getCustomerProfitMain();
	}
	viewSellerInvoice(id)
	{
		const caturls = this.state.com_url+'moiz_api/getSellerOrders/'+id;
		this.setState({loading:true})
		fetch(caturls,
	           {
	            method: 'POST',
	            headers:
	            {
	                'Accept': 'application/json',
	                'Content-Type': 'application/json',
	            },
	            body:JSON.stringify()
	        })
	      .then(res => res.json())
	      .then(
	        (result) => {
	        	$('.main_comission_table').addClass('display_none')
	        	$('.detail_comission_table').removeClass('display_none')
	          this.setState({
	          	data:result.data,
	          	loading:false,
	          	total_profit: result.comsission
	          }); 
	        },
	        (error) => {
	          this.setState({ error });
	        }
	      )
	}
	componentWillMount() {
	if(localStorage.getItem("userData")){
			this.getUserData(this.state.type);
		}
		else
		{
			this.setState({redirectToReferrer: true});
		}
	}
	changeType(type){ 
		this.setState({type:type},()=>{

			this.receivedData(this.state.type);
		});
	}
	getUserData() {
		JSON.parse(localStorage.getItem("userData"));
	}
 	getCustomerProfitMain(){
	let sendData = {
		start:this.state.start_date,
		end:this.state.end_date
	};
	const caturl = this.state.com_url+'moiz_api/getSellerOrdersMain/'+this.state.current_user;
	this.setState({loading:true})
	fetch(caturl,
           {
            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(sendData)
        })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
          	mainData:result.data,
          	loading:false,
          	total_profit: result.comsission
          }); 
        },
        (error) => {
          this.setState({ error });
        }
      )
}

getCustomerProfit(){
	let sendData = {
		start:this.state.start_date,
		end:this.state.end_date
	};
	const caturl = this.state.com_url+'moiz_api/getSellerOrders/'+this.state.current_user;
	this.setState({loading:true})
	fetch(caturl,
           {
            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(sendData)
        })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
          	data:result.data,
          	loading:false,
          	total_profit: result.comsission
          }); 
        },
        (error) => {
          this.setState({ error });
        }
      )
}
startDateHandler = (date) =>{
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    var month = m+parseFloat(1);
    var formatedDate = y+'-'+month+'-'+d;
    this.setState({start_date:formatedDate})
  }
  endDateHandler = (date) =>{
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    var month = m+parseFloat(1);
    var formatedDate = y+'-'+month+'-'+d;
    this.setState({end_date:formatedDate})
  }
searchComissionHandler()
{
	let sendData = {
		start_date:this.state.start_date,
		end_date:this.state.end_date
	};
	console.log(sendData);
}

render() {

	

	return (
			<div>
			<Header />
			   <div className="user_profile_wrap">
			      <div className="container">
			      	
			         <div className="row">
			            <div className="col-sm-2 user_profile_left">
			               <h3 >Seller Comission</h3>
			               <UserSidebar active="seller_comission" />
			            </div>
			            <div className="col-sm-10 accont_right_layout">
			            	<div className="row">
			            		<div className="col-sm-2 sidegapp order_dropbox">
					               <h3 >Seller Comission</h3>
			            		</div>
			            	</div>


			            	<div className="form-wrapper-trad main_comission_table">
			                  <div className="row">
			                     <div className="col-lg-12 padding-set-left">
			                        	{(this.state.loading) ?
						                  <div style={{textAlign:'center'}}>
										  <img src={loading} /></div>
										:
						                  <div className="table-responsive seller_comission_box">
						                  <DatePicker
				                               value = {this.state.start_date}
				                                onChange={(e)=>this.startDateHandler(e)}
				                              />
				                              <DatePicker
				                               value = {this.state.end_date}
				                                onChange={(e)=>this.endDateHandler(e)}
				                              /> 
						                  <input type='submit' value="Search" onClick={this.getCustomerProfitMain} />
						                  <table id="customers">

				                        <thead>
				                           <tr>
				                              <th rowSpan="1" colSpan="1">SrNo</th>
				                              <th rowSpan="1" colSpan="1">Payment Date</th>
				                              <th rowSpan="1" colSpan="1">Total Products</th> 
				                              <th rowSpan="1" colSpan="1">Seller Comission</th>
				                              <th rowSpan="1" colSpan="1">Total Payable</th>
				                              <th rowSpan="1" colSpan="1">Total Payment</th> 
				                              <th rowSpan="1" colSpan="1">Balance</th>
				                              <th rowSpan="1" colSpan="1">Action</th>
				                           </tr>
				                        </thead>
				                        <tbody>
				                        	{this.state.mainData.length>0?this.state.mainData.map((item,key) =>
							                    <tr className="fixed-table" key={item.id}>
							                  <td colSpan="1">
							                        <p>{key+parseFloat(1)}</p>
							                  </td>
							                  <td colSpan="1">
							                       <p>{item.created_at} </p>
							                  </td>
							                  
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.product_ids.split(",").length}</p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.total_comission}</p>
							                  </td> 
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.total_payable_amount}</p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.total_paid}</p>
							                  </td>
							                   
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.remaining_amount}</p>
							                  </td>

							                  <td colSpan="1" className="red-trash">
							                  	<p><button className="btn btn-default" onClick={()=>this.viewSellerInvoice(item.order_ids.split(",")[0])}>View Invoice</button></p>
							                  </td>

							                  
							               </tr>
							               ) :
				                        	<tr>
				                        	<td colSpan="12">No Record Found</td>
				                        	</tr>  
				                        }

				                         </tbody>
				                        {
				                        	this.state.total_profit > 0 && 
				                        	<tfoot>
				                         	<td colSpan="7" className="background-grey">
							                  	<p></p>
							                </td>

							                <td colSpan="1" className="background-grey">
							                  	<p>{this.state.total_profit}</p>
							                </td>
				                         </tfoot>
				                        }
				                         

			                        </table>
				                         
						               </div>
			                        }
			                     </div>
			                  </div>
			               </div>


			                
			               <div className="form-wrapper-trad detail_comission_table display_none">
			                  <div className="row">
			                     <div className="col-lg-12 padding-set-left">
			                        	{(this.state.loading) ?
						                  <div style={{textAlign:'center'}}>
										  <img src={loading} /></div>
										:
						                  <div className="table-responsive seller_comission_box">
						                  {/*<DatePicker
				                               value = {this.state.start_date}
				                                onChange={(e)=>this.startDateHandler(e)}
				                              />
				                              <DatePicker
				                               value = {this.state.end_date}
				                                onChange={(e)=>this.endDateHandler(e)}
				                              /> 
						                  <input type='submit' value="Search" onClick={this.getCustomerProfit} />*/}
						                  <table id="customers">

				                        <thead>
				                           <tr>
				                              <th rowSpan="1" colSpan="1">SrNo</th>
				                              <th rowSpan="1" colSpan="1">Order No</th>
				                              <th rowSpan="1" colSpan="1">Order Date</th> 
				                              <th rowSpan="1" colSpan="1">Product Name</th>
				                              <th rowSpan="1" colSpan="1">Unit Price</th>
				                              <th rowSpan="1" colSpan="1">Qty</th> 
				                              <th rowSpan="1" colSpan="1">Comission</th>
				                           </tr>
				                        </thead>
				                        <tbody>
				                        	{this.state.data.length>0?this.state.data.map((item,key) =>
							                    <tr className="fixed-table" key={item.id}>
							                  <td colSpan="1">
							                        <p>{key+parseFloat(1)}</p>
							                  </td>
							                  <td colSpan="1">
							                       <p>{item.order_id} </p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.created_at}</p>
							                  </td> 
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.product_name}</p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.unit_price}</p>
							                  </td>
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.quantity}</p>
							                  </td>
							                   
							                  <td colSpan="1" className="red-trash">
							                  	<p>{item.seller_comission}</p>
							                  </td>

							                  
							               </tr>
							               ) :
				                        	<tr>
				                        	<td colSpan="12">No Record Found</td>
				                        	</tr>  
				                        }

				                         </tbody>
				                        {
				                        	this.state.total_profit > 0 && 
				                        	<tfoot>
				                         	<td colSpan="6" className="background-grey">
							                  	<p></p>
							                </td>

							                <td colSpan="1" className="background-grey">
							                  	<p>{this.state.total_profit}</p>
							                </td>
				                         </tfoot>
				                        }
				                         

			                        </table>
				                         
						               </div>
			                        }
			                     </div>
			                  </div>
			               </div>
			            </div>
			         </div>
			      </div>
			   </div>
			<Footer />
		</div>
		);
	}
}

export default SellerComission;
