import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import $ from 'jquery';
import '../../assets/css/bootstrap.css';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/font-awesome.min.css';
import { NavLink } from 'react-router-dom';
import '../../assets/css/style.css';
import shape1 from '../../assets/images/Shape-1.png';
import { api_url } from '../../Config.js';
import { Favourite } from '../../services/Favourite.js';
import { Unfavourite } from '../../services/Unfavourite.js';
import { Myshop } from '../../services/Myshop.js';
import { RemoveMyShop } from '../../services/RemoveMyShop.js';
import { countMostViewsItem } from '../../services/countMostViewsItem.js';
import styled from 'styled-components';
import swal from 'sweetalert';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const Container = styled.div`
  background-color:#444;
  color:white;
  padding:8px;
  position:${window.location.hash === '#/' ? 'fixed' : 'fixed'};
  top:${props => props.top}vh;
  display:'none';
  margin-top:4px;
  font-size:12px;
  z-index:99999;


`;
class DynamicComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      img_url: api_url.img_url,
      base_url: api_url.base_url,
      fv_item: [],
      products: [],
      addtocart: '',
      top: -8000000,
      display: 'none',
      single_product: this.props.title,
      saleRate: (this.props.title.saleRate) ? this.props.title.saleRate : 0,
      discounted_price: (this.props.title.discounted_price) ? this.props.title.discounted_price : 0,
      out_of_stock: this.props.title.out_of_stock,
      cartCount: (localStorage.getItem('cartCount') ? parseInt(localStorage.getItem('cartCount')) : 0),
      calculation_rate: (this.props.title.discounted_price > 0) ? this.props.title.discounted_price : this.props.title.saleRate,
      unitsdetail: this.props.title.unitsdetail,
      unit_id: (this.props.title.unitsdetail[0]) ? this.props.title.unitsdetail[0].unit_id : '',
      unitName: (this.props.title.unitsdetail[0]) ? this.props.title.unitsdetail[0].unit_id : '',
    }
  }
  componentDidMount() {


    $(".heart").click(function () {
      $(this).toggleClass('fa-heart');
      $(this).toggleClass('fa-heart-o');
    });
    $(".cart").click(function () {
      $(this).toggleClass('fa-shopping-cart');
      $(this).toggleClass('fa-shopping-basket');
    });

  }
  addToFavoite(e, value) {
    // value.title.user_id=1;
    Favourite(value);
  }
  Unfavourite(e, value) {
    Unfavourite(value);
  }
  addtoMyshop(e, value) {
    Myshop(value);
  }
  RemoveMyshop(e, value) {
    RemoveMyShop(value);
  }
  countMostViewsItem = (e, product_id, unit_id) => {
    countMostViewsItem(product_id);
    const url = '/#/product-detail/' + product_id + '/' + unit_id;
    // window.location.href = '/#/product-detail/'+product_id+'/'+unit_id;
    // window.location.reload();
    window.open(url, '_blank');
  }
  getUnitDetail = (e, qty, out_of_stock, discounted_price, saleRate, unit_id, unitName, calculation_rate) => {
    this.setState({
      unit_id: unit_id,
      discounted_price: discounted_price,
      saleRate: saleRate,
      out_of_stock: out_of_stock,
      calculation_rate: (discounted_price > 0) ? discounted_price : saleRate,
      unitName: unitName,
      unit_id: unit_id,
      top: -8000,
    });
    if (out_of_stock == 0 && qty < 0) {
      this.setState({ out_of_stock: 1 });
    }
  }

  addToCart = (e, product) => {
    // console.log(this.props.updateCount)
    const oldCart = (localStorage.getItem('cartCount') > 0) ? localStorage.getItem('cartCount') : 0;
    const newCart = parseFloat(oldCart) + parseFloat(1);
    localStorage.setItem('cartCount', newCart)
    // alert(localStorage.getItem('cartCount'));
    this.props.setStateOfParent(newCart);

    localStorage.setItem('top', '5px');
    this.setState({
      top: 2,
      display: 'block'
    }, function () {
      setTimeout(() => {
        this.setState({
          top: -800,
          display: 'none'
        })
        localStorage.setItem('top', '-800px');
      }, 1000)

    })
    product.calculation_rate = this.state.calculation_rate;
    product.unit_name = this.state.unitName;
    product.unit_id = this.state.unit_id;
    product.itemQty = 1;
    var flag = 0;
    var products_array = [];
    products_array = localStorage.getItem('cartProducts') ? JSON.parse(localStorage.getItem('cartProducts')) : [];
    if (products_array != null) {
      for (var i in products_array) {
        var item = products_array[i];
        if (item.product_id === product.product_id && item.unit_id === product.unit_id) {
          flag = 1;
          item.itemQty += parseFloat(1);
          break;
        }
      }
      if (flag == 0) {
        products_array.push(product);
      }
    }
    else {
      products_array.push(product);
    }
    localStorage.setItem('cartProducts', JSON.stringify(products_array));
    this.setState({ addtocart: "Product Added to Cart!" });
    this.setState({ cartCount: products_array.length }, function () {
      localStorage.setItem('cartCount', products_array.length);

    });

  }



  render() {
    var stockCheck = '';
    var units = [];
    if (this.state.unitsdetail != undefined) {
      this.state.unitsdetail.forEach((item, index) => {
        units.push(
          <li key={index}><a className="nav-item active" onClick={e => this.getUnitDetail(e, item.qty, item.out_of_stock, item.discounted_price, item.saleRate, item.unit_id, item.UOMName, item.calculation_rate)}>{item.UOMName}</a></li>
        );
      });
    }
    if (this.props.title.out_of_stock == 0 && parseFloat(this.props.title.qty) < 0) {
      this.setState({ out_of_stock: 1 });
    }
    if (this.state.out_of_stock == 1) {
      stockCheck = '<h5>Out Of Stock</h5>';
    }
    // alert(this.state.out_of_stock);
    return (
      <div className="col-sm-3 side_gaps" key={this.props.value} >
        <Container className="fade-in" top={this.state.top}>{this.state.addtocart}</Container>

        <div className="inner_box_sec">
          {
            // <div className="variation_items">

            // <div className="cata-sub-nav">
            // {(
            //      this.state.unitsdetail!=undefined && this.state.unitsdetail.length > 4
            //     ?
            //       <div className="nav-prev arrow" style={{'display':'none'}} >
            //         <i className="fa fa-angle-left"></i>
            //       </div>
            //     :""
            //   )}
            //   <ul>
            //     {units}
            //   </ul>
            //   {(
            //      this.state.unitsdetail!=undefined && this.state.unitsdetail.length > 4
            //     ?
            //       <div className="nav-next arrow">
            //         <i className="fa fa-angle-right"></i>
            //       </div>
            //     :""
            //   )}
            // </div>
            // </div>

          }
          <div className="out_ofstock">
            <span dangerouslySetInnerHTML={{ __html: stockCheck }}></span>
          </div>
          <a onClick={e => this.countMostViewsItem(e, this.props.title.product_id, this.state.unit_id)}>
            <img src={this.state.img_url + `/${this.props.title.image_name}`} />

          </a>
          <a onClick={e => this.countMostViewsItem(e, this.props.title.product_id, this.state.unit_id)}>
            <h4>{this.props.title.product_name || <Skeleton />}</h4>
          </a>

          <div className="stars-inner">

            <span className="rating_price"></span>
            {(
              this.state.discounted_price > 0 ?
                <p>{this.props.title.prefix} {(this.state.saleRate)} {this.props.title.suffix}</p>
                : ""
            )}
            {(
              this.state.discounted_price > 0 ?
                <h3>{this.props.title.prefix} {(this.state.calculation_rate)} {this.props.title.suffix}
                  {(localStorage.getItem('userData')) ? <div className="slide_up_box"><span ><i onClick={e => this.addtoMyshop(e, this.props)} className={this.props.title.basket_class_name + 'cart'} ></i> <i className={this.props.title.hear_class_name + 'heart'} onClick={e => this.addToFavoite(e, this.props)}></i> </span></div> : ''}
                </h3>
                :
                <h3>{this.props.title.prefix} {(this.state.calculation_rate)} {this.props.title.suffix}
                  {(localStorage.getItem('userData')) ? <div className="slide_up_box"><span ><i onClick={e => this.addtoMyshop(e, this.props)} className={this.props.title.basket_class_name + ' cart'} ></i> <i className={this.props.title.hear_class_name + ' heart'} onClick={e => this.addToFavoite(e, this.props)}></i> </span></div> : ''}
                </h3>
            )}

            <div className="cart_btn">
              {(
                this.state.out_of_stock == 1 ?
                  <a className="cart_btn_a">Out Of Stock</a>
                  :
                  <a className="cart_btn_a" onClick={e => this.addToCart(e, this.state.single_product)} name={this.props.value} value={this.props.title.id}>Add to Cart</a>
              )}
            </div>
          </div>
        </div>
        {(
          this.props.title.discounted_price > 0 ?
            <div className="_2N1Tif">
              <div className="shopee-badge shopee-badge--fixed-width shopee-badge--promotion">
                <div className="shopee-badge--promotion__label-wrapper shopee-badge--promotion__label-wrapper--en">
                  <span className="percent">{parseFloat((100 - (this.props.title.discounted_price / this.props.title.saleRate) * 100)).toFixed(0)}%</span><span className="shopee-badge--promotion__label-wrapper__off-label shopee-badge--promotion__label-wrapper__off-label--en">off</span>
                </div>
              </div>
            </div>
            : ""
        )}
      </div>
    );
  }
}
export default DynamicComponent;
