import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import {api_url} from '../Config.js';
import Header  from "./Header/Header";
import Footer  from "./Footer/Footer";
import NumberFormat from 'react-number-format';
import {PostData} from '../services/PostDataReview.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
export default class orderDetail extends Component {
	constructor(props){
		super(props);
		this.state={
			prefix:'',
    	suffix:'',
			customer : [],
			shipping : [],
			order:[],
      dropship_order:[],
			couriers:[],
			order_status:[],
			payment_method: [],
			order_products:[],
			history_statuses:[],
      dropship_orders:[],
			fulfillment:[],
			base_url:api_url.base_url,
      img_url:api_url.img_url,
      com_url:api_url.com_url,
      review:0,
      feedback:'',
      order_id:'',
      reviewErr:'',
      feedErr:'',
      resMsg:'',
      fulfillment_status:'',
      blurLoading:true,
      opacity:1,
      post_review:0,
      current_user:localStorage.getItem('userData'),
		};
this.validation = this.validation.bind(this)
this.deleteProductNow = this.deleteProductNow.bind(this)
this.plusProductNow = this.plusProductNow.bind(this)
this.minusProductNow = this.minusProductNow.bind(this)
this.callAllProducts = this.callAllProducts.bind(this)
this.cancelOrderHandler = this.cancelOrderHandler.bind(this)
	}
  cancelOrderHandler(id){
    
    const cancelURL = this.state.base_url+'products_api/cancelOrder/'+id;
    fetch(cancelURL)
      .then(res => res.json())
      .then(
        (result) => {
          toast('Order is cancelled!!');
          this.setState({fulfillment_status:'cancelled'});
          this.callAllProducts();
        },
        (error) => {
          this.setState({ error });
        }
      )
  }
  componentDidUpdate(){
    $(document).ready(function(){

        $('#stars li').on('mouseover', function(){
          var onStar = parseInt($(this).data('value'), 10); // The star currently mouse on

          // Now highlight all the stars that's not after the current hovered star
          $(this).parent().children('li.star').each(function(e){
            if (e < onStar) {
              $(this).addClass('hover');
            }
            else {
              $(this).removeClass('hover');
            }
          });

        }).on('mouseout', function(){
          $(this).parent().children('li.star').each(function(e){
            $(this).removeClass('hover');
          });
        });


      $('#stars li').on('click', function(){
        var onStar = parseInt($(this).data('value'), 10); // The star currently selected
        var stars = $(this).parent().children('li.star');

        for (var i = 0; i < stars.length; i++) {
          $(stars[i]).removeClass('selected');
        }

        for (i = 0; i < onStar; i++) {
          $(stars[i]).addClass('selected');
        }

        // JUST RESPONSE (Not needed)
        var ratingValue = parseInt($('#stars li.selected').last().data('value'), 10);
        var msg = "";
        if (ratingValue > 1) {
            msg = "Thanks! You rated this " + ratingValue + " stars.";
        }
        else {
            msg = "We will improve ourselves. You rated this " + ratingValue + " stars.";
        }
        responseMessage(msg);

      });


    });
    function responseMessage(msg) {
      $('.success-box').fadeIn(200);
      $('.success-box div.text-message').html("<span>" + msg + "</span>");
    }
  }
	componentDidMount()
	{
		this.callAllProducts();
      const prefixUrl = this.state.base_url+'products_api/GetPrefix';
    fetch(prefixUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            prefix: result
          }, function(){

          });

        },
        (error) => {
          this.setState({ error });
        }
      )
const suffixUrl = this.state.base_url+'products_api/GetSuffix';
    fetch(suffixUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result
          }, function(){

          });
        },
        (error) => {
          this.setState({ error });
        }
      )

      // rating


}
  async helloworld(){
    await this.setState({review:1},function(){
      console.log(this.state.review)
    })
  }
  async helloworld_two(){
    await this.setState({review:2},function(){
      console.log(this.state.review)
    })
  }
  async helloworld_three(){
    await this.setState({review:3},function(){
      console.log(this.state.review)
    })
  }
  async helloworld_four(){
    await this.setState({review:4},function(){
      console.log(this.state.review)
    })
  }
  async helloworld_five(){
    await this.setState({review:5},function(){
      console.log(this.state.review)
    })
  }
 callAllProducts()
 {
   let filterid = this.props.match.params.orderId
    const filteredUrl = this.state.com_url+'moiz_api/getProductsById/'+filterid+'/'+this.state.current_user;
    fetch(filteredUrl)
      .then(res => res.json())
      .then(
        (result) => {
            console.log(result)
          this.setState({
            order:result.order,
            dropship_order:result.dropship_order,
            order_id:result.order.id,
            customer: result.customer,
            shipping: result.order_shipping,
            payment_method:result.payment_method,
            couriers: result.couriers,
            order_status:result.order_status,
            order_products:result.order_products,
            dropship_orders:result.dropship_orders,
            history_statuses:result.history_statuses,
            fulfillment_status:result.fulfillment_status,
            post_review:result.post_review,
          })
        },
        (error) => {
          this.setState({ error });
        }
      )
 }
  sendRating() {
     if(this.validation())
      {
          this.setState({reviewErr:'',feedErr:''})
              let apiUrl = this.state.base_url+'products_api/save_review';
            fetch(apiUrl,{
                  method: 'POST',
                  headers:
                  {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                  },
                  body:JSON.stringify(this.state)
                })
            .then(res => res.json())
            .then(
              (result) => {
                 swal('Thank You')
                 this.setState({resMsg:'Thank You',post_review:0});
                 
              },
              (error) => {
                this.setState({ error,loading:false });
              }
            )


      }
   }
   validation(){
      if(this.state.review == 0 && this.state.feedback=='')
      {
         this.setState(
            {
               reviewErr:'Please enter your review',
               feedErr: 'Please enter your feedback',
            }
         )
      }else if(this.state.review ==0)
      {
         this.setState(
            {
               reviewErr:'Please enter your review',
               feedErr: '',
            }
         )
      }else if(this.state.feedback == '')
      {
         this.setState(
            {
               reviewErr:'',
               feedErr: 'Please enter your feedback',
            }
         )
      }else{
         return true
      }
   }
 onchangeText(e){
  this.setState({feedback:e.target.value})
 }
 deleteProductNow(id,unit_price,product_id,quantity)
 {

      this.setState({opacity:0.1});
       const sendMinusData = {
              id:id,
              product_id:product_id,
              order_id:this.state.order_id,
              unit_price:unit_price,
              subtotal:this.state.order.price_subtotal,
              total:this.state.order.price_total,
              dropSubtotal:this.state.dropship_order.price_subtotal,
              dropTotal:this.state.dropship_order.price_total,
              qunatity:quantity
        };
    const delUrl = this.state.com_url+'moiz_api/deleteProductOnEdit';

    fetch(delUrl,{
                method: 'POST',
                headers:
                {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                },
                body:JSON.stringify(sendMinusData)
              })
          .then(res => res.json())
          .then(
            (result) => {
                this.callAllProducts()
                this.setState({opacity:1})
            },
            (error) => {
              this.setState({ error,loading:false });
            }
          )
 }
 plusProductNow(id,unit_price,quantity,amount,product_id)
 {
   this.setState({opacity:0.1});
       const sendMinusData = {
              id:id,
              product_id:product_id,
              order_id:this.state.order_id,
              unit_price:unit_price,
              qunatity:quantity,
              amount:amount,
              subtotal:this.state.order.price_subtotal,
              total:this.state.order.price_total,
              dropSubtotal:this.state.dropship_order.price_subtotal,
              dropTotal:this.state.dropship_order.price_total
        };
    const delUrl = this.state.com_url+'moiz_api/plusProductOnEdit';

    fetch(delUrl,{
                method: 'POST',
                headers:
                {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                },
                body:JSON.stringify(sendMinusData)
              })
          .then(res => res.json())
          .then(
            (result) => {
                this.callAllProducts()
                this.setState({opacity:1})
            },
            (error) => {
              this.setState({ error,loading:false });
            }
          )
 }
 minusProductNow(id,unit_price,quantity,amount,product_id)
 {
   this.setState({opacity:0.1})

          const sendMinusData = {
              id:id,
              product_id:product_id,
              order_id:this.state.order_id,
              unit_price:unit_price,
              qunatity:quantity,
              amount:amount,
              subtotal:this.state.order.price_subtotal,
              total:this.state.order.price_total,
              dropSubtotal:this.state.dropship_order.price_subtotal,
              dropTotal:this.state.dropship_order.price_total
          };
          const apiUrl = this.state.com_url+'moiz_api/minusProductEdit';
          fetch(apiUrl,{
                method: 'POST',
                headers:
                {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                },
                body:JSON.stringify(sendMinusData)
              })
          .then(res => res.json())
          .then(
            (result) => {
                this.callAllProducts()
                this.setState({opacity:1})
            },
            (error) => {
              this.setState({ error,loading:false });
            }
          )
 }
 product_sku(id)
 {
    const skuRl = this.state.com_url+'moiz_api/getProductSku/'+id;
    fetch(skuRl)
      .then(res => res.json())
      .then(
        (result) => {
          console.log('result is ' + result)
            return result;
        },
        (error) => {
          this.setState({ error });
        }
      )
 }
	render() {
		const map_address = this.state.shipping.city + ',' + this.state.shipping.country
		const service_name = this.state.couriers.name

		 const order_history = [];
      if(this.state.history_statuses.length > 0)
      {
        this.state.history_statuses.forEach((element, index) => {
          console.log(element.status_name +'this is the element');

          order_history.push(
              <tr key={index}>
                <td>{parseFloat(index) + parseFloat(1)}</td>
                <td>{element.status_name}</td>
                <td>{element.created_on}</td>
              </tr>
            )
          })
      }
		return (
			<div className="">
        <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
{/* Same as */}
<ToastContainer />
			<Header />
			<div className="container con_0" id="order_table_page" >
			<div className="order_list">
        <h1 className="order_type ml-0 mr-0"> Order Detail</h1>
    </div>
				<div className="row">
			      <div className="col-sm-4">
				      <span>

				      </span>
			     </div>
		    </div>
		    <div className="row">
		        <div className="col-sm-4 customer_details">
		          <div className="inner_details">
		            <h3>Customer Details  </h3>
		              <div className="details_info">
		              <p><b>Name :</b> {(this.state.customer.first_name) ? (this.state.customer.first_name + " " +this.state.customer.last_name) : ''}</p>
		              <p><b>Email :</b> {(this.state.customer.email) ? (this.state.customer.email) : ''} </p>
                  {(
                    this.state.customer.phone ?
		                 <p><b>Phone :</b> {this.state.customer.phone} </p>
                    :""
                  )}
		               <b>Shipping Details:</b>
		                    <p><b>Address : </b>
		                    {(this.state.shipping.address) ? (this.state.shipping.address) : ''}
		                    {/*{(this.state.shipping.street) ? (this.state.shipping.street) : ''},
		                    {(this.state.shipping.city) ? (this.state.shipping.city) : ''},<br />
		                    {(this.state.shipping.postal_code) ? (this.state.shipping.postal_code) : ''}*/}
		                    </p>
		                    <p><b>Phone:</b> {(this.state.shipping.phone) ? (this.state.shipping.phone) : ''}</p>
		                <b>Billing Details:</b>
		                    <p><b>Address : </b>
                        {(this.state.shipping.address) ? (this.state.shipping.address) : ''}
                        </p>
                        <p><b>Phone:</b> {(this.state.shipping.phone) ? (this.state.shipping.phone) : ''}</p>
		              </div>
		          </div>

		          <div className="inner_details">
		            <h3>Payment Details</h3>
		              <div className="details_info">
		                <p><b>Payment Method:</b> {(this.state.payment_method.name) ? (this.state.payment_method.name) : ''} </p>
		                 <p><b>Payment Status: </b>
		                {(this.state.order.is_paid=='1')?"Paid":"Awaiting Payment"}


		                </p>

		              </div>
		          </div>
		          <div className="inner_details">
              <h3>Delivery Details</h3>

                <div className="details_info">


                  <div id="delivery_info" style={{"display":'block'}}>
									<p><b>Service Name : </b>{service_name}</p>
									<p><b>Tracking Number : </b>{(this.state.order.shipping_tracking_number) ? this.state.order.shipping_tracking_number : 'Not available'}</p>
									<p  style={{'border':'none'}}><b>Tracking RUL : </b>{(this.state.order.shipping_tracking_url) ? this.state.order.shipping_tracking_url : 'Not available'}</p>

                  </div>
                </div>

          </div>
		        </div>
		        <div className="col-sm-8 order_table_list">
				        <div className="inner_details">
		                <h3>Order ID: {this.props.match.params.orderId} <NavLink target="_blank"  to={`/print-order/`+this.state.order.id} details={this.state}
		                 className="pull-right print_btn" title=" Print Invoice"><i className="fa fa-print" style={{"float": "right"}}></i></NavLink></h3>

		                <div className="details_info">
		                    <p><b>Placed On :</b> {(this.state.order.created_on) ? (this.state.order.created_on) : ''} </p>
		                    <p><b>Order Status : </b> {(this.state.order_status.status_name) ? (this.state.order_status.status_name) : 'Pending'} </p>
		                    <p><b>Special Instructions: </b> {(this.state.order.comments) ? (this.state.order.comments) : 'N/A'}  </p>
		                    	{(this.state.order.is_dropship == '1')?
		                    	<p><b>Dropship Reason : </b> {(this.state.order.dropship_comment) ? (this.state.order.dropship_comment) : 'N/A'} </p>
		                    	:''
		                    }

                        {
                          this.state.order_status.status_name==='Pending' &&
                          <p className='dropship_box' style={{textAlign:'left'}}>
                          <button onClick={()=>this.cancelOrderHandler(this.props.match.params.orderId)}>Cancel Order</button>
                        </p>
                        }
		                </div>
		            </div>
		            <p className="table_heading"><b>Order Products</b></p>
            <div className="table-responsive order_tables" style={{opacity:this.state.opacity}}>
                <table>
                    <thead>
                        <tr>
                            <th>Sr.No</th>
                            <th>SKU</th>
                            <th>Product</th>
                            <th>Unit Price</th>
                            <th>Unit</th>
                            <th style={{width:'100px'}}>Quantity</th>
                            <th className="text-left">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                    	{(this.state.order_products.length > 0)?
                    		this.state.order_products.map((product,index)=>(
                    			<tr key={index}>
                    				<td>{parseFloat(index) + parseFloat(1)}</td>
                    				<td>{product.barcode}</td>
                    				<td className="close-product-items">
                            {
                              this.state.order_status.status_name === 'Pending' &&
                              <span className="fa fa-close close_on_edit" onClick={()=>this.deleteProductNow(product.id,product.unit_price*product.quantity,product.product_id,product.quantity)}></span>
                            }

                            {product.product_name}
                            </td>
                    				<td>
                            <NumberFormat value={product.unit_price} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} />
                            </td>
                    				<td>{product.UOMName}</td>
                    				<td>

                            {
                              this.state.order_status.status_name ==='Pending' ?
                              <span className="control_on_edit"><i className="fa fa-minus" onClick={()=>this.minusProductNow(product.id,product.unit_price,product.quantity,product.product_total,product.product_id)}></i> <span>{product.quantity}</span><i className="fa fa-plus" onClick={()=>this.plusProductNow(product.id,product.unit_price,product.quantity,product.product_total,product.product_id)}></i> </span>
                              :
                              <span>{product.quantity}</span>
                            }
                            </td>
                    				<td><NumberFormat value={product.product_total} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>

                    			</tr>
                    			))
                    	:''
                    }

                        <tr>
                            <td colSpan="5"></td>
                            <td>
                              <div className="Customer_info">
                               <p><b>Subtotal</b></p>
                              </div>
                            </td>
                            <td> <NumberFormat value={this.state.order.price_subtotal} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>
                        </tr>
                        <tr>
                            <td colSpan="5"></td>
                            <td>
                              <div className="Customer_info">
                               <p><b>Shipping </b></p>
                              </div>
                            </td>
                            <td><NumberFormat value={(this.state.order.price_shipping) ? this.state.order.price_shipping :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>
                        </tr>
                        <tr>
                            <td colSpan="5"></td>
                            <td>
                              <div className="Customer_info">
                               <p><b>Discount</b></p>
                              </div>
                            </td>
                            <td><NumberFormat value={(this.state.order.discount_coupon_value) ? this.state.order.discount_coupon_value :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>
                        </tr>
                        <tr>
                            <td colSpan="5"></td>
                            <td>
                              <div className="Customer_info">
                               <p><b>Extra</b></p>
                              </div>
                            </td>
                            <td><NumberFormat value={(this.state.order.extra) ? this.state.order.extra :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>
                        </tr>
                        <tr>
                            <td colSpan="5"></td>
                            <td>
                              <div className="Customer_info">
                               <p><b>Tax</b></p>
                              </div>
                            </td>
                            <td><NumberFormat value={(this.state.order.tax) ? this.state.order.tax :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>
                        </tr>

                        <tr>
                            <td colSpan="5"></td>
                            <td>
                              <div className="Customer_info">
                               <p><b>Total</b></p>
                              </div>
                            </td>
                            <td><NumberFormat value={(this.state.order.price_total) ? this.state.order.price_total :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
           {(this.state.dropship_orders.length>0)?
            <div className="dropship_products">
             <p className="table_heading"><b>Dropship Products</b></p>
            <div className="table-responsive order_tables">
                <table>
                    <thead>
                        <tr>
                            <th>Sr.No</th>
                            <th>Product</th>
                            <th>Unit</th>
                            <th>SKU</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>

                            <th className="text-left">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.dropship_orders.map((product,index)=>(
                          <tr>
                            <td>{parseFloat(index) + parseFloat(1)}</td>
                            <td>{product.product_name}</td>
                            <td>{product.UOMName}</td>
                            <td>{product.barcode}</td>
                            <td>{product.quantity}</td>
                            <td><NumberFormat value={product.unit_price} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>
                            <td><NumberFormat value={product.product_total} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>

                          </tr>
                          ))

                    }

                        <tr>
                            <td colSpan="5"></td>
                            <td>
                              <div className="Customer_info">
                               <p><b>Subtotal</b></p>
                              </div>
                            </td>
                            <td> <NumberFormat value={this.state.dropship_order.price_subtotal} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>
                        </tr>
                        <tr>
                            <td colSpan="5"></td>
                            <td>
                              <div className="Customer_info">
                               <p><b>Shipping </b></p>
                              </div>
                            </td>
                            <td><NumberFormat value={(this.state.dropship_order.price_shipping) ? this.state.dropship_order.price_shipping :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>
                        </tr>
                        <tr>
                            <td colSpan="5"></td>
                            <td>
                              <div className="Customer_info">
                               <p><b>Discount</b></p>
                              </div>
                            </td>
                            <td><NumberFormat value={(this.state.dropship_order.discount_coupon_value) ? this.state.dropship_order.discount_coupon_value :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>
                        </tr>
                        <tr>
                            <td colSpan="5"></td>
                            <td>
                              <div className="Customer_info">
                               <p><b>Extra</b></p>
                              </div>
                            </td>
                            <td><NumberFormat value={(this.state.dropship_order.extra) ? this.state.dropship_order.extra :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>
                        </tr>
                        <tr>
                            <td colSpan="5"></td>
                            <td>
                              <div className="Customer_info">
                               <p><b>Tax</b></p>
                              </div>
                            </td>
                            <td><NumberFormat value={(this.state.dropship_order.tax) ? this.state.dropship_order.tax :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>
                        </tr>

                        <tr>
                            <td colSpan="5"></td>
                            <td>
                              <div className="Customer_info">
                               <p><b>Total</b></p>
                              </div>
                            </td>
                            <td><NumberFormat value={(this.state.dropship_order.price_total) ? this.state.dropship_order.price_total :'0'} displayType={'text'} thousandSeparator={true} prefix={this.state.prefix} suffix={this.state.suffix} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
           </div>
            : ''}
            <br />
							<div className="order_tables">
              <p className=" table-responsive table_heading"><b>Order History:</b></p>
                    <table>
                        <thead>
                            <tr>
                                <th style={{"width": "10%"}}>Sr.No</th>
                                <th>Status</th>
                                <th style={{"width": "25%"}}>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                            {order_history}
                        </tbody>
                    </table>
                </div>
                 <br />
                  {(this.state.post_review == 1) ?


                  <div className="rate_experience">
                  <h3>Rate your experience</h3>
                   <div className='rating-stars text-center'>
                      <ul id='stars'>
                        <li className='star' title='Poor' data-value='1' >
                          <i className='fa fa-star fa-fw' onClick={this.helloworld.bind(this)}></i>
                        </li>
                        <li className='star' title='Fair' data-value='2'>
                          <i className='fa fa-star fa-fw' onClick={this.helloworld_two.bind(this)}></i>
                        </li>
                        <li className='star' title='Good' data-value='3'>
                          <i className='fa fa-star fa-fw' onClick={this.helloworld_three.bind(this)}></i>
                        </li>
                        <li className='star' title='Excellent' data-value='4'>
                          <i className='fa fa-star fa-fw' onClick={this.helloworld_four.bind(this)}></i>
                        </li>
                        <li className='star' title='WOW!!!' data-value='5'>
                          <i className='fa fa-star fa-fw' onClick={this.helloworld_five.bind(this)}></i>
                        </li>
                      </ul>
                    </div>
                  <textarea type="text" placeholder="Add a comment to your rating" onChange={this.onchangeText.bind(this)} ></textarea>
                  <button type="button" onClick={this.sendRating.bind(this)}>Submit Rating</button>
                <span style={{color: "red"}}>{this.state.feedErr}</span>
                <span style={{color: "red"}}>{this.state.resMsg}</span>
                <span style={{color: "red"}}>{this.state.reviewErr}</span>
                </div>
                : ''}
                 <br />

		        </div>
        </div>
				</div>
			<Footer />
			</div>
		);
	}
}
