import React, { Component } from 'react';
import DComponent from './DynamicComponent.js';
import { NavLink } from 'react-router-dom';
import { api_url } from '../../Config.js';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import * as ReactBootStrap from 'react-bootstrap';
import LComponent from './LoadComponent.js';
import $ from 'jquery';
const Container = styled.div`
  background-color:#444;
  color:white;
  padding:16px;
  position:fixed;
  top:${props => props.top}px;
  right:16px;
  z-index:999;
  transition: top 0.9s ease;
`;
class FeaturedProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      products: [],
      backupproducts: [],
      cartProducts: [],
      totalProducts: 0,
      brands: [],
      itemShow: 10,
      top: -800,
      categories: [],
      productsFilters: [],
      filter: [],
      totalItems: "",
      loading: false,
      addtocart: '',
      itemquantity: 1,
      items: [],
      cartCount: (localStorage.getItem('cartCount') ? parseInt(localStorage.getItem('cartCount')) : 0),
      filterId: localStorage.getItem('filterId'),
      base_url: api_url.base_url,
      img_url: api_url.img_url,
      filterData: [],
      fillterCategory: '',
      filterOption: '',
      com_url: api_url.com_url,
      sale_enable: 1,
      sale_view: 1,
      settings: [],
      showItems: 15,
      loadbtn: false,
      error: '',
    }
    this.loadmore = this.loadmore.bind(this);
    this.viewAllProducts = this.viewAllProducts.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.setStateOfParent = this.setStateOfParent.bind(this);

  }


  async setStateOfParent(result) {

    this.props.setStateOfParent(result);
    await this.setState({
      cartCount: result
    }, () => {
      localStorage.setItem('cartCount', result);
      localStorage.setItem('cartCount', result);
    })
  }

  async componentDidMount() {
    console.log(localStorage.getItem('top'))
    const settingUrl = this.state.com_url + 'moiz_api/get_admin_settings';
    await fetch(settingUrl)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            sale_view: result[0].featured_products_view,
            sale_enable: result[0].featured_products_enable,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )




    var filterId = this.state.filterId;

    const apiUrl = this.state.base_url + 'products_api/getProducts/15/all/all/featuredproducts/1';

    this.setState({ products: [], backupproducts: [], totalProducts: 0 });
    var api_Data = { user_id: sessionStorage.getItem('userData') };
    await fetch(apiUrl, {
      method: 'POST',
      headers:
      {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(api_Data)
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            products: result.products,
            backupproducts: result.products,
            loading: false,
            totalProducts: result.total,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )
  }
  async viewAllProducts() {
    const apiUrl = this.state.base_url + 'products_api/getProducts/15';
    this.setState({ products: [], backupproducts: [], totalProducts: 0 });
    var api_Data = { user_id: sessionStorage.getItem('userData') };
    await fetch(apiUrl, {
      method: 'POST',
      headers:
      {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(api_Data)
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            products: result.products,
            backupproducts: result.products,
            loading: false,
            totalProducts: result.total,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )
  }
  loadMoreItems() {
    this.setState((prev) => {
      return { showItems: prev.showItems + 15 };
    });
  }

  async loadmore() {
    this.setState({ loadbtn: true })
    var length = parseInt(this.state.products.length) + 20;
    const apiUrl = this.state.base_url + 'products_api/getProducts/' + length + '/all/all/featuredproducts/1';
    // this.state.products=[];
    // this.state.backupproducts=[];
    var api_Data = { user_id: sessionStorage.getItem('userData') };
    await fetch(apiUrl, {
      method: 'POST',
      headers:
      {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(api_Data)
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            products: result.products,
            backupproducts: result.products,
            loadbtn: false,
            totalProducts: result.total,
          });
        },
        (error) => {
          this.setState({ error });
        }
      )

    // this.setState((old)=>{
    //   return {itemShow:old.itemShow + 10}
    // })
  }
  addToCart(e) {
    alert('hello world')
    this.setState((old) => {
      return { cartCount: old.cartCount + 1 }
    })
    const oldCart = (localStorage.getItem('cartCount') > 0) ? localStorage.getItem('cartCount') : 0;
    const newCart = parseFloat(oldCart) + parseFloat(1);
    localStorage.setItem('cartCount', newCart)
    // localStorage.clear();
    this.setState({
      top: 5,
    }, function () {
      setTimeout(() => {
        this.setState({
          top: -800,
        })
      }, 1500)
    })

    this.setState({ addtocart: "Product Added to Cart!" })
    var count = this.state.cartCount;
    count = parseInt(this.state.cartCount) + parseInt(1);
    this.setState({ cartCount: count }, function () { })

    localStorage.setItem('cartCount', count);
    var newproducts = JSON.parse(localStorage.getItem('session'))
    var products = this.state.products[e.target.name];
    var proid = products.id



    products['itemQty'] = '1';
    a = JSON.parse(localStorage.getItem('session')) || [];
    a.push(products);
    localStorage.setItem('session', JSON.stringify(a));






    for (var i = 0; i < newproducts.length; i++) {
      var newid = newproducts.map((key, index) => (key.id))
      var idd = newproducts[i]
      if (products.id == idd.id) {

        const updateditems = newproducts.filter(item => item.id !== idd.id);

        localStorage.setItem('session', JSON.stringify(updateditems));


        var changeproduct = newproducts[i]

        var qty = changeproduct.itemQty

        products['itemQty'] = parseInt(changeproduct.itemQty) + parseInt(1);
        var a = [];
        a = JSON.parse(localStorage.getItem('session')) || [];
        var length = a.length;
        a.push(products);
        localStorage.setItem('session', JSON.stringify(a));

      }
    }

  }
  search_all() {
    console.log('show all')
  }
  render() {
    const toppopup = this.props.top;
    const { error, products, loading, loadbtn } = this.state;
    if (this.state.sale_enable === 0) {
      return null;
    }
    if (this.state.products.length === 0 || this.state.error !== '') {
      return <LComponent />;
    }
    return (
      <div>
        <Container top={this.state.top}>{this.state.addtocart}</Container>
        <section className="tabs_blog new_add_products">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 new_arrivals">
                <section className="best_deal">

                  <div className="section-title">
                    <div className="bar"></div>
                  </div>
                  <div className="shadow_box">
                    <div className="top_category_tabs">

                      <div className="row">
                        <div className="withoutSlider">
                          {(
                            (this.state.sale_view == 1) ?
                              products.map((product, index) => {
                                if (product.is_homepage === '1') {
                                  return (
                                    <DComponent setStateOfParent={this.setStateOfParent} title={product} key={index} user_id={sessionStorage.getItem('userData')} value={index} />
                                  )

                                }

                              })
                              : ""
                          )}


                        </div>
                      </div>
                      <div className="read_more_btn">
                        {/* {this.state.totalProducts > this.state.products.length && this.state.products.length > 0 &&

                                <button
                                className="loadmore_products"
                                 style={{ fontSize: "18px"}}
                                 type='button'
                                 onClick={this.loadmore}
                                 disabled={loadbtn}
                                 >
                                 {loadbtn && (
                                   <i
                                     className="fa fa-refresh fa-spin"
                                     style={{ marginRight: "5px" }}
                                   />
                                 )}
                                 {loadbtn && <span>Loading...</span>}
                                 {!loadbtn && <span>Load More</span>}
                               </button>
                              } */}
                        <NavLink to='/search-results/all/all/featured-products' className="show_all_button">Show All</NavLink>
                      </div>
                      {(
                        (this.state.sale_view == 2) ?
                          <div className="shadow_box">

                            <div className="row">
                              <div className="MultiCarousel" data-items="1,2,3,4" dataslide="1" id="MultiCarousel" data-interval="1000">
                                <div className="MultiCarousel-inner">
                                  {products.map((product, index) => {
                                    if (product.is_homepage === '1') {
                                      return (
                                        <div className="item" key={index}>
                                          <DComponent setStateOfParent={this.setStateOfParent} title={product} key={index} value={index} onClick={() => { this.setState({ top: 5 }) }} />
                                        </div>
                                      )

                                    }

                                  })}
                                </div>

                                {(this.state.products.length > 4) &&
                                  <div className="ant-carousel-arrow-left leftLst"></div>
                                }
                                {(this.state.products.length > 4) &&
                                  <div className="ant-carousel-arrow-right rightLst"></div>
                                }

                              </div>
                            </div>
                          </div>
                          : ""
                      )}

                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default FeaturedProduct;
