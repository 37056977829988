import React, { Component } from 'react';
import image103 from '../assets/images/deal-103.jpg';
import loading from '../assets/images/loading.gif';
import {NavLink} from 'react-router-dom';
import Header  from "./Header/Header";
import Footer  from "./Footer/Footer";
import {Redirect} from 'react-router-dom';
import {api_url} from '../Config.js';
import UserSidebar from './UserSidebar';
class Feedback extends Component {
constructor(props) {
super(props);
this.state = {
data:[],
redirectToReferrer: false,
base_url:api_url.base_url,
img_url:api_url.img_url,
com_url:api_url.com_url,
loading:false,
feedback:[],
user_id:localStorage.getItem('userData'),
};
}
componentDidMount()
{
	const feedbackUrl = this.state.base_url+'products_api/getFeedback/'+this.state.user_id;
		this.setState({loading:true})
	     fetch(feedbackUrl)
	      .then(res => res.json())
	      .then(
	        (result) => {
	          this.setState({
	          	feedback:result.feedback,
	          	loading:false,
	          });
	          console.log(this.state.feedback)
	        },
	        (error) => {
	          this.setState({ error });
	        }
	      )
}
componentWillMount() {
if(localStorage.getItem("userData")){
this.getUserData();
}
else{
this.setState({redirectToReferrer: true});
}
}
getUserData() {
JSON.parse(localStorage.getItem("userData"));

}
render() {

	const feedback = this.state

		return (
			<div>
				<Header />
			   <div className="user_profile_wrap">
			      <div className="container">
			         <div className="row">
			            <div className="col-sm-2 user_profile_left">
			               <h3>Orders</h3>
			               <UserSidebar active="feedback" />
			            </div>
			            <div className="col-sm-10 accont_right_layout">
			               <h3>Manage Feedback</h3>
			               <ul className="light-ul">
			                  <li><i className="fa fa-light"></i> 0 Guidelines </li>
			                  <li>1. You can leave feedback for sellers within 30 days in "Orders Awaiting My Feedback".</li>
			                  <li>2. Feedback will be published when both you and the seller have left feedback,or at the end of the 30 days.</li>
			                  <li>3. <a href="#">Learn more about our Feedback Rating Policies </a></li>
			               </ul>
			               <div className="tabs_wrap table-responsive">
			                  <div className="row">
			                     <div className="col-lg-12 tab-section-">

			                        <div className="main_tab">
			                           <div className="tabs_wrap" id="home" style={{display: 'block'}}>
			                              <div className="row">
			                                 <div className="col-lg-6" >
			                                    <div className="form-ch-data form-search">
			                                       <label for="">Order Number : </label>
			                                       <input type="search" placeholder="Search" />
			                                       <button type="button">Search</button>
			                                    </div>
			                                 </div>
			                              </div>
			                              {
			                              	(this.state.loading)?

			                              	<div style={{textAlign:'center'}}>
						                 					<img src={loading} /></div>

			                              	:

			                              	(this.state.feedback.length > 0)?

			                              	this.state.feedback.map((feed,index)=>(
				                              <div className="table-responsive">
				                              	<table id="customers" className="customers--" key={index}>
				                                 <tr>
				                                    <th rowspan="1" colspan="2">Order Details </th>
				                                    <th rowspan="1" colspan="2">Feedback  </th>
				                                    <th rowspan="1" colspan="2">Feedback Status </th>
				                                    <th rowspan="1" colspan="1">Action</th>
				                                 </tr>

				                                 <tr className="fixed-table">
				                                    <td colspan="2">
				                                       <span>
				                                          <b>Order ID</b>
				                                          <h6>:</h6>
				                                          <p>{feed.order_id}</p>
				                                          <a href="#">

				                                          </a>
				                                       </span>
				                                    </td>
				                                    <td colspan="2">

				                                    </td>
				                                    <td colspan="2" className="red-trash">
				                                       <span>
				                                          <b className="order-rate">Order Closed </b>
				                                          <h6>:</h6>
				                                          <p>{feed.closing_date}</p>
				                                       </span>
				                                    </td>
				                                    <td colspan="1" className="red-trash">
				                                       <span> <i className="fa fa-trash"> </i> </span>
				                                    </td>
				                                 </tr>
				                                 <tr>
				                                    <td colspan="2" width="100px">
				                                       <ul className="fit-set-creen">
				                                          <li>
				                                             <a src={this.state.img_url+`${feed.image_name}`} />
				                                          </li>
				                                          <li>
				                                             <p>{feed.product_description}
				                                             </p>

				                                          </li>
				                                       </ul>
				                                    </td>
				                                    <td colspan="2" className="top-10 new-h4-">
				                                       <h4>Feedback I left</h4>
				                                       <span className="top-top">
				                                       {(feed.review == 1)?
				                                       	<div>
				                                       <i className="fa fa-star"></i>
				                                        <i className="fa fa-star-o"></i>
				                                         <i className="fa fa-star-o"></i>
				                                          <i className="fa fa-star-o"></i>
				                                           <i className="fa fa-star-o"></i>
				                                           </div>

				                                           :''

				                                       }
				                                       {(feed.review == 2)?
				                                       	<div>
				                                       <i className="fa fa-star"></i>
				                                        <i className="fa fa-star"></i>
				                                         <i className="fa fa-star-o"></i>
				                                          <i className="fa fa-star-o"></i>
				                                           <i className="fa fa-star-o"></i>
				                                           </div>

				                                           :''

				                                       }
				                                       {(feed.review == 3)?
				                                       	<div>
				                                       <i className="fa fa-star"></i>
				                                        <i className="fa fa-star"></i>
				                                         <i className="fa fa-star"></i>
				                                          <i className="fa fa-star-o"></i>
				                                           <i className="fa fa-star-o"></i>
				                                           </div>

				                                           :''

				                                       }
				                                       {(feed.review == 4)?
				                                       	<div>
				                                       <i className="fa fa-star"></i>
				                                        <i className="fa fa-star"></i>
				                                         <i className="fa fa-star"></i>
				                                          <i className="fa fa-star"></i>
				                                           <i className="fa fa-star-o"></i>
				                                           </div>

				                                           :''

				                                       }
				                                       {(feed.review == 5)?
				                                       <div>
				                                       <i className="fa fa-star"></i>
				                                        <i className="fa fa-star"></i>
				                                         <i className="fa fa-star"></i>
				                                          <i className="fa fa-star"></i>
				                                           <i className="fa fa-star"></i>
				                                           </div>

				                                           :''

				                                       }
				                                           </span>

				                                       <p>{feed.feedback}</p>
				                                    </td>
				                                    <td colspan="2" className="top-10 text-center">
				                                    </td>
				                                    <td colspan="1" className="top-10 text-center">
				                                    </td>
				                                 </tr>
				                              </table>
				                              </div>


			                              	))

			                              	: ""
			                              }
			                           </div>
			                           <div className="tabs_wrap" id="about">
			                              <div className="row">
			                                 <div className="col-lg-6">
			                                    <div className="form-ch-data form-search">
			                                       <label for="">Order Number : </label>
			                                       <input type="search" placeholder="Search" />
			                                       <button type="button">Search</button>
			                                    </div>
			                                 </div>
			                              </div>
			                              <table id="customers" className="customers--">
			                                 <tr>
			                                    <th rowspan="1" colspan="2">Order Details </th>
			                                    <th rowspan="1" colspan="2">Feedback  </th>
			                                    <th rowspan="1" colspan="2">Feedback Status </th>
			                                    <th rowspan="1" colspan="1">Action</th>
			                                 </tr>
			                                 <tr className="fixed-table">
			                                    <td colspan="2">
			                                       <span>
			                                          <b>Order ID</b>
			                                          <h6>:</h6>
			                                          <p>101376332334352</p>
			                                          <a href="#">
			                                             <h3>View Detail</h3>
			                                          </a>
			                                       </span>
			                                    </td>
			                                    <td colspan="2">
			                                       <span>
			                                          <b>Seller </b>
			                                          <h6>:</h6>
			                                          <p>lanxiu Li</p>
			                                       </span>
			                                    </td>
			                                    <td colspan="2" className="red-trash">
			                                       <span>
			                                          <b className="order-rate">Order Closed </b>
			                                          <h6>:</h6>
			                                          <p>01 Aug 2019 20:27</p>
			                                       </span>
			                                    </td>
			                                    <td colspan="1" className="red-trash">
			                                       <span> <i className="fa fa-trash"> </i> </span>
			                                    </td>
			                                 </tr>
			                                 <tr>
			                                    <td colspan="2" width="100px">
			                                       <ul className="fit-set-creen">
			                                          <li>
			                                             <img src={image103} alt="" />
			                                          </li>
			                                          <li>
			                                             <p>Fashion Gold Color Korean Style Simple Crystal Stud Earrings For Women Wedding Jewerly Bridal Engagement Earrings Female Gifts
			                                             </p>
			                                             <h5>$ 1.76*1</h5>
			                                          </li>
			                                       </ul>
			                                    </td>
			                                    <td colspan="2" className="top-10 new-h4-">
			                                       <h4>Feedback I left</h4>
			                                       <span className="top-top"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></span>
			                                       <h4>Feedback I received</h4>
			                                       <span className="bottom-"> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></span>
			                                       <p>great buyer!!welcome to our shop again!!</p>
			                                    </td>
			                                    <td colspan="2" className="top-10 text-center">
			                                    </td>
			                                    <td colspan="1" className="top-10 text-center">
			                                    </td>
			                                 </tr>
			                                 <tr className="fixed-table">
			                                    <td colspan="2">
			                                       <span>
			                                          <b>Order ID</b>
			                                          <h6>:</h6>
			                                          <p>101376332334352</p>
			                                          <a href="#">
			                                             <h3>View Detail</h3>
			                                          </a>
			                                       </span>
			                                    </td>
			                                    <td colspan="2">
			                                       <span>
			                                          <b>Seller </b>
			                                          <h6>:</h6>
			                                          <p>lanxiu Li</p>
			                                       </span>
			                                    </td>
			                                    <td colspan="2" className="red-trash">
			                                       <span>
			                                          <b className="order-rate">Order Closed </b>
			                                          <h6>:</h6>
			                                          <p>01 Aug 2019 20:27</p>
			                                       </span>
			                                    </td>
			                                    <td colspan="1" className="red-trash">
			                                       <span> <i className="fa fa-trash"> </i> </span>
			                                    </td>
			                                 </tr>
			                                 <tr>
			                                    <td colspan="2" width="100px">
			                                       <ul className="fit-set-creen">
			                                          <li>
			                                             <img src={image103} alt="" />
			                                          </li>
			                                          <li>
			                                             <p>Fashion Gold Color Korean Style Simple Crystal Stud Earrings For Women Wedding Jewerly Bridal Engagement Earrings Female Gifts
			                                             </p>
			                                             <h5>$ 1.76*1</h5>
			                                          </li>
			                                       </ul>
			                                    </td>
			                                    <td colspan="2" className="top-10 new-h4-">
			                                       <h4>Feedback I left</h4>
			                                       <span className="top-top"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></span>
			                                       <h4>Feedback I received</h4>
			                                       <span className="bottom-"> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></span>
			                                       <p>great buyer!!welcome to our shop again!!</p>
			                                    </td>
			                                    <td colspan="2" className="top-10 text-center">
			                                    </td>
			                                    <td colspan="1" className="top-10 text-center">
			                                    </td>
			                                 </tr>
			                              </table>
			                           </div>
			                           <div className="tabs_wrap" id="blog">
			                              <div className="row">
			                                 <div className="col-lg-6" >
			                                    <div className="form-ch-data form-search">
			                                       <label for="">Order Number : </label>
			                                       <input type="search" placeholder="Search" />
			                                       <button type="button">Search</button>
			                                    </div>
			                                 </div>
			                              </div>
			                              <table id="customers" className="customers--">
			                                 <tr>
			                                    <th rowspan="1" colspan="2">Order Details </th>
			                                    <th rowspan="1" colspan="2">Feedback  </th>
			                                    <th rowspan="1" colspan="2">Feedback Status </th>
			                                    <th rowspan="1" colspan="1">Action</th>
			                                 </tr>
			                                 <tr className="fixed-table">
			                                    <td colspan="2">
			                                       <span>
			                                          <b>Order ID</b>
			                                          <h6>:</h6>
			                                          <p>101376332334352</p>
			                                          <a href="#">
			                                             <h3>View Detail</h3>
			                                          </a>
			                                       </span>
			                                    </td>
			                                    <td colspan="2">
			                                       <span>
			                                          <b>Seller </b>
			                                          <h6>:</h6>
			                                          <p>lanxiu Li</p>
			                                       </span>
			                                    </td>
			                                    <td colspan="2" className="red-trash">
			                                       <span>
			                                          <b className="order-rate">Order Closed </b>
			                                          <h6>:</h6>
			                                          <p>01 Aug 2019 20:27</p>
			                                       </span>
			                                    </td>
			                                    <td colspan="1" className="red-trash">
			                                       <span> <i className="fa fa-trash"> </i> </span>
			                                    </td>
			                                 </tr>
			                                 <tr>
			                                    <td colspan="2" width="100px">
			                                       <ul className="fit-set-creen">
			                                          <li>
			                                             <img src={image103} alt="" />
			                                          </li>
			                                          <li>
			                                             <p>Fashion Gold Color Korean Style Simple Crystal Stud Earrings For Women Wedding Jewerly Bridal Engagement Earrings Female Gifts
			                                             </p>
			                                             <h5>$ 1.76*1</h5>
			                                          </li>
			                                       </ul>
			                                    </td>
			                                    <td colspan="2" className="top-10 new-h4-">
			                                       <h4>Feedback I left</h4>
			                                       <span className="top-top"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></span>
			                                       <h4>Feedback I received</h4>
			                                       <span className="bottom-"> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></span>
			                                       <p>great buyer!!welcome to our shop again!!</p>
			                                    </td>
			                                    <td colspan="2" className="top-10 text-center">
			                                    </td>
			                                    <td colspan="1" className="top-10 text-center">
			                                    </td>
			                                 </tr>
			                                 <tr className="fixed-table">
			                                    <td colspan="2">
			                                       <span>
			                                          <b>Order ID</b>
			                                          <h6>:</h6>
			                                          <p>101376332334352</p>
			                                          <a href="#">
			                                             <h3>View Detail</h3>
			                                          </a>
			                                       </span>
			                                    </td>
			                                    <td colspan="2">
			                                       <span>
			                                          <b>Seller </b>
			                                          <h6>:</h6>
			                                          <p>lanxiu Li</p>
			                                       </span>
			                                    </td>
			                                    <td colspan="2" className="red-trash">
			                                       <span>
			                                          <b className="order-rate">Order Closed </b>
			                                          <h6>:</h6>
			                                          <p>01 Aug 2019 20:27</p>
			                                       </span>
			                                    </td>
			                                    <td colspan="1" className="red-trash">
			                                       <span> <i className="fa fa-trash"> </i> </span>
			                                    </td>
			                                 </tr>
			                                 <tr>
			                                    <td colspan="2" width="100px">
			                                       <ul className="fit-set-creen">
			                                          <li>
			                                             <img src={image103} alt="" />
			                                          </li>
			                                          <li>
			                                             <p>Fashion Gold Color Korean Style Simple Crystal Stud Earrings For Women Wedding Jewerly Bridal Engagement Earrings Female Gifts
			                                             </p>
			                                             <h5>$ 1.76*1</h5>
			                                          </li>
			                                       </ul>
			                                    </td>
			                                    <td colspan="2" className="top-10 new-h4-">
			                                       <h4>Feedback I left</h4>
			                                       <span className="top-top"><i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></span>
			                                       <h4>Feedback I received</h4>
			                                       <span className="bottom-"> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i className="fa fa-star"></i></span>
			                                       <p>great buyer!!welcome to our shop again!!</p>
			                                    </td>
			                                    <td colspan="2" className="top-10 text-center">
			                                    </td>
			                                    <td colspan="1" className="top-10 text-center">
			                                    </td>
			                                 </tr>
			                              </table>
			                           </div>
			                        </div>
			                     </div>
			                  </div>
			               </div>
			               <div className="form-wrapper-trad">
			                  <div className="row">
			                     <div className="col-lg-12 padding-set-left">
			                     </div>
			                  </div>
			               </div>
			            </div>
			         </div>
			      </div>
			   </div>
			   <Footer />
			</div>
		);
	}
}

export default Feedback;
