import React, { Component , useEffect} from 'react';
import Footer from './components/Footer/Footer';
import {HashRouter as Router} from 'react-router-dom';
import { useHistory, useLocation, hashHistory, Route, Switch,withRouter } from 'react-router-dom';
import { browserHistory} from 'react-router-dom';
import Welcome from '././components/Welcome/Welcome';
import Home from '././components/Home/Home';
import Login from '././components/Login/Login';
import ForgetPassword from '././components/Login/ForgetPassword';
import Brands from '././components/Brands.js';
import Blog from '././components/Blog.js';
import Signup from '././components/Signup/Signup';
import ContactUs from './components/contactus.js';
import CheckOut from './components/checkout.js';
import GuestCheckOut from './components/Guestcheckout.js';
import Order from './components/order.js';
import Cart from './components/cart.js';
import ProductDetails from './components/product-details.js';
import ProductDetail from './components/Product-detail.js';
import SearchResults from './components/search-results.js';
import Mycoupon from './components/mycoupon.js';
import ManageFeedback from './components/managefeedback.js';
import MyFavourite from './components/MyFavourite.js';
import MyShop from './components/MyShop.js';
import CustomerProfit from './components/CustomerProfit.js';
import ShippingAddress from './components/shippingAddress.js';
import AboutUs from './components/AboutUs.js';
import Profile from './components/Profile.js';
import orderDetail from './components/orderDetail.js';
import DropshipPayments from './components/DropshipPayments.js';
import SellerPayments from './components/SellerPayments.js';
import SellerComission from './components/SellerComission.js';
import PrintOrder from './components/Print_invoice.js';
import {Redirect} from 'react-router-dom';


import TestAnything from './components/test.js';
const Index = () => {
  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    if (location.hash) {
      history.replace(location.hash.replace('#', ''))
    }
  }, [history, location.hash, location.pathname])
}
const Routes = () => {
		return (
				<Router>
						<Route exact={ true } path="/" component={withRouter(Home)}/>
						<Route exact={ true } path="/about-us" component={AboutUs}/>
						<Route exact={ true } path="/profile" component={Profile}/>
						<Route exact={ true } path="/login" component={Login}/>
						<Route exact={ true } path="/forget-password" component={ForgetPassword}/>
						<Route exact={ true } path="/brands" component={Brands}/>
						<Route exact={ true } path="/blog" component={Blog}/>
						<Route exact={ true } path="/signup" component={Signup}/>
						<Route exact={ true } path="/contact-us" component={ContactUs}/>
						<Route exact={ true } path="/check-out" component={CheckOut}/>
						<Route exact={ true } path="/guest-check-out" component={GuestCheckOut}/>
						<Route exact={ true } path="/order" component={Order}/>
						<Route exact={ true } path="/cart" component={Cart}/>
						<Route exact={ true } path="/search-results/:category_id/:product_filter/:special_filter" component={withRouter(SearchResults)}/>
						<Route exact={ true } path="/search-results" component={withRouter(SearchResults)}/>
						<Route exact={ true } path="/product-details/:productId" component={ProductDetails}/>
						<Route exact={ true } path="/product-detail/:productId/:unitID" component={ProductDetail}/>
						<Route exact={ true } path="/order-detail/:orderId" component={orderDetail}/>
						<Route exact={ true } path="/print-order/:orderId" component={PrintOrder}/>
						<Route exact={ true } path="/my-coupon" component={Mycoupon}/>
						<Route exact={ true } path="/manage-feedback" component={ManageFeedback}/>
						<Route exact={ true } path="/my-favourite" component={MyFavourite}/>
						<Route exact={ true } path="/my-shop" component={MyShop}/>
						<Route exact={ true } path="/customer-profit" component={CustomerProfit}/>
						<Route exact={ true } path="/shipping-address" component={ShippingAddress}/>
						<Route exact={ true } path="/test" component={TestAnything}/>
						<Route exact={ true } path="/seller-comission" component={SellerComission}/>
						<Route exact={ true } path="/seller-payments" component={SellerPayments}/>
						<Route exact={ true } path="/dropship-payments" component={DropshipPayments}/>
				</Router>
		);
	}
class App extends Component {
	constructor(){
		super();
		this.state={
			home: false
		}
	}
}

export default Routes;
